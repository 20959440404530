import React from "react";

const CircleImage = ({ imageUrl, letter = "D", borderColor = "#4caf50", size = 24 ,isLast}:any) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        border: `1px solid ${imageUrl ? '#8a9c9d' : isLast ? '#334055' : borderColor}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        backgroundColor: imageUrl ? "transparent" : isLast ? "#060D15" : "#1A3A3E", // Dark background for letters
        color: isLast ? "#334055" : "#fff",
        fontSize: '12 px',
        fontWeight: "500",
        fontFamily:'poppins',
        textTransform: "uppercase",
        position: "relative",
        boxShadow: imageUrl || isLast ?  "none" :"0 0 10px #3B7375", // Glow effect like in the image

      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Content"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        letter
      )}
    </div>
  );
};

export default CircleImage;
