import React from "react";
// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import Header from "../../catalogue1/src/Header.web";
import Footer from "../../catalogue1/src/Footer.web";
import "../../catalogue1/src/Header.css";
import {
  Clock,
  User,
  Update,
  VerticalLine,
} from "../../../components/src/Icons.web";
import "../../scheduling/src/global.css";
import CoursePageController, { Props } from "./CoursePageController.web";
import {
  PlayCircleOutlineOutlined,
  LibraryBooksOutlined,
} from "@material-ui/icons";
import SubHeader from "../../catalogue1/src/SubHeader.web";
import { CourseSkeleton } from "../../../components/src/Skeletons";
// Customizable Area End
export default class CoursePage extends CoursePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  currentProgress = this.state.stations.findIndex(
    (station) => !station.completed
  );
  transitionStyle = {
    transition: "all 2s ease-in-out",
  };

  renderActiveStation = (station: any, index: number) => {
    const firstIncompleteIndex = this.state.stations.findIndex(s => !s.completed);
    const questionBankScore = this.state.questionBankScore.find(
      (qs) => qs.unit_name === station.label
    );
    const firstUnscoredIndex = this.state.stations.findIndex(s => {
      const questionBankScore = this.state.questionBankScore.find(qs => qs.unit_name === s.label);
      return !questionBankScore?.score?.all_attempted; 
    });
    const adjustedIndex = firstUnscoredIndex > 0 ? firstUnscoredIndex - 0 : firstUnscoredIndex; 
    const isScored2 = questionBankScore?.score?.all_attempted;
    const isActive = index === adjustedIndex;
    const questionBankScore2 = this.state.questionBankScore.find(qs => qs.unit_name === station.label);
    const isScored = questionBankScore2?.score?.all_attempted || false; 
    const isActive2 = index === firstUnscoredIndex;
    const shouldRenderCircle = isActive;
console.log(isScored2,'isScored2======>');


    return (
      <>
        <g
          key={`active-${index}`}
          transform={`translate(${station.x - 53} ${station.y - 49})`}
        >
          <svg
            width="106"
            height="106"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <g filter="url(#filter0_d_17843_19286)">
              <circle
                cx="53"
                cy="49"
                r="45"
                fill={isScored ? "#72EFDD" : "#334155"}
                style={this.transitionStyle}
              />
              <circle
                cx="53"
                cy="49"
                r="42.5"
                stroke={isScored ? "#8AB9B8" : "#475569"}
                strokeWidth="5"
                style={{
                  transition: "stroke 2s ease-in-out",
                }}
              />
            </g>
            <defs>
              <filter
                id="filter0_d_17843_19286"
                x="0"
                y="0"
                width="106"
                height="106"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_17843_19286"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_17843_19286"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          {shouldRenderCircle ? (
            <g transform={`translate(-45, -50)`}>
              <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                <defs>
                  <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur stdDeviation="5" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>
                <g opacity="0.2" filter="url(#filter0_f_17843_19146)">
                  <circle cx="100" cy="100" r="54.9" stroke="#72EFDD" stroke-width="2" fill="none" opacity="0.5" />
                  <circle cx="100" cy="100" r="64.9" stroke="#72EFDD" stroke-width="2" fill="none" opacity="0.3" />
                  <circle cx="100" cy="100" r="75.4" stroke="#72EFDD" stroke-width="2" fill="none" opacity="0.1" />
                </g>
              </svg>
            </g>
          ) : null}
          {this.renderStationLabels(station, index)}
          <circle
            ref={this.state.stationRefs[index]}
            data-station-id="1"
            cx="53"
            cy="49"
            r="15"
            fill={isScored ? "#D9FFFE" : "#64748B"}
            filter={station.completed ? "drop-shadow(0px 2px 4px #e1060640)" : ""}
            fillOpacity="1"
            style={{ ...this.transitionStyle, cursor: "pointer" }}
            onClick={(e) => this.handleStationClick(index, e)}
          />
        </g>
      </>
    );
  };

  renderStationLabels = (station: any, index: number) => {
    const firstUnscoredIndex = this.state.stations.findIndex(s => {
      const questionBankScore = this.state.questionBankScore.find(qs => qs.unit_name === s.label);
      return !questionBankScore?.score?.all_attempted; 
    });
    const adjustedIndexValue= firstUnscoredIndex > 0 ? firstUnscoredIndex - 0 : firstUnscoredIndex; 
    const isActiveValue= index === adjustedIndexValue;
    const shouldRenderCircleValue = isActiveValue;
    const questionBankScore = this.state.questionBankScore.find(
      (qs) => qs.unit_name === station.label
    );
    const score = questionBankScore
      ? parseInt(questionBankScore.score?.score)
      : 0;
    const isScored = questionBankScore?.score?.all_attempted
    const unitPercentage = parseInt(
      this.state.unitPercentages.find((up) => up.unit_name === station.label)
        ?.completion_percentage || "0%"
    );
   
    const getColor = score >= 70 ? "rgba(114, 239, 221, 0.4)" : score < 50 ? "rgba(248, 113, 113, 0.4)" : "rgba(245, 158, 11, 0.4)";
    return (
      <g>
        {/* Score Label */}
        {isScored && (
          <g transform="translate(10, 115)">
            <foreignObject x="10" y="12" width="60" height="20">
              <div
                className="preview-font"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  borderRadius: "11px",
                  backgroundColor: getColor,
                  color: "#FFFFFF",
                  fontSize: "10px",
                  fontWeight: "500",
                  textAlign: "center",
                  fontFamily: 'Poppins'
                }}
              >
                {Number(
                  score
                )}{" "}
                / 100
              </div>
            </foreignObject>
          </g>
        )}
        {!isScored && unitPercentage != 0 && this.renderProgressBars(station)}
        {/* Station Label - positioned below score */}
        <text
          x="53"
          y="117"
          textAnchor="middle"
          fill="white"
          style={{
            fontSize: "18px",
            opacity:shouldRenderCircleValue|| isScored? "1":"70%",
            fontWeight:"700"
          }}
        >
          {this.truncateText(station.label)}
        </text>
      </g>
    );
  };

  renderProgressBars = (station: any) => {
    const percentage =
      this.state.unitPercentages.find((up) => up.unit_name === station.label)
        ?.completion_percentage || "0.0%";

    return (
      <foreignObject x="11" y="130" width="85" height="20">
        <div
          data-testid={`progress-container-${station.label}`}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            height: "8px",
            marginTop: '3px'
          }}
        >
          <div
            data-testid={`progress-bar-${station.label}`}
            style={{
              width: "60px",
              height: "100%",
              background: "#334155",
              borderRadius: "4px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{
                width: percentage,
                height: "100%",
                background: "#72EFDD",
                borderRadius: "4px",
                transition: "all 0.5s ease-in-out",
                opacity: 0.5,
              }}
            />
          </div>
          <Typography
            data-testid={`progress-text-${station.label}`}
            className="preview-font"
            style={{
              fontSize: "10px",
              color: "#FFFFFF",
              marginLeft: "4px",
            }}
          >
            {percentage}
          </Typography>
        </div>
      </foreignObject>
    );
  };

  CoursePageSkeleton = () => {
    return (<CourseSkeleton />
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log(this.state.stations, "currentProgress====>");

    return (
      //Merge Engine DefaultContainer
      <>
        <Box style={webStyle.backgroundCLr} className="course-page">
          <Header navigation={this.props.navigation} id={"3"} />
          <SubHeader
            data={this.state.subCategoryData}
            navigation={`/Journey/${this.state.courseID}`}
            id={String(this.state.courseID)}
            onGoBack={this.onJourney}
          />
          {this.state.thumbnail ? <>
            <Box style={webStyle.root}>
              <Grid container spacing={2}>
                <Grid style={{ display: 'flex' }} item lg={6} md={6} sm={12} xs={12}>
                  <Box style={webStyle.courseContainer}>
                    <Box style={webStyle.previewCard} data-testid="preview-section">
                      <Box style={webStyle.preview}>

                        <img
                          src={this.state.thumbnail}
                          alt=""
                          style={webStyle.previewImage}
                        />

                      </Box>
                      <Box style={webStyle.courseInfo} data-testid="course-info">
                        {this.state?.certificateScore !== null &&
                          this.state?.certificateScore !== undefined ? (
                          <Box
                            style={{
                              clipPath:
                                "path('M0 0H84V72.6004C84 78.4125 77.9981 82.2849 72.7027 79.8893L46.1217 67.8646C43.5017 66.6793 40.4983 66.6793 37.8783 67.8646L11.2973 79.8893C6.00188 82.2849 0 78.4125 0 72.6004V0Z')",
                              background: "#72efdd57",
                              height: "85px",
                              width: "85px",
                              color: "#fff",
                              position: "absolute",
                              right: "5%",
                              bottom: "22.9%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              paddingTop: "10px",
                              gap: "8px",
                            }}
                          >
                            <Typography
                              className="preview-font"
                              style={{
                                fontSize: "8px",
                                fontWeight: "700",
                                lineHeight: "1",
                                letterSpacing: "1.5px",
                                marginBottom: "4px",
                                fontFamily:'Raleway'
                              }}
                              variant="subtitle1"
                            >
                              YOUR SCORE
                            </Typography>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "end",
                              }}
                            >
                              <Typography
                              className="preview-font"
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "700",
                                  lineHeight: "1",
                                  fontFamily:'Poppins !important'
                                }}
                                variant="subtitle2"
                              >
                                {this.state.courseDetails?.score}
                              </Typography>
                            <Typography variant="subtitle2" style={{fontFamily:'Raleway'}}>/100</Typography>
                            </Box>
                          </Box>
                        ) : null}
                        <Box
                          display="flex"
                          alignItems="center"
                          style={webStyle.infoRow}
                        >
                          <Clock width={18} height={18} />
                          <VerticalLine />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                            }}
                          >
                            <Typography variant="subtitle1" style={webStyle.subT1}>
                              EXPECTED DURATION
                            </Typography>
                            <Typography
                              variant="body1"
                              // style={webStyle.body1}
                              data-testid="duration-info"
                              className="preview-font"
                            >
                              {this.state.courseDetails.expected_duration}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={webStyle.infoRow}
                        >
                          <User width={18} height={18} />
                          <VerticalLine />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                            }}
                          >
                            <Typography variant="subtitle1" style={webStyle.subT1}>
                              LEARNERS ENROLLED
                            </Typography>
                            <Typography
                              variant="body1"
                              style={webStyle.body1}
                              data-testid="learners-info"
                              className="preview-font"
                            >
                              {this.state.courseDetails.learners_enrolled}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={webStyle.infoRow}
                        >
                          <Update width={18} height={18} />
                          <VerticalLine />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                            }}
                          >
                            <Typography variant="subtitle1" style={webStyle.subT1}>
                              LAST UPDATE
                            </Typography>
                            <Typography
                              variant="body1"
                              style={webStyle.body1}
                              data-testid="update-info"
                              className="preview-font"
                            >
                              {this.state.courseDetails.last_update}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid style={{ display: 'flex' }} item lg={6} md={6} sm={12} xs={12}>
                  <Box style={webStyle.learningPath}>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "50rem",
                        margin: "0 auto",
                        padding: "0 auto",
                      }}
                    >
                      <svg width="700" height="840" viewBox="-50 -50 700 800">
                        <defs>
                          <filter
                            id="drop_shadow"
                            x="-50%"
                            y="-50%"
                            width="200%"
                            height="200%"
                          >
                            <feDropShadow
                              dx="0"
                              dy="4"
                              stdDeviation="2"
                              floodOpacity="0.25"
                              floodColor="#000000"
                            />
                          </filter>

                          <linearGradient
                            id="paint0_linear_right"
                            x1="27.9747"
                            y1="15.797"
                            x2="8.97465"
                            y2="21.297"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#334155" />
                            <stop offset="1" stopColor="#475569" />
                          </linearGradient>

                          <linearGradient
                            id="paint0_linear_left"
                            x1="5.00195"
                            y1="29"
                            x2="24.002"
                            y2="23.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#334155" />
                            <stop offset="1" stopColor="#475569" />
                          </linearGradient>

                          <linearGradient
                            id="paint0_linear_17843_19187"
                            x1="36"
                            y1="27.002"
                            x2="5.99999"
                            y2="27.002"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#334155" />
                            <stop offset="1" stopColor="#475569" />
                          </linearGradient>
                          <filter
                            id="circle-blur"
                            x="-100%"
                            y="-100%"
                            width="300%"
                            height="300%"
                          >
                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                          </filter>
                        </defs>

                        <path
                          className="progress-path"
                          opacity="0.2"
                          stroke="#a2b7d6"
                          strokeWidth="19.5"
                          strokeLinecap="round"
                          fill="none"
                          d={this.createMainPath()}
                        />
                        <g transform="translate(365 -18)">
                          <svg
                            width="195"
                            height="125"
                            viewBox="0 0 195 125"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM189.333 122C189.333 123.473 190.527 124.667 192 124.667C193.473 124.667 194.667 123.473 194.667 122C194.667 120.527 193.473 119.333 192 119.333C190.527 119.333 189.333 120.527 189.333 122ZM3 3.5L92 3.5V2.5L3 2.5V3.5ZM191.5 103V122H192.5V103H191.5ZM92 3.5C146.952 3.5 191.5 48.0477 191.5 103H192.5C192.5 47.4954 147.505 2.5 92 2.5V3.5Z"
                              fill="url(#paint0_linear_17848_19930)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_17848_19930"
                                x1="-1.5"
                                y1="2.99999"
                                x2="194.5"
                                y2="118"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#BAC1CB" />
                                <stop offset="0.513804" stop-color="#424952" />
                                <stop offset="1" stop-color="#CDD5E1" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </g>
                        <g transform="translate(-10 153)">
                          <svg
                            width="197"
                            height="219"
                            viewBox="0 0 197 219"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M89.1667 3C89.1667 4.47276 87.9728 5.66667 86.5 5.66667C85.0272 5.66667 83.8333 4.47276 83.8333 3C83.8333 1.52724 85.0272 0.333333 86.5 0.333333C87.9728 0.333333 89.1667 1.52724 89.1667 3ZM196.667 216C196.667 217.473 195.473 218.667 194 218.667C192.527 218.667 191.333 217.473 191.333 216C191.333 214.527 192.527 213.333 194 213.333C195.473 213.333 196.667 214.527 196.667 216ZM86.6193 3.48557L77.2668 5.78267L77.0283 4.81153L86.3807 2.51443L86.6193 3.48557ZM1.5 102.411V116H0.5V102.411H1.5ZM101 215.5H194V216.5H101V215.5ZM1.5 116C1.5 170.952 46.0477 215.5 101 215.5V216.5C45.4954 216.5 0.5 171.505 0.5 116H1.5ZM77.2668 5.78267C32.7784 16.7096 1.5 56.6001 1.5 102.411H0.5C0.5 56.1397 32.0927 15.8483 77.0283 4.81153L77.2668 5.78267Z"
                              fill="url(#paint0_linear_17848_19931)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_17848_19931"
                                x1="194"
                                y1="216"
                                x2="83"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#545B64" />
                                <stop offset="1" stop-color="#B0C0D5" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </g>
                        <g transform="translate(485 415)">
                          <svg
                            width="78"
                            height="122"
                            viewBox="0 0 78 122"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M71.8333 3.5C71.8333 2.02724 73.0272 0.833336 74.5 0.833336C75.9728 0.833336 77.1667 2.02724 77.1667 3.5C77.1667 4.97276 75.9728 6.16666 74.5 6.16666C73.0272 6.16666 71.8333 4.97276 71.8333 3.5ZM0.833333 118.5C0.833333 117.027 2.02724 115.833 3.5 115.833C4.97276 115.833 6.16667 117.027 6.16667 118.5C6.16667 119.973 4.97276 121.167 3.5 121.167C2.02724 121.167 0.833333 119.973 0.833333 118.5ZM73.5411 19.9221L74.0002 3.48604L74.9998 3.51396L74.5407 19.95L73.5411 19.9221ZM74.5407 19.95C73.3015 64.3127 45.266 103.486 3.67444 118.969L3.32556 118.031C44.5352 102.691 72.3133 63.8774 73.5411 19.9221L74.5407 19.95Z"
                              fill="url(#paint0_linear_17856_20426)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_17856_20426"
                                x1="-122.5"
                                y1="114"
                                x2="73.5"
                                y2="-1.00005"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#BAC1CB" />
                                <stop offset="0.513804" stop-color="#424952" />
                                <stop offset="1" stop-color="#CDD5E1" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </g>

                        <path
                          ref={this.state.pathRef}
                          className="progress-path"
                          stroke="#8AB9B8"
                          strokeWidth="19.5"
                          strokeLinecap="round"
                          fill="none"
                          d={this.createMainPath()}
                          style={{
                            strokeDasharray: "5000",
                            strokeDashoffset: "5000",
                            transition: "stroke-dashoffset 2s ease-in-out",
                          }}
                        />
                        <g transform="translate(0 500)">
                          <path
                            d="M10.0004 179.5V110.001C10.0004 54.7721 54.772 10.0006 110 10.0006H361"
                            stroke="#212E42"
                            strokeWidth="20"
                            fill="none"
                            strokeLinecap="round"
                          />
                        </g>
                        {this.state.stations.map((station, index) =>
                          this.renderActiveStation(station, index)
                        )}
                      </svg>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {this.state.selectedStation !== null &&
              this.state.popupPosition &&
              (document.body.classList.add("popup-open"),
                (
                  <div
                    className="popup-container"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1000,
                      background: "#1E293B9C",
                      height:'200vh',
                    
                    }}
                    onClick={() => {
                      this.setState({
                        selectedStation: null,
                        popupPosition: null,
                      });
                      document.body.classList.remove("popup-open");
                    }}
                  >
                    <div
                      style={{
                        ...this.state.popupPosition,
                        width: "301px",
                        backgroundColor: "#182232",
                        borderRadius: "24px",
                        padding: "20px",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        zIndex: 1001,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={(e) => e.stopPropagation()}
                      className="preview-font custom-popup"
                    >
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      left: "59%",
                      transform: "translateX(-50%)",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "10px solid #182232",
                    }}
                  ></div>

                      <path
                        d="M 200 20 Q 190 30 180 30 H 220 Q 210 30 200 20 Z"
                        fill="#1e293b"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {/* File Icon */}
                          <div
                            style={{
                              marginBottom: "16px",
                              position: "relative",
                              width: "40px",
                              height: "40px",
                            }}
                          >
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ position: "absolute", top: 0, left: 0 }}
                            >
                              <circle
                                cx="20"
                                cy="20"
                                r="18"
                                stroke="#72EFDD"
                                strokeWidth="1"
                                fill="none"
                              />
                            </svg>
                            <LibraryBooksOutlined
                              style={{
                                color: "#72EFDD",
                                height: "20px",
                                width: "20px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </div>

                          {/* Unit Number */}
                          <div
                            className="preview-font"
                            style={{
                              color: "#72EFDD",
                              fontSize: "10px",
                              fontWeight: 500,
                              marginBottom: "8px",
                               fontFamily:'Poppins'
                            }}
                          >
                            {`${this.state.selectedStation + 1} / ${this.state.stations.length
                              }`}
                          </div>
                        </div>
                        <div style={{ letterSpacing: "0.3px" }}>
                          {/* Title */}
                          <div
                            style={{
                              color: "white",
                              fontSize: "18px",
                              fontWeight: 700,
                              marginBottom: "12px",
                              lineHeight: "21px",
                              letterSpacing: "0.8px",
                              font: 'Raleway'
                            }}
                          >
                            {this.state.stations[this.state.selectedStation].label}
                          </div>

                          {/* Description */}
                          <div
                            style={{
                              color: "rgba(255, 255, 255, 0.7)",
                              fontSize: "14px",
                              lineHeight: "20px",
                              marginBottom: "24px",
                            }}
                          >
                            {this.state.stations[this.state.selectedStation]
                              .description ||
                              "Leadership plays a crucial role in promoting professional wellness..."}
                          </div>
                        </div>
                      </div>
                      {/* Start Button */}
                      <div>
                        <button
                          data-testid="popup-btn"
                          className="start-btn"
                          style={{
                            width: "100%",
                            padding: "14px",
                            backgroundColor: "rgba(114, 239, 221, 0.3)",
                            border: "none",
                            borderRadius: "20px",
                            color: "#72EFDD",
                            fontSize: "14px",
                            fontWeight: 600,
                            cursor: "pointer",
                            transition: "all 0.2s ease",
                          }}
                          onClick={() => {
                            if (
                              this.state.selectedStation !== null &&
                              this.unitIds[this.state.selectedStation]
                            ) {
                              this.handleNavigation(
                                this.unitIds[this.state.selectedStation]
                              );
                              this.setState({
                                selectedStation: null,
                                popupPosition: null,
                              });
                              document.body.classList.remove("popup-open");
                            }
                          }}
                        >
                          {this.unitTxt()}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
          </> : this.CoursePageSkeleton()}
          <Footer navigation={this.props.navigation} id={""} />
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
  },
  productContainer: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column" as const,
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column" as const,
  },
  root: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
    // padding: "0 8%",
    color: "white",
    overflow: "hidden" as "hidden",
    display: "flex",
    flexWrap: "wrap" as const,
    textAlign: "end" as const,
    marginBottom: "-5%",
  },
  courseContainer: {
    display: "flex",
    gap: "32px",
    maxWidth: 1400,
    margin: "0 auto",
    padding: "0 24px",
    flexDirection: "row" as const,
    alignItems: "flex-start",
  },
  previewCard: {
    backgroundColor: "#182232",
    borderRadius: "20px",
    padding: "0",
    width: "400px",
    height: "auto",
    backdropFilter: "blur(10px)",
    border: "1px solid rgb(72 82 113 / 48%)",
    filter: "drop-shadow(0px 1px 2px rgb(0, 0, 0))",
    flex: "0 0 auto",
  },
  preview: {
    width: "100%",
    height: "328px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderRadius: "20px",
    marginBottom: "24px",
    position: "relative" as const,
    overflow: "hidden" as const,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  previewImage: {
    width: "100%",
    height: "100%",
    objectFit: "fill" as const,
  },
  previewOverlay: {
    position: "absolute" as const,
    bottom: "16px",
    left: "34%",
    padding: "8px 13px",
    backgroundColor: "rgba(255, 255, 255, 0.20)",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center" as const,
    gap: "4px",
    width: "116px",
    height: "34px",
  },
  preFont: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    textShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
  },
  courseInfo: {
    display: "flex",
    flexDirection: "column" as const,
    paddingBottom: "20px",
    color: "rgba(255, 255, 255, 0.7)",
  },
  learningPath: {
    flex: "1 1 auto",
    backgroundColor: "transparent",
    borderRadius: "16px",
    // padding: "0 32px",
    minWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative" as const,
  },
  infoRow: {
    display: "flex",
    alignItems: "center" as const,
    gap: "12px",
    padding: "8px 24px",
  },
  subT1: {
    fontSize: "10px",
    opacity: "0.7",
    letterSpacing: "0.3px",
    fontFamily:'Raleway',
    fontWeight:300
  },
  body1: {
    fontSize: "14px",
    fontFamily:'Raleway',
    fontWeight:400
  },
  infoContent: {
    display: "flex",
    flexDirection: "column" as const,
    textAlign: "start" as const,
    gap: "4px",
  },

};
// Customizable Area End
