Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.metricsData="account_block/dashboards/metrics"
exports.btnExampleTitle = "CLICK ME";
exports.performance='bx_block_data_import_export/export/export_student_performance'
exports.averagData='account_block/dashboards/average_course_scores?filter='
// Customizable Area End