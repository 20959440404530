
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import dayjs from "dayjs";

import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
 
  id: string;
  navigation: any;
}

interface S {
  coursesData:any,
  finalValue:number,
  highlightData:any,
  graphData:any,
  graphTime:string,
  anchorElServices: boolean | null;
  hoveredUnit:any,
  hoveredCourse:any,
  courseUnit:number,
  openSubMenu:boolean,
  openSubMenu2:number,

  
}

interface SS {
  id: any;
}

export default class HighlightController extends BlockComponent<Props, S, SS> {
 
 
  getCourse:string="";
  graphPoints:string="";
  highlightPoints:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      graphTime:'weekly', 
      coursesData:[],
      graphData:[],
      highlightData:[],
      finalValue:0,
      courseUnit:0,
      anchorElServices:null,
      openSubMenu:false,
      hoveredUnit:null,
      hoveredCourse:null,
      openSubMenu2:0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.graphChart("weekly");
    const token = await getStorageData("signupToken");
    this.getCourses(token)
    this.getGraphValue(token);
    this.getHighlightCourse(token);
   
  }
  async graphChart(value:any){
    const token = await getStorageData("signupToken");
   this.setState({graphTime:value})
   this.getGraphValue(token);
  }
  handleCourses=(event:any)=>{
    this.setState({ anchorElServices: event.currentTarget})
  }
  getCourses = ( token: string) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const coursesRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCourse = coursesRequest.messageId;

    coursesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.courseUnit}`
    );

    coursesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    coursesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(coursesRequest.id, coursesRequest);
  };

  getGraphValue = ( token: string) => {
   const endPoint= this.state.hoveredCourse!=null ?  `${configJSON.graph}${this.state.graphTime}&unit_id=${this.state.hoveredCourse}`: `${configJSON.graph}${this.state.graphTime}`;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const graphRequests = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.graphPoints = graphRequests.messageId;

    graphRequests.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    graphRequests.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    graphRequests.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(graphRequests.id, graphRequests);
  };
  getHighlightCourse = ( token: string) => {
     const header = {
       "Content-Type": configJSON.dashboarContentType,
       token: token,
     };
 
     const highlightRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
     this.highlightPoints = highlightRequest.messageId;
 
     highlightRequest.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.highlight}`
     );
 
     highlightRequest.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     highlightRequest.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.dashboarApiMethodType
     );
 
     runEngine.sendMessage(highlightRequest.id, highlightRequest);
   };
  handleMouseEnter = (index: number) => {
    this.setState({ hoveredUnit: index });
  };
  
  handleMouseLeave = () => {
    this.setState({ hoveredUnit: null });
  };
  handleMouseEnter2 = (index: number) => {
    this.setState({ hoveredCourse: index });
  };
  
   handleMouseLeave2=()=>  {
   
    this.setState({ hoveredCourse: null });
   
  };
  handleValueBar=()=>{
    if(this.state.graphTime=='daily'){
      return 30;
    }
    else if(this.state.graphTime=='monthly'){
      return 20
    }
    else if(this.state.graphTime=='weekly'){
      return 61
    }
    return 50
  }
 
  async handleMenuClose(unit: any, course: any) {
   
    this.setState((prevState) => ({
      courseUnit: unit === prevState.finalValue ? null : course,
      hoveredCourse: unit === prevState.finalValue ? null : prevState.hoveredCourse,
      finalValue: unit === prevState.finalValue ? 0 : unit,
      anchorElServices: null,
      openSubMenu: false,
    }), async () => {
      const token = await getStorageData("signupToken");
      this.getGraphValue(token);
    });
}

  handleSubMenuToggle = (course:any) => {
    this.setState((prevState):any => ({ openSubMenu: !prevState.openSubMenu ,openSubMenu2:course}));
  };

  handleClose = () => {
      this.setState({ anchorElServices: null,openSubMenu:false  });
  };
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        
        if(webApiRequestCallId===this.getCourse){
          this.setState({coursesData:webResponseJson})
        }
        if(webApiRequestCallId===this.graphPoints){
          this.setState({graphData:webResponseJson})
        }
        if(webApiRequestCallId===this.highlightPoints){
          this.setState({highlightData:webResponseJson?.top_5_course?.data})
        }
      } 
    }
  }
}
