// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import './styles/customForm.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class LearningVertical extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 /* istanbul ignore next */
  render() {
    return (
      // Customizable Area Start
      <LoginComp
        navigation={undefined}
        id={""}
        setup={true}
        onhover={this.handleIconHover}
        onleave={this.handleIconLeave}
        showInfo={this.state.showInfo}
        bgImgColor={
          "linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(114, 239, 221, 0.25) 49%, rgba(85, 145, 245, 0.5) 100%)"
        }
        heading={this.learningVerticalHeading}
        infoText1={this.learningVerticalInfoText}
        infoTextStyle={"desc-text"}
        backBtn={true}
        goBack={this.goBack}
        buttonText={"Next"}
        btnDisabled={this.state.verticalBtn}
        onclick={this.handleLearningVertical}
        customForm={
          <ProgressBar
            navigation={undefined}
            id={""}
              role={true}
              vertical={this.state.verticalStep}
              journey={false}
              institution={false}
              immediateGoal={false}
              futureGoal={false}
              step1={true}
              step2={false}
              step3={false}
              step4={false}
              step5={false}
              step6={false}
          />
        }
        formContent={
          <div className="formcontent-wrapper" data-testid="formContentWrapper">
            {this.state.learningVerticals && this.state.learningVerticals?.map((vertical:any, index:any) => {
              const isSelected = this.state.selectedVertical === vertical.id;
              return (
                <div
                  key={index.toString()}
                  className={`${
                    isSelected
                      ? "vertical-container selected-container"
                      : "vertical-container"
                  }`}
                  data-testid={`${vertical.title}-container`}
                >
                  <div
                    key={vertical.id}
                    className={`${
                      isSelected
                        ? "vertical-wrapper selected-wrapper"
                        : "vertical-wrapper"
                    }`}
                    data-testid={`${vertical.title}-wrapper`}
                  >
                    <span
                      className={`${
                        isSelected
                          ? "vertical-icon  selected-icon"
                          : "vertical-icon"
                      }`}
                      data-testid={`${vertical.title}-iconWrapper`}
                    >
                      <img
                        src={vertical.image?.url}
                        alt={`${vertical.title}-icon`}
                        data-testid={`${vertical.title}-icon`}
                      />
                    </span>
                    <label
                      htmlFor={`vertical-${index}`}
                      className={`${
                        isSelected
                          ? "vertical-label  selected-label"
                          : "vertical-label"
                      }`}
                      data-testid={`${vertical.title}-label`}
                    >
                      {vertical.title}
                    
                      <input
                        type="radio"
                        id={`vertical-${index}`}
                        name="vertical"
                        value={vertical.title}
                        className="radioBtn"
                         data-testid="changevertical"
                        onChange={() => this.handleVerticalChange(vertical.id)}
                        checked={isSelected}
                      
                      />
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
      // Customizable Area End
    )
  }
}
// Customizable Area End
