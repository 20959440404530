import { searchImgIcon } from "./assets"
import React from 'react'
const NoRecord = () => {
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            flexDirection: 'column', paddingBottom: '80px', width: '100%'
        }} >
            <div className="empty-explore-1" >
                <img src={searchImgIcon} alt="search-icon" className="centered-element" style={{opacity:0.7}}/>
            </div>
            <div style={{ marginTop: '-125px', color: 'rgba(255,255,255,0.5)' }}>
                <p style={{ textAlign: 'center', marginBottom: '4px', fontSize:'14px',fontWeight:700,fontFamily:'Raleway' }}>Uh-oh! It seems we couldn't find any students matching your search.</p>
            </div>
        </div>
    )
}

export default NoRecord;