import { Box } from "@mui/material";
import React from "react";
import { arrowRight } from "./assets";
// Customizable Area Start
const TopNav = () => {
  const taskSelection = () => {
    return (
      <div className="taskselection" style={{
        backgroundColor: '#050D15',
        padding: '3% 100px 3%',
        borderRadius: '0 0 50px 50px',
        position: 'relative',
        width: '100%'
      }}>
        <Box sx={{ display: 'flex',justifyContent:'space-between' }}>
          <h2
            style={{
              color: 'white', fontFamily: 'Raleway', fontSize: '24px',
              fontWeight: 500,
              margin: 0, marginLeft: '15px'
            }}
          >
            <p style={{display:'flex', gap:'12px',alignItems:'center'}}>
            <span style={{ marginLeft: '8px', color: 'white', fontWeight: '500', fontFamily: 'Raleway', fontSize: '24px', }}> Students List</span>
            <span><img src={arrowRight} color="#818689"/></span>
            <span style={{color: 'white', fontWeight: '900', fontFamily: 'Raleway', fontSize: '24px',}}>Student Details Page</span>
            </p>
          </h2>
          
        </Box>
      </div>
    );
  };


  return (
    <Box style={webStyle.backgroundCLr}>
      {taskSelection()}
    </Box>
  );
}

const webStyle = {
  Student: {
    fontFamily: "Poppins", fontSize: "12px", opacity: '70%', color: 'white'
  },
  data: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', fontFamily: 'Poppins'
  },
  imageStyle: {
    width: '25.8px', height: "24px"
  },
  headingValue: {
    opacity: '70%',
    fontFamily: "Raleway",
    color: 'white',
    fontWeight: 700,
    fontSize: "12px",
    margin: 0
  },
  divHeading: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'
  },
  header: {
    display: 'flex', justifyContent: 'space-between',
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  dataAnalytics: {
    fontFamily: "Poppins", fontSize: "40px", color: 'white', marginRight: '10px'
  },
  week: {
    width: '51px',
    height: '24px',
    cursor: "pointer",
    fontWeight: 500,
    fontSize: '12px', color: '#FFFFFF', display: 'flex',
    justifyContent: 'center', alignItems: 'center',
    fontFamily: 'Poppins',
  },
  courses: {
    display: 'flex',
    cursor: "pointer",
    width: '109px', height: '35px', borderRadius: '50px', background: '#FFFFFF1A', fontWeight: 300, fontSize: '14px', opacity: '80%', color: '#FFFFFF',
    justifyContent: 'center', alignItems: 'center',
  },
  headerBox: {
    width: '273px',
    height: '144px',
    margin: '20px',
    borderRadius: '20px',
    padding: '25px',
    backgroundColor: '#121c28',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    paddingBottom: '40px'

  }

};
export default TopNav;
// Customizable Area End
