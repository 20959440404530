import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { skip_shape, waving_hand } from "./assets";
import LoginComp from "./LoginComp.web";
import './styles/loginForm.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SignuploginController, {
  Props,
  configJSON,
} from "./SignuploginController";

export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoginPasswordType = () => {
    return this.state.enableField ? "text" : "password";
  }

  rendershowPasswordIcon = () => {
    return this.state.enableField ? <IoEyeOffOutline className="eyeIcon" data-testid="passwordInvisible" /> : <IoEyeOutline className="eyeIcon" data-testid="passwordVisible" />
  }

  renderColor = () => {
    const validEmail = this.state.validEmail === true;
    const invalidEmail  = this.state.validEmail === false;
    
    if (validEmail) return '#72efdd';
    return invalidEmail ? '#f87171' : '#ffffff';
  }
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <LoginComp 
        navigation={undefined} 
        id={""} 
        auth={
        <>
            <p className="signupText" data-testid="signupText"><div className="line"></div>&nbsp; <strong>{this.signupText1}</strong> {this.signupText2}</p>&nbsp;&nbsp;
            <div className="zindexBtn">
            <Button data-test-id="signupBtn" onClick={()=>this.goToSignUp()} className="signupbtn-wrapper">
              <span
                style={{ display: 'block', width: '100%', height: '100%', borderRadius: '50%', color: 'white', fontWeight: 800, textTransform: 'none' }}>
                {this.signupBtnText}
              </span>
            </Button>
            </div>
           
        </>}
        bgImg={skip_shape}
        goToSignup={this.goToSignUp}
        pgIcon={waving_hand}
        backBtn={true}
        goBack={this.goBack}
        heading={this.headingLogin}
        onclick={this.handleLogin}
        buttonText={this.btnTextLogin}
        btnDisabled={this.state.isDisabled}
        popupError={this.state.error}
        popupErrorStyle={"popupErrorStyle"}
        warning={this.state.warning}
        form={
          <Box className="loginFormContainer"> 
            <Box className="input-wrapper">
                <input 
                  type="text" 
                  id="email" 
                  name="email" 
                  value={this.state.loginEmail}
                  onChange={(e) => this.handleEmailChange(e)} 
                  className="input-field login-input1" 
                  style={{ borderColor: this.renderColor()}}
                  data-testid="email"
                  required />
                  <label htmlFor="email" className="label-text" style={{ color: this.renderColor()}} data-testid="emailLabel">{this.placeHolderEmail}</label>
              </Box>

              <Box className="input-wrapper">
              <input 
                type={this.renderLoginPasswordType()}  
                id="password" 
                name="password"
                value={this.state.loginPassword} 
                onChange={(e) => this.handlePasswordChange(e)}
                className="input-field login-input2" 
                data-testid="password"
                required />
                <label htmlFor="password" className="label-text" data-testid="labelPassword">{this.placeholderPassword}</label>               
                <span onClick={this.handleShowPassword} className="passwordIconWrapper" data-testid="passwordVisibleWrapper">{this.rendershowPasswordIcon()}</span>
            </Box>   
              <Box className="loginActions">
                <Box className="checkbox-wrapper">
                  <label htmlFor="checkbox" className="checkbox-label" data-testid="checkboxLabel">{this.labelRememberMe}
                  <input 
                      type="checkbox" 
                      id="checkbox" 
                      name="checkbox" 
                      checked={this.state.isBtnChecked}
                      onChange={(e) => this.handleBtnChecked(e)} 
                      className="checkbox"
                      data-testid="checkbox"
                      required />
                    <span className="checkmark"></span>
                  </label>
                </Box>
              <Box>
                <Typography component="p" onClick={this.goToForgotPasswordPage} className="forgotPswrdTxt" data-testid="forgotPassword">{this.btnForgotPassword}
              </Typography>
              </Box> 
            </Box>
          </Box>
      }/>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
