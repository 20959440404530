// Customizable Area Start
import React from "react";
import // Customizable Area Start
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import { institution } from "./assets";
import "./styles/customForm.css";
import CustomFormController, {
  Props
} from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";

// Customizable Area End

export default class MyInstitution extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.inputRef = React.createRef();  
    this.state = {
      ...this.state,
      institution: '',
      otherInstitutionSelected: false,
      
    };
    // Customizable Area End
  }

  // Customizable Area Start  
  // Customizable Area End

  render() {
  // Customizable Area Start
  const institutionOptions =this.state.institutions && this.state.institutions.map((institution: any) => ({
    value: institution.attributes.id,
    label: institution.attributes.name
  })) || [];

  institutionOptions.push({ value: 'other', label: 'Other' });
  // Customizable Area End
    return (
      // Customizable Area Start
      <LoginComp
        navigation={undefined}
        id={""}
        setup={true}
        onhover={this.handleIconHover}
        onleave={this.handleIconLeave}
        showInfo={this.state.showInfo}
        bgImgColor={
          "linear-gradient(to bottom, #5591f500 10%, #6200ea, #5591f5)"
        }
        heading={this.institutionHeading}
        infoText1={this.institutionInfoText}
        infoTextStyle={"desc-text"}
        backBtn={true}
        goBack={this.goBack}
        buttonText={"Next"}
        btnDisabled={this.state.institutionBtn}
        onclick={this.handleInstitutionNext}
        popupError={this.state.error}
        warning={this.state.warning}
        customForm={
          <ProgressBar
            navigation={undefined}
            id={""}
            role={true}
            vertical={true}
            journey={true}
            institution={this.state.institutionStep}
            immediateGoal={false}
            futureGoal={false}
            step1={true}
            step2={true}
            step3={true}
            step4={false}
            step5={false}
            step6={false}/>
        }
        formContent={
          <div className="institution-container"
            data-testid="institutionContainer">
            <div className="institution-wrapper">
              <span className="institutionIcon" data-testid="">
                <img
                  className="institution-icon"
                  src={institution}
                  alt="institutionIcon"
                  data-testid="institutionIcon"
                />
              </span>
               
                <input 
                  id="other-institution" 
                  name="other-institution"
                  autoComplete="off"
                  placeholder="Enter your institution's name here" 
                  value={this.state.searchQuery}
                  onChange={this.handleSearchInput} 
                  onKeyDown={this.handleKeyDown}
                  className="dropdown textInput"
                  data-testid="other-institution" 
                  ref={this.inputRef}
                />



              {this.state.searchQuery && (
                <div className="suggestions-dropdown">
                  {this.state.filteredInstitutions?.map((institution: any,index:any) => (
                   <div>
                     <div key={institution.id} className={`suggestion-item ${
                    this.state.highlightedIndex === index ? 'highlighted' : ''
                  }`}>
                      <div className="item-text" onClick={() => this.handleSuggestionClick(institution)}>
                      {institution}
                      </div>
                    </div>
                    <div className="suggestion-item-line-bottom">
                    </div>
                   </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        }
      />
      // Customizable Area End
    );
  }
}
// Customizable Area End
