Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "https://jsonplaceholder.typicode.com/todos/1";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ApiIntegration Excample";
exports.labelBodyText = "Click Button to Demo ApiIntegration";
exports.btnExampleTitle = "CLICK ME";
exports.table="account_block/dashboards/student_list"
exports.studentDetailEndPoint = "account_block/dashboards/student_details"
exports.engagementHistory = "account_block/dashboards/engagement_history"
exports.courseUnit="account_block/dashboards/course_units"
// Customizable Area End