import { Box } from "@material-ui/core"
import InstitutionalHeader from "../../blocks/navigationmenu/src/InstitutionalHeader.web"
import React from 'react'
import StudentReports from "../../blocks/dashboard/src/StudentReports.web";
import AverageScore from "../../blocks/dashboard/src/AverageScore.web";

const Reports = (props:any) =>{
  return (
     <Box style={webStyle.backgroundCLr} className="course-page">
        <InstitutionalHeader navigation={props?.navigation} id={"3"} />
        <StudentReports navigation={undefined} id={""}/>
        <div style={{paddingBottom:'50px'}}>
        <AverageScore id={""} navigation={undefined}/>
        </div>
        </Box>
  )
}

const webStyle = {
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
  },
};

export default Reports;