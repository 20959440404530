// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import './styles/customForm.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class MedicalJourney extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     /* istanbul ignore next */
    return (
      // Customizable Area Start
        <LoginComp 
          navigation={undefined} 
          id={""} 
          setup={true}
          onhover={this.handleIconHover}
          onleave={this.handleIconLeave}
          showInfo={this.state.showInfo}
          bgImgColor={'linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(38, 197, 243, 0.25) 50%, rgba(85, 145, 245, 0.5) 100%)'}
          heading={this.medicalJourneyHeading}
          infoText1={this.medicalJourneyInfoText}
          infoTextStyle={"desc-text"}
          backBtn={true}
          goBack={this.goBack}
          buttonText={'Next'}
          btnDisabled={this.state.medicalJourneyBtn}
          onclick={this.handleMdJourney}
          customForm={
            <ProgressBar
              navigation={undefined}
              id={""}
              role={true}
              vertical={true}
              journey={this.state.journeyStep}
              institution={false}
              immediateGoal={false}
              futureGoal={false}
              step1={true}
              step2={true}
              step3={false}
              step4={false}
              step5={false}
              step6={false}
            />
          }
          formContent={
            <div className="mdjourney-container" data-testid="mdJomdJourneyContainer">
             
            {this.state.MedicalJourneyState?.map((mdJourney:any, index:any) => {
              const isSelected = this.state.selectedMdJourney === mdJourney.id;
              return(
                  <div key={mdJourney.id} className={`${isSelected ? 'mdJourney-wrapper mdJourney-selected-wrapper' : 'mdJourney-wrapper'}`} data-testid={`${mdJourney.name}-wrapper`}>
                    <span className={`${isSelected ? 'mdJourney-icon  mdJourney-selected-icon' : 'mdJourney-icon'}`} data-testid={`${mdJourney.name}-iconWrapper`}>
                      <img src={mdJourney.image?.url} alt={`${mdJourney.name}-icon`} data-testid={`${mdJourney.name}-icon`}  />
                    </span>
                    <label 
                      htmlFor={`mdJourney-${index}`}
                      className={`${isSelected ? 'mdJourney-label  mdJourney-selected-label' : 'mdJourney-label'}`}
                      data-testid={`${mdJourney.name}-label`}
                    >
                      {mdJourney.name}
                    <input 
                      type="radio" 
                      id={`mdJourney-${index}`}
                      name="mdJourney"
                      value={mdJourney.name}
                      className="radioBtn"
                      onChange={() => this.handleMdJourneyChange(mdJourney.id)} 
                      checked={isSelected}
                      data-testid={`${mdJourney.name}-mdJourney`} 
                    />
                    </label>
                  </div>
            )})}
            </div>
          }
        />
      // Customizable Area End
    );
  }
}
// Customizable Area End
