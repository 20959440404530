import React, { useState } from "react";
import { 
  Box, 
  Typography, 
  IconButton 
} from "@mui/material";
import { 
  checkPNG, 
  downArrow, 
  skipCancelPNG, 
  skipEditPNG, 
  skipVector, 
  swapVector
} from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NoRecord from "./NoRecord.web";

// Define Student Data Type
interface Student {
  name: string;
  courses: number;
  email: string;
  progress: number;
  lastLogin: string;
  id: string;
  status: "Active" | "Inactive";
}
const StudentTable=({
  detailsData,
  navigateStudentDetail,
  getStudentTableDetails,
  setQueryData
}:any) => {
  const records = detailsData?.studentDetails?.meta || {} as any
  const totalPages = Math.ceil((records.totalRecords/records.per) as any)
  const studentsData = detailsData?.studentDetails?.data?.map((item:any) => ({
    id:item?.id,
    name: item?.attributes?.student_name,
    courses: item?.attributes?.number_of_courses,
    email: item?.attributes?.email,
    progress: item?.attributes?.average_course_progress,
    lastLogin: item?.attributes?.last_login 
      ? new Date(item?.attributes?.last_login)?.toLocaleString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })?.replace(",", " •") 
      : "N/A",
    status: item?.attributes?.status,
  })) || [];
  const [orderBy, setOrderBy] = useState('average_course_progress')
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC");
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const getPageNumbers = () => {
    const startPage = Math.max(1, page - 1); 
    const endPage = Math.min(totalPages, startPage + 2); 
  
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  // Sorting Function
  const handleSort = (propertyVal?: string,pageNumber?:number) => {
    let property = propertyVal || orderBy
    const isSameColumn = propertyVal && orderBy === property;
    const newOrder = isSameColumn ? (order === "ASC" ? "DESC" : "ASC") : order ;
    setOrder(newOrder);
    setOrderBy(property);
    if(pageNumber){
      setPage(pageNumber)
    }
    getStudentTableDetails(undefined)
    setQueryData({sortBy:property,order:newOrder,page:pageNumber || page,per:rowsPerPage})
  };

  // Sorted & Paginated Data
  
  const getTitle = (title: string) => {
    const sortKeyMap = {
      "Avg. Course Progress": "average_course_progress",
      "Number of Courses": "number_of_courses",
      "Last login": "last_login",
    } as any;
  
    if (!sortKeyMap[title]) return title;
    const isSorted = orderBy === sortKeyMap[title];
    const icon = isSorted ? downArrow : swapVector;
    let imgStyle = order === 'ASC' ? 
    {
      marginBottom: "-3px"
    } : {transform:'rotate(180deg)'}
  
    return (
      <span  style={ { display: "flex", justifyContent:title === "Last login" ? 'center':'flex-start', alignItems: "center", gap: "4px", cursor: "pointer" } }>
        {title}
        <span  id="columnId"
          onClick={() => handleSort(sortKeyMap[title])}
          >
        <img
          src={icon}
          height={isSorted ? '10px' :'12px'}
          width={isSorted ? '10px' :'12px'}
          style={{...imgStyle}}
        />
        </span>
        
      </span>
    );
  };
 
  return (
    <Box sx={{ backgroundColor: "#0f172a", padding: "20px", borderRadius: "10px", width: "100%" }}>
      <TableContainer style={{ borderRadius: '8px 8px 0px 0px', backgroundColor: '#0f172a' }}>
        <Table aria-label='simple table' style={{ backgroundColor: '#0f172a !important' }}>
          <TableHead >        
            <TableRow >
              {["Student name", "Number of Courses", "Email", "Avg. Course Progress", "Last login", "Status", "   "].map((title) => {
                return (
                  <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', opacity: '100%', textTransform: 'none', color: 'rgba(100, 116, 139, 1', fontFamily: 'Raleway', position: 'relative' ,width:'16%'}} align={new Set(['Avg. Course Progress', 'Status']).has(title) ? 'center' : 'left'} width={'16%'}>
                    {getTitle(title)}
                  </TableCell>

                )
              })}

            </TableRow>
          </TableHead>
          
          <TableBody>
            {!studentsData.length && (
              <Box style={{
                position: 'relative',
                left: '195%'
              }}>
                <NoRecord />

              </Box>
            )}
            {studentsData?.map((row: Student, index: number) => (
              <>
                <TableRow
                  className="MuiTableRow-root"
                  key={index}
                  style={{
                    borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px',
                    borderTopRightRadius: '20px', borderBottomRightRadius: '20px',
                    height: "52px",
                    backgroundColor: "rgba(24, 34, 50, 1)",
                    marginBottom: "10px",
                    padding: 0,
                  }}>

                  <TableCell
                    className="MuiTableCell-root"
                    style={{
                      ...webStyle.tableCellBody,
                      borderRadius: "8px 0 0 8px",
                      color: "rgba(255, 255, 255, 1)",
                      fontFamily: "Poppins",
                      borderBottom: "none",
                      height: '100%',
                      padding: 0,

                    }}
                  // align="left"
                  >

<div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginLeft: "10px", 
  maxWidth: "100%", 
  overflow: "hidden" 
}}>
  <span style={{ 
    fontFamily: "Poppins", 
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    textOverflow: "ellipsis", 
    maxWidth: "calc(100% - 30px)"  // Adjust based on image width
  }}>
    {row.name}
  </span> 
  <span style={{ marginRight: "10px" }}>
    <img src={skipVector} alt="Skip" />
  </span>
</div>

                  </TableCell>
                  <TableCell
                    style={{
                      ...webStyle.tableCellBody,
                      color: 'rgba(255, 255, 255, 1)',
                      fontFamily: 'Poppins',
                      borderBottom: 'none',
                    }}
                    align="center"
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <span style={{ ...webStyle.tableCellText, fontFamily: 'Poppins', flex: 1, textAlign: 'center' }}>
                        {row.courses}
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        <img src={skipVector} style={{ marginLeft: '-24px' }} />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...webStyle.tableCellBody,
                      color: 'rgba(255, 255, 255, 1)',
                      fontFamily: 'Poppins',
                      borderBottom: 'none',
                    }} align='right'
                  >
                    <div style={{
                      ...webStyle.tableCellText,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginRight: "15px",
                      maxWidth: "100%",
                      overflow: "hidden"
                    }}>
                      <span style={{
                        fontFamily: "Poppins",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "calc(100% - 30px)"  // Adjust to prevent collision with the image
                      }}>
                        {row.email}
                      </span>
                      <span>
                        <img src={skipVector} alt="Skip" />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    className="MuiTableCell-root"
                    style={{
                      ...webStyle.tableCellBody,
                      color: "rgba(255, 255, 255, 1)",
                      fontFamily: "Poppins",
                      borderBottom: "none",
                      height: '100%',
                      padding: 0,
                    }}
                    align="center"
                  >

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <span style={{ ...webStyle.tableCellText, fontFamily: 'Poppins', flex: 1, textAlign: 'center' }}>
                        {row.progress}%
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        <img src={skipVector} style={{ marginLeft: '-24px' }} />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...webStyle.tableCellBody,
                      color: 'rgba(255, 255, 255, 1)',
                      fontFamily: 'Poppins',
                      borderBottom: 'none',
                    }} align='right'
                  >

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <span style={{ ...webStyle.tableCellText, fontFamily: 'Poppins', flex: 1, textAlign: 'center',}}>
                        {row.lastLogin}
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        <img src={skipVector} style={{ marginLeft: '-24px' }} />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...webStyle.tableCellBody,
                      color: 'rgba(255, 255, 255, 1)',
                      fontFamily: 'Poppins',
                      borderBottom: 'none',

                    }} align='right'
                  >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                      <div
                        style={{
                          backgroundColor: row.status === "Active" ? "#2F515B" : "#4A3746",
                          color: row.status === "Active" ? "#72EFDD" : '#F87171',
                          padding: "2px",
                          borderRadius: "20px",
                          textAlign: "center",
                          fontWeight: "400",
                          fontFamily: 'Poppins',
                          position: "relative",
                          width: row.status === "Active" ? '82px' : '94px',
                          marginLeft:row.status ==="Active" ? '-12px' :'0px'
                        }}
                      >
                        <span style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '4px',
                          textAlign: 'center',
                        }}>{row.status === "Active" ? <img src={checkPNG} /> : <img src={skipCancelPNG} />} {row.status}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{
                    ...webStyle.tableCellBody,
                    borderRadius: "0 8px 8px 0",
                    color: 'rgba(255, 255, 255, 1)',
                    fontFamily: 'Poppins',
                    borderBottom: 'none',
                  }}>
                    <IconButton sx={{ color: "#94a3b8", backgroundColor: '#353E4F', height: '24px', width: '24px' }} onClick={() =>navigateStudentDetail(row.id)}>
                      <img src={skipEditPNG} />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <br />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
     {totalPages >1 && (<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px", gap: "10px" }}>
        {getPageNumbers().map((pageNumber) => (
          <Typography sx={{ ...webStyle.paginationTxt, ...(page === pageNumber ? webStyle.gradientBorder : { opacity: 0.7 }) }} onClick={() => handleSort(undefined,pageNumber)} data-test-id="page1">{pageNumber}</Typography>
        ))}
      </Box>)} 
      
    </Box>
  );
};

export default StudentTable;
const webStyle = {
  tableHead: {
    backgroundColor: '#21355F',
    borderRadius: '8px 8px 0px 0px',
  },
  paginationTxt:{
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '25px',
      letterSpacing: '0%',
      textAlign: 'center',
      verticalAlign: 'middle',
      color: "rgba(255, 255, 255, 1)",
      cursor:'pointer'
    
  },
  gradientBorder: {
    borderBottom: '2px solid rgb(95, 210, 255)', // You can use transparent to create the effect
    backgroundClip: 'text', // Makes the gradient effect visible
  },
  tableCellText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300
  },
  tableCellRow: {
    color: '#FFFFFF',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Raleway',
    fontSize: '14px',
    opacity: '60%',
    fontWeight: 400

  },
  tableCellBody: {
    width: 'fit-content',
    height: '22px',
    color: ' rgba(255, 255, 255, 1)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWright: 300,
  },

  text: {
    fontWeight: "bold",
    marginLeft: '10px'
  },
  status: {
    height: "28px",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "12px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  courses: {
    display: "flex",
    cursor: "pointer",
    width: "109px",
    height: "35px",
    borderRadius: "50px",
    background: "#FFFFFF1A ",
    fontWeight: 300,
    fontSize: "14px",
    opacity: "80%",
    color: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};