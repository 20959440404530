import React, { useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { Button, CircularProgress } from "@material-ui/core";
import { library, schedule } from "./assets";
const JourneyScroll = ({
  journeyView,
  isHighLight,
  loading,
  enrollInCourse,
  handleRedirectToCourse,
  handleBorder,
  handleData
}: any) => {
  const scrollContainerRef = useRef(null) as any;
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Handle mouse events for smooth scrolling
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current?.offsetLeft);
    setScrollLeft(scrollContainerRef.current?.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Multiply for faster scroll
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  // Swipe handlers for touch scrolling
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += eventData.deltaX;
      }
    },
    onSwipedRight: (eventData) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft -= eventData.deltaX;
      }
    },
    trackMouse: true,
  });

  return (
    <Box
      {...handlers}
      ref={(el) => {
        scrollContainerRef.current = el;
        handlers.ref(el as any);
      }}
      style={{
        overflowX: "auto",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        marginTop: "10px",
        overflowY: "hidden",
        margin: "20px",
        padding: "10px",
        paddingLeft: "35%",
        scrollBehavior: "smooth",
        cursor: isDragging ? "grabbing" : "grab",
        userSelect: "none",
      }}
      className="overall_card_journey"
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Grid
        container
        spacing={4}
        style={{ display: "flex", flexWrap: "nowrap" }}
      >
        {journeyView?.attributes?.sub_categories?.data.map(
          (journeyData: any) => (
            <Grid
              item
              lg={3}
              style={{ margin: "auto", minWidth: "368px" }}
              key={journeyData.id}
            >
              <Box
                className="cardParent"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "stretch",
                }}
              >
                <>
                  <Box
                    className={`${
                      isHighLight === journeyData.id
                        ? "borderJourney cardMapContainer boxJourney"
                        : "cardMapContainer boxJourney"
                    }`}
                    data-id={journeyData.id}
                    onClick={handleBorder}
                  >
                    {loading ? (
                      <Box style={{ textAlign: "center", margin: "auto" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <Box style={{ position: "relative" }}>
                          {journeyData?.attributes.completed_percentage > 0 && (
                            <div
                              className="percentCircle"
                              style={{
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    zIndex: 999,
                                  }}
                                >
                                  <Box
                                    position="relative"
                                    display="inline-flex"
                                  >
                                    <Box
                                      position="relative"
                                      display="inline-flex"
                                    >
                                      <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        style={{ color: "#e0e0e0" }}
                                      />
                                      <CircularProgress
                                        variant="determinate"
                                        value={
                                          journeyData?.attributes
                                            .completed_percentage
                                        }
                                        style={{
                                          color: "#72EFDD",
                                          position: "absolute",
                                          left: 0,
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      top={0}
                                      left={0}
                                      bottom={0}
                                      right={0}
                                      position="absolute"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                        style={{
                                          color: "#fff",
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontWeight:400,
                                        }}
                                      >
                                        {
                                          journeyData?.attributes
                                            .completed_percentage
                                        }
                                        %
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography
                                    style={{ color: "#fff", fontSize: "12px" ,fontFamily:'Poppins'}}
                                  >
                                    Complete
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            className="cardimagedata_card"
                            style={{
                              width: "100%",
                              height: "171px",
                              opacity: 0.5,
                              margin: "-4px 0px 0px",
                              backgroundImage: handleData(journeyData && journeyData.attributes && journeyData.attributes.image && journeyData.attributes.image.url),                              position: "relative",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              objectFit: "cover",
                            }}
                          />

                          <Box
                            style={{
                              bottom: "24px",
                              left: "25px",
                              position: "absolute",
                              justifyContent: "space-between",
                              display: "flex",
                              width: "88%",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography
                                className="cardTitle"
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Raleway",
                                }}
                              >
                                {journeyData?.attributes.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            padding: "30px 20px",
                            borderBottomLeftRadius: "16px",
                            borderBottomRightRadius: "16px",
                            backgroundColor: "#172130",
                            margin: "-5px 5px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              marginTop: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Box style={{ display: "flex" }}>
                              <img
                                src={library}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "13px",
                                }}
                                className="font-family-p"
                                data-test-id="unit-count"
                              >
                                {`${
                                  journeyData?.attributes.all_units_count || 0
                                } Unit${
                                  journeyData?.attributes.all_units_count > 1
                                    ? "s"
                                    : ""
                                }`}
                              </Typography>
                            </Box>
                            <Box
                              fontSize="small"
                              style={{
                                marginRight: 5,
                                display: "flex",
                                gap: 4,
                              }}
                            >
                              <img
                                src={schedule}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "13px",
                                  fontFamily: "Poppins",
                                }}
                                className="font-family-p"
                              >
                                {journeyData?.attributes.duration}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              marginTop: "20px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="course_desc"
                              style={{
                                overflow: "auto",
                                height: "auto",
                                maxHeight: "130px",
                                paddingRight: "12px",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "14px",
                                  textAlign: "justify",
                                  fontFamily: "Raleway",
                                  marginBottom:'30px'
                                }}
                              >
                                {journeyData?.attributes.description}
                              </Typography>
                            </div>
                          </Box>
                          <Box style={{ textAlign: "center" }} />
                        </Box>
                      </>
                    )}
                  </Box>
                  {isHighLight === journeyData.id ? (
                    <Button
                      className="quebankbtns getStartbtn"
                      variant="contained"
                      id="enrollCourseBtn"
                      onClick={() => {
                        enrollInCourse(journeyData?.attributes?.id);
                        handleRedirectToCourse(journeyData?.attributes.id);
                      }}
                      style={{
                        width: "200px",
                        height: "44px",
                      }}
                    >
                      {journeyData?.attributes.completed_percentage == 0.0
                        ? "Get Started!"
                        : journeyData?.attributes.completed_percentage == 100
                        ? "Review"
                        : "Continue"}
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default JourneyScroll;
