export const Univeristy = require("../assets/university.svg")
export const Platform = require("../assets/platforn.svg")
export const Pending = require("../assets/pending.svg")
export const Completed = require("../assets/completed.svg")
export const Filter = require("../assets/filter.svg")
export const bookLight = require("../assets/skip_library_books.svg");
export const timer = require("../assets/timer.svg");
export const courseimage = require("../assets/image_course.svg");
export const arrowup = require("../assets/arrowup.svg");
export const arrowdown = require("../assets/arrowdown.svg");
export const circle=require("../assets/viewcircle_updates.svg")

export const activestudents=require("../assets/activestudents.svg")
export const averagegenerate=require("../assets/averagerate.svg")
export const completionrate=require("../assets/completionrate.svg")
export const frequency=require("../assets/frequency.svg")
export const courserate=require("../assets/courserate.svg")
export const exportsValue=require("../assets/export.svg")
export const arrowDown=require("../assets/arrow_down.svg")

