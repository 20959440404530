import React from "react";
import { Filter, arrowDown, courserate } from "./assets";
import AverageScoreController, { Props } from "./AverageScoreController.web";
import "./Header.css";
import {
  Box,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from "@mui/material";
import AnalyticsController from "../../analytics/src/AnalyticsController.web";
export default class AverageScore extends AnalyticsController {
  
  averageStatus = () => {
    let averageScore = this.state.averageValues.slice(0,this.state.viewAll ? this.state.averageValues.length : 5);
    console.log(averageScore,"averageScore")
    return (
      <Box className="highlightMainContainer" style={{ border: '1px solid rgba(30, 41, 59, 1)', width: '90%' }}>
        <Box style={webStyle.rowDirection}>
          <h4 data-test-id="clicknext" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: "50px" }}>
            Average Course Scores
          </h4>
          <div style={{ gap: "8px", display: "flex" }}>
          
            <div
              style={{
                ...webStyle.courses,
                width: "180px",
                height: "35px",
                borderRadius: "50px",
                justifyContent: "space-around",
                alignItems: "center",
                padding: '2px',
                display: "flex",
                fontWeight: 500,
              }}
            >
              {["All", "Failed", "Passed"]?.map((type, index) => (
                <div
                  key={index}
                  onClick={() => this.setState({ scoreValues: type }, () => this.scoreChart(type))}
                  style={{
                    ...webStyle.status,
                    color: 'rgba(255, 255, 255, 1)',
                    opacity: this.state.scoreValues === type ? "100%" : "80%",
                    borderRadius: "50px",
                    backgroundColor: this.state.scoreValues === type ? "rgba(100, 116, 139, 1)" : "transparent",
                    fontWeight: this.state.scoreValues === type ? 600 : 500,
                    width: type == 'All' ? '40px' : '60px',
                  }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </div>
              ))}
            </div>
            <Box
              style={{
                position: "relative",
               marginTop:'-5px',
                width: "375px",
                height:'46px',
                borderRadius: "24px",
                border: '1.5px solid rgba(255, 255, 255, 0.05)',
                backgroundColor:'rgba(255, 255, 255, 0.1)',
                opacity:0.8
              }}
            >
            
              {this.inputCourse('courseName',this.handleInputChange,"Search course name", <SearchIcon style={{
              color: "white",
              opacity: 0.7
            }}/>)}


            </Box>
           </div>


        </Box>
        <TableContainer style={{ borderRadius: '8px 8px 0px 0px', marginBottom: '55px',backgroundColor:'rgb(18, 28, 40)' }}>
          <Table aria-label='simple table'>
            <TableHead >
              <TableRow >

                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', opacity: '100%', textTransform: 'uppercase', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway', left: '80px', position: 'relative' }} align='left'>Course Name</TableCell>
                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', textTransform: 'uppercase', position: 'relative', textAlign: 'right', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway' }} align='right'>Avg. Scores by Course</TableCell>
                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', textTransform: 'uppercase', textAlign: 'right', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway' }} align='right'>Pass/Fail Rates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {averageScore?.map((row: any) => (
                <>
                  <TableRow
                  className="MuiTableRow-root"
                    key={row?.attributes?.name}
                    style={{
                      borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',
                      borderTopRightRadius:'20px',borderBottomRightRadius:'20px',
                      height: "52px",
                      boxShadow: 'inset 1px 1px 1px rgba(51, 65, 85, 1), inset -1px -1px 1px rgba(51, 65, 85, 1), inset 1px -1px 1px rgba(51, 65, 85, 1), inset -1px 1px 1px rgba(51, 65, 85, 1)',
                      backgroundColor: "rgba(24, 34, 50, 1)",
                      marginBottom: "10px",
                      padding: 0,
                    }}>
                    <TableCell
                    className="MuiTableCell-root"
                      style={{
                        ...webStyle.tableCellBody,
                        borderRadius: "20px 0 0 20px",
                        color: "rgba(255, 255, 255, 1)",
                        fontFamily: "Poppins",
                        borderBottom: "none",
                        height: '100%',
                        padding: 0,
                      }}
                      align="left"
                    >

                      <div style={{ display: "flex", alignItems: "center", justifyContent: 'left', gap: '30px' }}>
                        {row?.attributes?.image &&
                        <img
                          style={{ width: "40px", height: "40px", borderRadius: "15px", position: 'relative' ,marginLeft:'5px',
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                          }}
                          src={row?.attributes?.image?.url}
                          alt="Course Icon"
                        />
  }
                        <span style={{  fontFamily: "Poppins"}}>{row?.attributes?.name}</span>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableCellBody,
                        textAlign: 'left',
                        marginLeft: '20px',
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'Poppins',
                        borderBottom: 'none'
                      }}
                      align='center'>
                      <div
                        style={{
                          display: "flex",
                          marginRight: '30px',
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            position: "relative",
                            background: `conic-gradient(
                                          from 0deg, 
                                          #72EFDD 0% ${row?.attributes?.average_score * 0.33}%, 
                                          #26C5F3 ${row?.attributes?.average_score * 0.66}%, 
                                           #6A00F4 ${row?.attributes?.average_score}%, 
                                          rgba(255,255,255,.1) ${row?.attributes?.average_score}% 100%
                                        )`}}
                        >
                          <div className="donut-hole-3" />
                          <div className="donut-data" style={{ fontFamily: "Poppins", }}>
                          {row?.attributes?.average_score}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableCellBody,
                        borderRadius: "0 20px 20px 0",
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'Poppins',
                        borderBottom: 'none',
                      }} align='right'
                    >
                      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight:'15px'}}>
                        <div style={{ backgroundColor: this.handleColor(row?.attributes?.average_score), borderRadius: '20px', height: '28px', width: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'left', fontFamily: 'Poppins', }}>
                        {row?.attributes?.average_score}/100
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <br/>
                </>
              ))}
            </TableBody>
          </Table>
          {this.state.averageValues?.length>5 &&(<Box style={{display:'flex',justifyContent:'center', gap:'8px',alignItems:'center', marginTop:'12px'}}>
            <Typography style={{
              color: 'rgba(100, 116, 139, 1)',
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: 800,

            }}>{!this.state.viewAll ? 'View all':'View less'}</Typography>
            <span test-id='viewAllBtn' onClick={() => this.setState({viewAll:!this.state.viewAll})} style={ this.state.viewAll ? {transform:'rotate(180deg)', cursor:'pointer'} : {cursor:'pointer'}}><img src={arrowDown} /></span>
          </Box>)}
          

        </TableContainer>
      </Box>
    );
  };
   inputCourse = (key: string, onChange: any,placeHolder:string, icon: React.ReactNode) => {
    return (<TextField
      value={this.state.searchValue}
      variant="standard"
      name={key}
      autoComplete="off"
      style={{width:'100%'}}
      InputProps={{
        disableUnderline: true,
        style: {
           width:'100%',
          color: "rgba(255, 255, 255, 1)",
          fontSize: "14px",
          padding: "12px 12px",
          fontFamily: "Poppins",
          fontWeight: 300,
       
        },
        startAdornment: icon && (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}  
      onChange={onChange}
      placeholder={placeHolder}
      
    />)
  }
  render() {
    return this.averageStatus();
  }
}

const webStyle = {
  tableHead: {
    backgroundColor: '#21355F',
    borderRadius: '8px 8px 0px 0px',
  },

  tableCellRow: {
    color: '#FFFFFF',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Raleway',
    fontSize: '12px',
    opacity: '60%',
    fontWeight: 400

  },
  tableCellBody: {
    width: 'fit-content',
    height: '22px',
    color: ' rgba(255, 255, 255, 1)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWright: 300,
  },

  text: {
    fontWeight: "bold",
    marginLeft: '10px'
  },
  status: {
    height: "28px",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "12px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  courses: {
    display: "flex",
    cursor: "pointer",
    width: "109px",
    height: "35px",
    borderRadius: "50px",
    background: "#FFFFFF1A ",
    fontWeight: 300,
    fontSize: "14px",
    opacity: "80%",
    color: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
