// Customizable Area Start
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { deselect, filter,filterPNG, select,search } from "./assets";
import { Divider } from "@material-ui/core";
const TopNav = ({searchDetails,handleInputChange,handleFilter,handleCourseClick,handleStatus}:any) => {
  const courseValue=searchDetails?.courseDataValue;
  const taskSelection = () => {
    return (
      <div className="taskselection" style={{
        backgroundColor: '#050D15',
        padding: '3% 100px 3%',
        borderRadius: '0 0 50px 50px',
        position: 'relative',
        width: '100%'
      }}>
        <Box sx={{ display: 'flex',justifyContent:'space-between' }}>
          <h2
            style={{
              color: 'white', fontFamily: 'Raleway', fontSize: '24px',
              fontWeight: 500,
              margin: 0, marginLeft: '15px'
            }}
          >
            <span style={{ marginLeft: '8px', color: 'white', fontWeight: 'bold', fontFamily: 'Raleway', fontSize: '24px', }}> Students List</span>
          </h2>
          <Box 
          sx={{display:'flex', gap:'16px'}}>          
            <Box
              sx={{
                position: "relative",
                width: "477px",
                borderRadius: "24px",
                border: "2px solid #1F252C",
                bgcolor: "#111921",
              }}
            >
              {/* Input Label (Floating Effect) */}
              {inputField2('firstName',handleInputChange,"Search by Name or Email", <SearchIcon style={{
              color: "white",
              opacity: 0.7
            }}/>)}


            </Box>
            <Box
              sx={{
                position: "relative",
                width: "94px",
                borderRadius: "24px",
                border: "2px solid #1F252C",
                bgcolor: "#111921",
                cursor:'pointer'
              }}
            >  <Button
             id="filter-button"
              disableRipple
              onClick={handleFilter}
              sx={{
                
                color: "white",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor:'transparent',
                textTransform: "none",
                height: '100%',
                width: '100%'
              }}
            >

                {<img src={filterPNG} width={"13px"} height={'13px'} style={{ opacity: searchDetails?.filterValue ? 1 : 0.7, color: "#FFFF", textAlign: 'center', cursor: 'pointer' }} />}
                <span style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: 300, opacity: searchDetails?.filterValue ? 1 : 0.7 }}>Filter</span>
              </Button>
            {searchDetails?.filterValue && (
              <div
              id="filter-dropdown"
                style={{
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                  position: "absolute",
                  top: "122%",
                  right: "-310%",
                  transform: "translateX(-50%)",
                  width: "580px",
                  height: '279px',
                  backdropFilter: "blur(8px)",
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                  backgroundColor:'rgb(33, 44, 59)',
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  fontWeight: 600,
                  zIndex: 1000,
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "rgb(30, 30, 47) transparent",
                  overflowX:'hidden'
                }}
              >
                <div
                  data-test-id="csv-btn"
                  style={{
                    width: "100%",
                    padding: "10px 10px 10px 12px",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 700,
                    transition: "background 0.3s ease",
                    background: "linear-gradient(90deg, rgba(45, 0, 247, 0.2) 44%, rgba(255, 255, 255, 0.2) 100%)"
                  }}

                >
                  <img src={filter} alt ='filter' style={{marginBottom:"-4px",marginRight:'6px'}}/>
                  Filter by
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                <div
                  style={{
                    padding: "10px 10px 10px 38px",
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    transition: "background 0.3s ease",
                  }}
                >
                  Course
                </div>
                <Divider
                  style={{
                    height:'2px',
                    background:'linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.02) 80%)'
                   , width: "100%", 
                    margin: "0 0 10px 7%", 
                  }}
                />
                <div style={{padding:'10px 10px 10px 37px',display:'flex',gap:'7px',
                  flexWrap: 'wrap', width:'560px'
                }}>
                      {courseValue?.map((course: any) => (
                        <div
                          key={course.id}
                          style={{
                            ...webStyle.Options,
                            fontWeight:searchDetails?.selectedCourse==course.id  ? 500 : 300,
                            cursor: "pointer",
                          }}
                          data-test-id="divcourse"
                         onClick={() => handleCourseClick(course.id,course.name)}
                        >
                          <img src={searchDetails?.selectedCourse==course.id ? select : deselect} />
                          {course.name}
                        </div>
                      ))}
                       <div
                          key={10000}
                          style={{
                            ...webStyle.Options,
                            fontWeight:searchDetails?.selectedCourse==10000  ? 500 : 300,
                            cursor: "pointer",
                          }}
                          data-test-id="divcourse"
                          onClick={() => handleCourseClick(10000,'all courses')}
                        >
                          <img src={searchDetails?.selectedCourse==10000 ? select : deselect} />
                          {'All Courses'}
                        </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px 10px 10px 38px",
                      width: "100%",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 500,
                      transition: "background 0.3s ease",
                    }}
                  >
                  Status
                </div>
                <Divider
                  style={{
                    background:'linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.02) 80%)'
                   , width: "100%", 
                   height:'2px',
                    margin: "0 0 10px 7%", 
                  }}
                />
                <div style={{padding:'10px 10px 10px 37px',display:'flex',gap:'7px',
                  flexWrap: 'wrap', width:'560px'
                }}>
                
                <div
                data-test-id="active"
                  onClick={() => handleStatus('active')}
                     style={{...webStyle.Options,width:'70px',fontWeight:searchDetails?.selectedStatusName=='active'?500:300}}
                >
                      <img src={searchDetails?.selectedStatusName=='active' ? select : deselect} />
                  Active
                </div>
                <div
                 data-test-id="inactive"
                 onClick={() => handleStatus('inactive')}
                     style={{...webStyle.Options,width:'81px',fontWeight:searchDetails?.selectedStatusName=='inactive'?500:300}}
                >
                   <img src={searchDetails?.selectedStatusName=='inactive' ? select : deselect} />
                   Inactive
                </div>
                <div
                  data-test-id="allstatuses"
                  style={{...webStyle.Options,fontWeight:searchDetails?.selectedStatusName=='all statuses'?500:300}}
                  onClick={() => handleStatus('all statuses')}
                >
                   <img src={searchDetails?.selectedStatusName=='all statuses' ? select : deselect} />
                   All Statuses
                </div>
               
                </div>
              </div>
            )}
        </Box>
        </Box>
        </Box>
      </div>
    );
  };
  const inputField2 = (key: string, onChange: any,placeHolder:string, icon: React.ReactNode) => {
    return (<TextField
    value={searchDetails?.searchValue}
      variant="standard"
      name={key}
      autoComplete="off"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
           <img src={search} height={'20px'} width={'20px'} style={{opacity:'0.7', marginTop:'-4px', marginLeft:'4px'}}/>
          </InputAdornment>
        ),
        style: {
          color: "rgba(255, 255, 255, 1)",
          fontSize: "14px",
          padding: "8px 12px",
          fontFamily:'Poppins',
          fontWeight:300,
        
        },
      }}
      sx={{
        mt: 1,
        "& input": { color: "white" },
        '& .MuiInputBase-input': {
          paddingTop: '0px', 
        },
        '& .MuiInputBase-root': {
          paddingTop: 0,
        },
        width:'100%'

      }}
      
      onChange={onChange}
      placeholder={placeHolder}
      
    />)
  }
 

  return (
    <Box style={webStyle.backgroundCLr}>
      {taskSelection()}
    </Box>
  );
}

const webStyle = {
  Options:{
    marginBottom:'5px',
    borderRadius:'20px',
    background:'rgba(255, 255, 255, 0.1)',
    padding: "10px 20px 10px 8px",
    width: "auto",
    height:'28px',
    display:'flex',
    alignItems:'center',
    cursor: "pointer",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: true?300:500,
    gap:'5px',
    transition: "background 0.3s ease",
  },
  Student: {
    fontFamily: "Poppins", fontSize: "12px", opacity: '70%', color: 'white'
  },
  data: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', fontFamily: 'Poppins'
  },
  imageStyle: {
    width: '25.8px', height: "24px"
  },
  headingValue: {
    opacity: '70%',
    fontFamily: "Raleway",
    color: 'white',
    fontWeight: 700,
    fontSize: "12px",
    margin: 0
  },
  divHeading: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'
  },
  header: {
    display: 'flex', justifyContent: 'space-between',
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  dataAnalytics: {
    fontFamily: "Poppins", fontSize: "40px", color: 'white', marginRight: '10px'
  },
  week: {
    width: '51px',
    height: '24px',
    cursor: "pointer",
    fontWeight: 500,
    fontSize: '12px', color: '#FFFFFF', display: 'flex',
    justifyContent: 'center', alignItems: 'center',
    fontFamily: 'Poppins',
  },
  courses: {
    display: 'flex',
    cursor: "pointer",
    width: '109px', height: '35px', borderRadius: '50px', background: '#FFFFFF1A', fontWeight: 300, fontSize: '14px', opacity: '80%', color: '#FFFFFF',
    justifyContent: 'center', alignItems: 'center',
  },
  headerBox: {
    width: '273px',
    height: '144px',
    margin: '20px',
    borderRadius: '20px',
    padding: '8px',
    backgroundColor: '#121c28',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    paddingBottom: '40px'

  }

};
export default TopNav;
// Customizable Area End