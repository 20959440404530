import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import {  withStyles } from "@material-ui/core/styles";
import { Filter,arrowdown,arrowup ,circle} from "./assets";
import HighlightController, { Props } from "./HighlightController.web";
import "./Header.css";
import {
  Box,
  Menu,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default class Graph extends HighlightController {


  graphData = () => {
    const {  graphData} = this.state;
   
   
    const activeStudents = graphData?.data?.map((item:any )=> item?.active_students) || [];
    const graphTimeValue=graphData?.filter;
    const maxStudents = Math.max(...(activeStudents.length ? activeStudents : [0]), 20);
    const thresholds = [20, 100, 500, 1000, 1500, 2000];
    const dynamicMax = thresholds.find((t) => maxStudents <= t) || maxStudents;
    const dynamicStepSize = Math.ceil(dynamicMax / 4);   
    const labelValue = graphData?.data?.map((item: any) => {
      if (graphTimeValue === "weekly") {
          return moment(item?.time, "dddd, D MMM. YYYY")?.format("ddd, D");  
        
      } else if (graphTimeValue === "yearly") {
          return moment(item?.x_axis_value, "MMM YYYY")?.format("MMM, YY");  
        
      }
      return item?.x_axis_value;
  }) || [];
    const data = {
      labels: labelValue,
      datasets: [
        {
          label: "",
          data: activeStudents,
          backgroundColor: "rgba(114, 239, 221, 1)",
          barThickness: this.handleValueBar(),
          hoverBackgroundColor: "rgba(114, 239, 221, 0.7)",
          borderRadius: 5,
          borderSkipped:false
        },
      ],
    };

    const options = {
      responsive: true,
      datasets: {
        bar: {
          barThickness: 10,
          categoryPercentage: 0.5,
          barPercentage: 0.6,
        }
      },
      plugins: {
        legend: { display: false },
        title: { display: false },
        tooltip: {
          borderWidth: 2, 
          borderColor: "rgba(255, 255, 255, 0.05)", 
          fontFamily: 'Poppins',
          backgroundColor: "#121B28",
          cornerRadius: 20,
          margin:10,
          padding:10,
          caretSize: 0,
          bodyFont: {
            family: 'Poppins'
          },
          titleFont: {
            family: 'Poppins'
          },
          bodySpacing: 6,
          boxPadding: 5,
          callbacks: {
            title: (tooltipItems: any) => {
              let index = tooltipItems[0].dataIndex;
              const labelValue = graphData?.data?.map((item:any )=> item?.time) || [];
              return labelValue[index] || ""
            },
            label: (tooltipItem: any) => {
              let value = tooltipItem.raw;
              return `Active students: ${value}`
            },
            labelColor: function() {
              return {
                backgroundColor:' #72EFDD',
                borderColor:'rgba(38, 197, 243, 0.2)',
                borderWidth: 6,
                borderRadius: 6, 
              };
            }
          }
        }
      },
      scales: {
        x: {
          grid: { display: false },
          ticks: {
            font: { size: 12, family: "Poppins" },
            color: "rgba(255, 255, 255, 0.5)",
            maxRotation: 0,
            minRotation: 0,
            callback: function (value: any, index: number) {
              if (graphTimeValue === "daily") {
                return index % 2 === 0 ? labelValue[index] : "";
              }
              if (graphTimeValue === "weekly") {
                return labelValue[index];
              }
              if (graphTimeValue === "monthly") {
                return index % 6 === 0 ? labelValue[index] : "";
              }
              if (graphTimeValue === "yearly") {
                return labelValue[index];
              }
              return "";
            },
          },
          border: { display: false },
        },
        y: {
          grid: {
            color: "rgba(141, 141, 141, 0.2)",
            drawBorder: false,
          },
          ticks: {
            stepSize: dynamicStepSize,
            min: 0,
            max: dynamicMax,
            callback: function (value: any) {
              return value >= 1000 ? value / 1000 + "k" : value;
            },
          },
          border: { display: false },
        },
      },
    };  

    return (
      <Box className="highlightMainContainer" style={{ marginTop: "80px",border: '1px solid rgba(30, 41, 59, 1)'}}>
        <Box style={webStyle.rowDirection}>
          <h4 data-test-id="clicknext" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: "50px" }}>
            Student Activity Chart
          </h4>
          <div style={{ display: "flex", gap: "8px" }}>
            <div
            style={{ ...webStyle.courses ,
                 width: "266px",
                 fontWeight:500,
                height: "35px",
                borderRadius: "50px", 
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
            }}
            >
              {["daily", "weekly", "monthly", "yearly"]?.map((type, index) => (
                <div
                  key={index}
                  onClick={() => this.setState({ graphTime: type }, () => this.graphChart(type))}
                  style={{
                    ...webStyle.week,
                    color:'rgba(255, 255, 255, 1)',
                    marginLeft:this.state.graphTime=='daily'?'3px':'0px',
                    backgroundColor: this.state.graphTime === type ? "rgba(100, 116, 139, 1)" : "transparent",
                    fontWeight: this.state.graphTime === type ? 600 : 500,
                    opacity: this.state.graphTime === type ? "100%" : "80%",
                    borderRadius: "50px",
                  }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </div>
              ))}
            </div>
            <div style={{ ...webStyle.courses }}>
              <img onClick={(event) => this.handleCourses(event)} src={Filter} style={{ marginLeft: "10px" }} alt="Filter" />
              <Typography onClick={(event) => this.handleCourses(event)} style={{fontFamily: "Poppins",fontSize:'14px',fontWeight:300,opacity:'80%',width:'100%',marginLeft:'5px' }}>
               Courses
            </Typography>
            <StyledMenu
                id="customized-menu-services"
                keepMounted
                style={{ width: '333px'
                }}
                anchorEl={this.state.anchorElServices}
                open={Boolean(this.state.anchorElServices)}
                data-test-id="handleCloseServices"
                MenuListProps={{ autoFocusItem: false }}
                onClose={() => this.handleClose()}
              >
                {this.state.coursesData
                  ?.filter((course: any) => course?.units?.length > 0)
                  ?.map((course: any) => (
                    <div
                      key={course?.id}
                      onMouseEnter={() => this.handleMouseEnter(course?.id)}
                      onMouseLeave={this.handleMouseLeave}
                    >
                      <StyledMenuItem
                        data-test-id="onMenuItemClick"
                        onMouseEnter={() => this.handleMouseEnter(course?.id)}
                        style={{
                          fontWeight: this.state.hoveredUnit == course?.id ? 700 : 300,
                        }}
                        onClick={()=>this.handleSubMenuToggle(course?.id)}
                      >
                        <ListItemText
                          primary={course?.name}
                          primaryTypographyProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight:this.state.hoveredUnit == course?.id || this.state.courseUnit===course?.id ? 700 : 300,
                              border: "1px solid rgba(255, 255, 255, 1) !important",
                            },
                          }}
                        />
                        <img
                          src={this.state.openSubMenu2!=0 &&this.state.openSubMenu2 ==course?.id && this.state.openSubMenu ? arrowup : arrowdown}
                          alt="toggle arrow"
                          style={{ width: "11px", height: "11px" }}
                        />
                      </StyledMenuItem>

                      {this.state.openSubMenu2!=0 && this.state.openSubMenu2 ==course?.id && this.state.openSubMenu &&
                        course?.units?.map((unit: any) => (
                          <StyledMenuItem
                            key={unit?.id}
                           
                            onClick={()=>this.handleMenuClose(unit?.id,course?.id)}
                            onMouseEnter={() => this.handleMouseEnter2(unit?.id)}
                            onMouseLeave={()=>this.handleMouseLeave2()}
                          >
                            <ListItemText
                              primary={unit?.name}
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: this.state.hoveredCourse === unit?.id|| this.state.finalValue===unit?.id? 700 : 300,
                                  marginLeft: "20px",
                                   textWrap:'initial',
                                  transition: "font-weight 0.2s ease",
                                },
                              }}
                            />
                            <img
                              style={{
                                width: "6px",
                                height: "6px",
                                display: this.state.hoveredCourse === unit?.id || this.state.finalValue===unit?.id  ? "inline" : "none",
                              }}
                              src={circle}
                              alt="indicator"
                            />
                          </StyledMenuItem>
                        ))}
                    </div>
                  ))}

              </StyledMenu>
            </div>
          </div>
        </Box>
        <Bar data={data} options={options} height={100} />
      </Box>
    );
  };

  render() {
    console.log(this.state.hoveredCourse,"checkmeeeeeee")
    return this.graphData();
  }
}

const StyledMenuItem = withStyles((theme) => ({
  "& .MuiMenu-list,& .MuiList-root,& .MuiMenu-list": {
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  root: {
    padding: "15px 30px !important",
    backgroundColor: "rgba(30, 41, 59, 1)",
    // backgroundColor:this.state.hoveredCourse === unit?.id  ?"rgba(30, 41, 59, 1)":
  },
  "&:paper": {
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transformOrigin: "66.5px 0px 0px",
   
  },
}))(MenuItem);

const StyledMenu = withStyles((theme) =>({
  paper: {
    borderRadius: 20,
    width:"334px",
    maxHeight:'280px',
    overflowX:'hidden',
    overflowY:'auto',
    scrollbarWidth: "thin",
    scrollbarColor: "#1E293B transparent",
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      marginLeft: '-105px',
      marginTop: '17px',
      border:' 1px solid rgba(30, 41, 59, 1) !important',
      background:' rgba( 0, 0, 0, 0.6 )',
      backdropFilter: 'blur(10px)',
      "@media (max-width: 1030px)": {
        marginLeft: '0px',
      },
      "@media (min-width: 1024px) and (max-width: 1135px)":{
        marginLeft:'0px',
      },
      "@media (min-width: 1135px) and (max-width: 1163px)":{
        marginLeft:'-8px',
      },
      "@media (min-width: 1164px) and (max-width: 1180px)":{
        marginLeft:'-10px',
      },
      "@media (min-width: 1181px) and (max-width: 1203px)":{
        marginLeft:'-30px',
      },
      "@media (min-width: 1204px) and (max-width: 1230px)":{
        marginLeft:'-40px',
      },
      "@media (min-width: 1231px) and (max-width: 1250px)":{
        marginLeft:'-50px',
      },
      "@media (min-width: 1251px) and (max-width: 1270px)":{
        marginLeft:'-60px',
      },
      "@media (min-width: 1289px) and (max-width: 1310px)":{
        marginLeft:'-80px',
      },
      "@media (min-width: 1311px) and (max-width: 1327px)":{
        marginLeft:'-90px',
      },
      "@media (min-width: 1328px) and (max-width: 1345px)":{
        marginLeft:'-100px',
      },
      "@media (max-width: 1290px)": {
        marginLeft:'-72px',
      },
      "@media (max-width: 768px)": {
        marginLeft: '0px',
      },
     
    transformOrigin: "66.5px 0px 0px",
    color: "white", 
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "black !important", 
    },
  },
}))((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const webStyle = {
  text: {
   fontWeight: "bold",
   marginLeft:'10px'
  },
  week: {
    width: "63px",
    height: "28px",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "12px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
  },
 
  courses: {
    display: "flex",
    cursor: "pointer",
    width: "109px",
    height: "35px",
    borderRadius: "50px",
    background: "#FFFFFF1A ",
    fontWeight: 300,
    fontSize: "14px",
    opacity: "80%",
    color: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};


