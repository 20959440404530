import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import HighlightController, {
  Props,
} from "./HighlightController.web";
import "./Header.css";
import { bookLight ,timer} from "./assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
export default class Highlight extends HighlightController {
  constructor(props: Props) {
    super(props);

  }
 
  coursesHighlight = () => {
    return (
      <Box
        className="highlightMainContainer"
        style={{ border: '1px solid rgba(255, 255, 255, 0.05)'
          ,marginTop: "50px" ,
          }}>
        <Box style={webStyle.rowDirection}>
          <h4 datat-test-id="clicknext" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: '20px' }}
          >
            Course Performance
          </h4>
        </Box>
        {
          <Grid
            container
            spacing={3}
              className="CradBox overall_card knowledge"
            data-test-id="recommendedCard"
            style={{
              overflowX: "auto",
              display: "flex",
             
              flexWrap: "nowrap",
              justifyContent: "start",
              marginTop: "10px",
              scrollbarWidth: "thin",
              scrollbarColor: "#1E293B transparent",
            }}

          >
            <>
              {this.state.highlightData?.map(
                (card: any, index: any) => {
                  const highlightCard = card?.attributes;
                  console.log(highlightCard,"highlightCard")
                  return (
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ flexShrink: 0 }}
                      key={index}
                      data-testid={`card-know-med-${index}`}
                    >
                      <Box className="cardMapContainer" style={{ width: "100%" , border: '1px solid rgba(30, 41, 59, 1)',background:'rgba(30, 41, 59, 0.5)'}}>
                        <Box style={{ position: "relative"}}>
                          <img
                            style={{
                              width: "100%",
                               height: "200px",
                               
                               objectFit: "cover",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              opacity: "0.8",
                            }}
                            src={highlightCard?.image?.url}
                            alt="Our Image"
                          />
                          <Box
                            style={{
                              position: "absolute",
                              bottom: "24px",
                              left: "25px",
                              width: "88%",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <div style={{ display: "flex" }}>
                              {highlightCard?.category_image?.[0]?.url &&
                                <div
                                  style={{
                                    background: "rgba(51, 65, 85, 0.6)",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                 
                                  <img
                                    src={highlightCard?.category_image?.[0]?.url}
                                    style={{
                                      color: "white",
                                      width: "90%",
                                      height: "90%",
                                      objectFit: "contain",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>}
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    color: "rgba(255, 255, 255, 1)",
                                     marginBottom:'5px',
                                    textTransform: "uppercase",
                                    fontFamily:'Raleway'
                                  }}
                                  variant="body1"
                                >
                                    {highlightCard?.parent_category_name}
                                </Typography>
                              </div>

                              <Box>
                                <Typography
                                  style={{ minHeight: "60px" ,fontFamily:'Raleway',fontWeight:900,fontSize:'20px'}}
                                >
                                  {highlightCard?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            padding: "10px 20px",
                           
                            margin: '10px',
                            marginBottom:'0px',
                            borderBottomLeftRadius: "16px",
                            borderBottomRightRadius: "16px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 10,
                            }}
                          >
                            <Box style={{ display: "flex" }}>
                              <img
                                src={bookLight}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.7)",
                                  fontFamily: "Poppins",
                                  fontSize: "13px",
                                  fontWeight:400
                                }}
                              >
                                {`${highlightCard?.all_units_count} units`}
                              </Typography>

                            </Box>
                            <Box style={{display:'flex'}}>
                            <img
                                src={timer}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                            <Typography
                              style={{
                                color: "rgba(255,255,255,0.7)",
                                fontFamily: "Poppins",
                                fontSize: "13px",
                                fontWeight:400
                              }}
                            >
                              {`${highlightCard?.duration}`}
                            </Typography>
                            </Box>
                            <Box
                              fontSize="small"
                              style={{ display: "flex", marginRight: 5, gap: 4 }}
                            >
                              <PeopleOutlineOutlinedIcon
                                  style={{ color: "white" }}
                                  fontSize="small"
                                />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.7)",
                                  fontSize: "13px",
                                  fontFamily: "Poppins",
                                  fontWeight:400
                                }}
                              >
                                {highlightCard?.level}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              marginTop: "20px",
                              flexDirection: "column",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                             }}
                          >
                            <div
                              className="course_desc"
                              style={{
                                overflow: "auto", height: "80px", scrollbarWidth: "thin",
                                scrollbarColor: "#1E293B transparent",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.8)",
                                  fontSize: "14px",
                                  fontFamily:'Raleway',
                                  fontWeight:300
                                }}
                              >
                                {highlightCard?.description}
                              </Typography>
                            </div>
                                <div
                                  style={{marginBottom:'10px',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        position: "relative",
                                        background: `conic-gradient(
                                          from 0deg, 
                                          #72EFDD 0% ${highlightCard?.completion_percentage * 0.33}%, 
                                          #26C5F3 ${highlightCard?.completion_percentage * 0.66}%, 
                                           #6A00F4 ${highlightCard?.completion_percentage}%, 
                                          rgba(255,255,255,.1) ${highlightCard?.completion_percentage}% 100%
                                        )`,
                                      }}
                                    >
                                      <div className="donut-hole-3" />
                                      <div className="donut-data" style={{ fontFamily: "Poppins"}}>
                                        {highlightCard?.completion_percentage}%
                                      </div>
                                    </div>
                                    <Typography
                                      style={{ color: "#fff", fontSize: "12px", fontFamily: "Poppins" }}
                                    >
                                      Complete
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                          position: "relative",
                                        
                                         background: `conic-gradient(
                                          from 0deg, 
                                          #72EFDD 0% ${highlightCard?.average_score * 0.33}%, 
                                          #26C5F3 ${highlightCard?.average_score * 0.66}%, 
                                           #6A00F4 ${highlightCard?.average_score}%, 
                                          rgba(255,255,255,.1) ${highlightCard?.average_score}% 100%
                                        )`,
                            
                                        }}
                                      >
                                      <div className="donut-hole-3" />
                                        <div className="donut-data" style={{ fontFamily: "Poppins",}}>
                                          {highlightCard?.average_score}
                                        </div>
                                      </div>
                                      <Typography
                                        style={{ color: "#fff", fontSize: "12px", fontFamily: "Poppins", }}
                                      >
                                        Average Score
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </>
          </Grid>
        }
      </Box>
    );
  };
  render() {
    return (
      this.coursesHighlight()
    );
  }
 }

const webStyle = {
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily:'Raleway',
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
