import React from "react";
import {
  Box,
  Button,
  Dialog,
  Typography,
} from "@material-ui/core";
import { cross, leave1, rippleBGImg } from "./assets";

const DialogBox = ({ isModalOpen, handleOnAction, handleCancel }: any) => {
  return (
    <Dialog open={isModalOpen} className="dialogContainer-logout">
      <Box style={webStyle.dialogBox}>
        <Box className="iconBox">
          <Box></Box>
          <Box sx={webStyle.frameWrapper8Style}>
            <Box sx={webStyle.skipUnionParentStyle}>
              <img
                className="skip-union-icon1"
                alt="skipUnion1"
                src={rippleBGImg}
              />
            </Box>
          </Box>
          <Box>
            <Box className="leaveBorder">
              <img src={leave1} />
            </Box>
          </Box>
          <Box color={"#475569"}>
            <img src={cross} onClick={handleCancel} />
          </Box>
        </Box>
        <Box sx={webStyle.dialogContext}>
          <Typography className="dialogHeading">Are you sure you want to logout?</Typography>
        </Box>

        <Box className="btnBox">
          <Button style={webStyle.leaveBtn} onClick={handleCancel}>Cancel</Button>
          <Button style={webStyle.cancelBtn} onClick={handleOnAction}>Logout</Button>
        </Box>
      </Box>
    </Dialog>)
}

export default DialogBox;

const webStyle = {
  leaveBtn: {
    width: '190px',
    height: '32px',
    color: '#94A3B8',
    borderRadius: '20px',
    border: '2px solid rgba(255, 255, 255, 0.05)',
    backgroundColor: '#38424F',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: "'Raleway', sans-serif",
    textTransform: 'none' as any,
    transition: 'box-shadow 0.3s ease-in-out', // Smooth transition

  },
  cancelBtn: {
    width: '190px',
    fontFamily: "'Raleway', sans-serif",
    fontWeight: 'bold',
    height: '32px',
    color: 'rgba(114, 239, 221, 1)',
    borderRadius: '20px',
    background: 'rgba(114, 239, 221, 0.2)',
    borderImageSource: 'linear-gradient(180deg, rgba(5, 13, 21, 0.4) 0%, rgba(255, 255, 255, 0) 100%)',
    cursor: 'pointer',
    textTransform: 'none' as any,
    border: '2px solid rgba(255, 255, 255, 0.05)',



  },
  dialogBox: {
    width: '482px',
    backgroundColor: '#212C3B',
    padding: '30px',
    border: '1.5px solid #334155',
    borderRadius:'20px'
  },
  frameWrapper8Style: {
    position: 'absolute',
    top: '0',
    left: '157.6px',
    width: '130.4px',
    height: '120.3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  skipUnionParentStyle: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    margin: '0 !important',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  dialogContext: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '8px'
  },
  dialogContainer: {
    width: " 100%",
    height: "100%",
    border: '1.5px solid #334155 !important', // Left and right shadow
  }
};
