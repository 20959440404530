import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { toast } from "react-toastify";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: string,
  lastName: string,
  title: string,
  avatar: string |undefined,
  email: string,
  image: any,
  [key: string]: any; 
  error:{
    popupError: string;
    firstName: boolean;
    lastName: boolean;
    title:boolean;

  }
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

interface ApiData {
  contentType?: string; 
  method: string; 
  endPoint: string; 
  body?: any; 
  type?: string;
}
export default class UserProfileSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProfileDetail: any;
  defaultError:any;
  updateProfileId:any;
  updatePasswordId:any;


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.RestAPIResponceErrorMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.CountryCodeMessage)
        ];
        this.defaultError = {
          popupError:'',
          firstName: false,
          lastName:  false,
          title:false
        }
        this.state = {
          firstName: "",
          lastName: "",
          title: "",
          avatar: "",
          email: "",
          image:undefined,
          currentPassword: "",
          confirmPassword: "",
          newPassword: "",
          passwordVisibility:{
            currentPassword: false,
            newPassword: false,
            confirmPassword:false
          },
          error:this.defaultError,
          succesMsg: ''
        };
        

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
      }
    
      async receive(from: String, message: Message) {
        // Customizable Area Start
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId === this.getProfileDetail) {
          this.handleProfileDetailResponse(responseJson);
        } 
        if(apiRequestCallId === this.updateProfileId){
          this.handleProfileUpdateResponse(responseJson);

        } if(apiRequestCallId === this.updatePasswordId){
          this.handleProfileUpdatePasswordResponse(responseJson);
        }
        
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    
        // Customizable Area End
      }
      async componentDidMount() {
          super.componentDidMount();
          // Customizable Area Start   
            this.getProfileDetail = this.apiCall({
              contentType: configJSON.validationApiContentType,
              method: configJSON.validationApiMethodType,
              endPoint: "account_block/accounts/professor",
            });
          // Customizable Area End
        }
       async componentDidUpdate(prevState:any) {
          if ((this.state.succesMsg || this.state.error.popupError) && 
              (!prevState.succesMsg || !prevState.error.popupError)) {
            setTimeout(() => {
              this.setState({ succesMsg: '', error: { ...this.state.error, popupError: '' } });
            }, 2000);
          }
        }
        
      handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({ [name]: value.trim() });
        if (value.trim() === '') {
          this.setErrorMsg(name)
        } else {
          this.setState({
            error: {
              ...this.state.error,
              [name]: false,
              popupError: ''
            }
          })
        }
       
    
      };
  isValid= (value: string) => {
    const alphabeticPattern = /^[A-Za-z]*$/;

    if (alphabeticPattern.test(value) || value === "") {
      return true
    }
    return false;

  }
  setSuccessMsg = (response:any) =>{
    this.setState({
      succesMsg:response.meta.message,
      error: {...this.defaultError}
    })
  }
  handleProfileUpdateResponse = (response:any) =>{
    if(response.meta){
     this.setSuccessMsg(response)
    }
  }
  handleProfileUpdatePasswordResponse = (response:any) =>{
    if(response.errors){
      this.setState({
        error: {...this.defaultError,popupError:response.errors[0]}
      })
    } else if(response.meta){
      this.setSuccessMsg(response)
    }
  }
      handleAvatarChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
          this.setState({ avatar: URL.createObjectURL(file), image: file });
        }
      };
      handleProfileDetailResponse = (responseJson:any) => {
        const data = responseJson.data;
        const profile = data[0]?.attributes
        this.setState({
          firstName: profile.first_name,
          lastName: profile.last_name,
          title: profile.title,
          avatar:profile.image?.url,
          email: profile.email,
        });
      }
      setErrorMsg = (name: string) =>{
       if(name=== 'firstName') {
        this.setState({
          error: {
          ...this.state.error,
            popupError: 'First Name is required',
            firstName: true,
            
          }
        })
       }
       if(name=== 'lastName') {
        this.setState({
          error: {
           ...this.state.error,
            popupError: 'Last Name is required',
            lastName: true,
          }
        })
       }
       if(name=== 'title') {
        this.setState({
          error: {
           ...this.state.error,
            popupError: "Title is required",
            title: true,
          }
        })
       }
        
      }
      updateProfileDetail = () =>{
        const { firstName, lastName, title, image, avatar } = this.state;
        if (firstName.trim() === '' || lastName.trim() === ''|| title.trim()==='') {
          this.setErrorMsg('firstName')
          this.setErrorMsg('lastName')
          this.setErrorMsg('title')
          return
        }
       
        const formData = new FormData();
        formData.append("account[first_name]", firstName);
        formData.append("account[last_name]", lastName);
        formData.append("account[title]", title);
        if(image){
          formData.append("account[image]", image);  
        }
        if(!avatar){
          formData.append("account[remove_image]", 'true')
        }
        this.updateProfileId = this.apiCall({
          method: configJSON.apiPatchType,
          endPoint: "account_block/accounts/update_professor",
          body: formData,
        });
        toast.success("Profile updated successfully");
      }
      updatePassword = () => {
        const { currentPassword, newPassword, confirmPassword } = this.state;
        if (!currentPassword ||!newPassword ||!confirmPassword) {
          this.setState({ error: {...this.state.error, popupError: "All fields are required" } });
          return;
        }
        if(!this.comparePasswords(newPassword, confirmPassword)) {
          return
        }
        const formData = new FormData();
        formData.append("account[old_password]", currentPassword);
        formData.append("account[confirm_password]", confirmPassword);
        formData.append("account[new_password]", newPassword);
      
        this.updatePasswordId=this.apiCall({
          method: configJSON.apiPatchType,
          endPoint: "account_block/accounts/update_professor",
          body: formData,
        });
      }
      apiCall = (data: ApiData) => {
        const { contentType, method, endPoint, body } = data;
        const token =  localStorage.getItem("signupToken") || "";
        const header = {
          contentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
      passwordVisibility  = (name: string) =>{
        this.setState({
          passwordVisibility: {
            ...this.state.passwordVisibility,
            [name]: !this.state.passwordVisibility[name]
          }
        });
      }

      comparePasswords = (newPassword: string, confirmPassword: string) => {
        if(newPassword === confirmPassword){
          return true;
        }
        this.setState({
          error:{
            ...this.state.error,
            popupError:'Passwords do not match'
          }
        });
        return false;
      }
      updatePassBtnDisable = () =>{
        const {currentPassword,newPassword,confirmPassword} = this.state;
       if( !currentPassword ||!newPassword ||!confirmPassword){
        return true
       }
       return false;
      }
}