Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.errorNameFieldRequired = "A name is required to proceed. Please provide your name.";
exports.errorEmailFieldRequired = "An email is required to proceed. Please provide your email.";
exports.errorMsgFieldRequired = "A message is required to proceed. Please write your message.";
exports.errorInvalidName = "Invalid name";
exports.errorInvalidEmail ="That doesn't look like a valid email address. Please double check.";
exports.queAnsLandingPageEndPoint= "/bx_block_landingpage/question_answers"
exports.getAllpageContentEndPoint="/bx_block_content_management/price_page?type="
exports.getAllpageContent="/bx_block_content_management/get_landing_page_content"
// Customizable Area End