import React from "react";

// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AuthComp from './AuthComp.web'
import './styles/form.css';
import { bgEllipsis } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SignUp extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
        <AuthComp 
        backBtn={true}
          navigation={undefined} 
          id={""} 
          factsBgImg={bgEllipsis}
          factsHeading={this.factsHeadingHome}
          factsInfoText={this.factsInfoTextHome}
          factsInfoText2={this.factsInfoText2Home}
          factsIcon={<span aria-label="brain">&#x1F9E0;</span>}
          goBack={this.goBack}
          heading3={this.headingSignUpText}
          heading3style={"signupHeading"}
          onclick={this.handleNameTrim}
          popupError={this.state.error}
          warning={this.state.warning}
          form={
              <>
              <Box className="inputWrapper">
                <input 
                  type="text" 
                  id="firstname" 
                  name="firstname" 
                  value={this.state.firstName}
                  onChange={(e) => this.handleFirstNameChange(e)} 
                  className="input"
                  data-testid="firstname"
                  required />
                  <label htmlFor="firstname" className="label" data-testid="firstnameLabel">{this.labelFirstName}</label>
              </Box>

              <Box className="inputWrapper">
                <input 
                  type="text" 
                  id="lastname" 
                  name="lastname" 
                  value={this.state.lastName}
                  onChange={(e) => this.handleLastNameChange(e)} 
                  className="input"
                  data-testid="lastname"
                  required />
                  <label htmlFor="lastname" className="label" data-testid="lastnameLabel">{this.lastName}</label>
              </Box>

              <Box className="inputWrapper">
                <input 
                  type="text" 
                  id="email" 
                  name="email" 
                  value={this.state.email}
                  onChange={(e) => this.handleEmailChange(e)} 
                  className="input"
                  data-testid="email"
                  required />
                  <label htmlFor="email" className="label" data-testid="emailLabel">{this.labelEmail}</label>
              </Box>

              <Box className="checkboxWrapper">
              <label htmlFor="checkbox" className="checkboxLabel termsText" data-testid="termsText">
                {this.terms}&nbsp;<span className="termsLink" onClick={this.goToTermsAndConditionsPage} data-testid="termsLink">{this.labelLegalTermCondition}.</span>
              <input 
                  type="checkbox" 
                  id="checkbox" 
                  name="checkbox" 
                  checked={this.state.isChecked}
                  onChange={(e) => this.handleChecked(e)} 
                  className="checkbox"
                  data-testid="checkbox"
                  required />
                <span className="checkmark"></span>
                  </label>
              </Box>
            </>
    }/>
       // Customizable Area End
    );
  }
}

