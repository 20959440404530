// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { missed, right, wrong } from "./assets";


interface Section {

  description: string;
  image?: string | null;
  sections: {
    data: Lesson[];
  };
  id: number;
  name: string;
  checked: boolean;
  sub_category_id?: number;
  assessment_attempted: boolean;
}

interface Lesson {
  attributes: {
    marked_as_completed: any;
    description: string;
    id: number;
    name: string;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    image: {
      url: string;
    } | null;
    lessons: {
      id: number;
      type: string;
      data: any;
    };
    completed?: boolean;
  };
  id: string;
  type: string;
}

export const configJSON = require("./config");

export interface Props {

  id: string;
  navigation: any;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  unitId: number | null;
  error: string | null;
  currentSection: Section;
  currentLessonIndex: number;
  openTab: boolean;
  viewresult:any,
  data: {
    unit_name: string;
    course_name: string;
    journey_name: string;
    topics: Section[];
  } | null;
  loading: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewResultController extends BlockComponent<Props, S, SS> {

  getSisebarId: any;
  getResult:any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      currentSection: {
        assessment_attempted: false,
        name: "",
        id: 0,
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                id: 0,
                name: "",
                description: "",
                marked_as_completed: "",
                image: {
                  url: "",
                },
                lessons: {
                  id: 0,
                  type: "",
                  data: [
                    {
                      id: "",
                      type: "",
                      attributes: {
                        id: 0,
                        name: "",
                        description: "",
                        image: {
                          url: "",
                        },
                        video: {
                          url: "",
                        },
                        audio: {
                          url: "",
                        },
                        table: {
                          url: "",
                        },
                      },
                    },
                  ],
                },
                video: {
                  url: "",
                },
                audio: {
                  url: "",
                },
                table: {
                  url: "",
                },
              },
            },
          ],
        },
        image: null,
        description: "",
       
        checked: false,
      },
     
      loading: false,
      error: null,
      currentLessonIndex: 0,
      unitId: null,
      data: null,
      openTab: true,
      viewresult:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0);
    const token = await getStorageData("signupToken");
    const unitId = this.props.navigation.getParam("unitId", null);
    this.setState({ token: token, unitId: unitId }, () => {
      let sectionid = Number(localStorage.getItem("topicid"))
      this.getSidebar(sectionid, token);
      this.getViewResult(token);
    });

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestId === this.getSisebarId) {
      if (this.handleViewResultToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
      if (responseJson?.course_name) {

        this.setState({
          loading: false,
          data: responseJson,
          currentSection: responseJson?.topics[0] || null,
        });
      }

    }
    else if(apiRequestId===this.getResult){
      if (this.handleViewResultToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
      if(responseJson && !responseJson.error){
        this.setState({viewresult:responseJson})
      }
    }
    // Customizable Area End
  }
  handlebackbutton=()=>{
    this.props.navigation.navigate("Score")
  }
  handleViewResultToken = (responseJson:any) => {
    if (responseJson?.errors?.[0]?.token == 'Token has Expired') {
     return true;
    }
    return false;};
  handleColorScore=()=>{
    if(this.state.viewresult?.score>=70){
      return '#21D58E';
    }
    else if(this.state.viewresult?.score>=50 && this.state.viewresult?.score<=69){
      return '#F59E0B'}
      return '#F87171';
  } 
  getImageByStatus = (status: string): string => {
    const statusMap: { [key: string]: string } = {
      Correct: right,
      Incorrect: wrong,
      Missed: missed,
    };
    return statusMap[status] || missed; 
  };
  handleopenTab = () => {
    this.setState({ openTab: true });
  };
  handleDashboard=()=>{
    const courseID = this.state.currentSection.sub_category_id
    this.props.navigation.navigate("Course", {courseID})
  }
  getSidebar = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSisebarId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.setState({ loading: true });
  };
  getViewResult = (token: string) => {
    let sectionRetry= localStorage.getItem("sectionID");
    if (!sectionRetry || sectionRetry === "undefined") {
      sectionRetry = (localStorage.getItem("retryID"));
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const apiRequestView = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getResult = apiRequestView.messageId;

    apiRequestView.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewResut}=${sectionRetry}`
    );

    apiRequestView.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequestView.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequestView.id, apiRequestView);
    this.setState({ loading: true });
  };
  handleClose = () => {
    this.setState({ openTab: false });
  };
  // Customizable Area End
}
