import React from "react";

// Customizable Area Start
import {
  checkboxMec1,
  checkboxMec2,
  checkboxMec,
  groupCurrentTime,
  groupOptions2x,
  groupPriority,
  groupSwitcher,
  image2,
  image3,
  imageChevronLeftWhite,
  imageChevronLeft,
  imageChevronRightWhite,
  imageChevronRight,
  imageX2x,
  skipArrowDown,
  skipArrowRight,
  skipBarChart4Bars,
  skipCalendarMonth,
  skipLecture,
  skipMask1_2x,
  skipMask2_2x,
  skipMask_2x,
  skipMeeting,
  skipMoreVert,
  skipNewLogo,
  skipNoteStack,
  skipSocial1,
  skipSocial2_2x,
  skipSocial3_2x,
  skipSocial,
  skipSubstract,
  skipTask,
  skipUnion1,
  skipUnion2,
  skipUnion3,
  skipUnion,
  skipVector,
  skipWordmark,
  skipWorld,
  viewVector1,
  viewVector2,
  viewVector,
  skipLocationLink,
  skipLocationOn,
  skipCircle,
  skipCalendar,
  groupHighTasksPriority,
  groupMidTasksPriority,
  skipTaskSummarize,
  groupLowTasksPriority,
  tasktitle,
  taskMemo,
  taskTime,
  meetingtitle,
  meetingMemo,
  meetingTime,
  lecttitle,
  lectMemo,
  lectLocation,
  lectMeet
} from './assets'; 
import "./global.css";
import "./Scheduling.css";
import Header from "../../catalogue1/src/Header.web";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {ArrowBackIos,ArrowForwardIos,LocationOnOutlined,LinkOutlined} from '@material-ui/icons';
import { Box, Grid, Hidden, styled } from "@material-ui/core";
import { Calendar, DateLocalizer, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// Customizable Area End

import SchedulingController, {
  Props,
} from "./SchedulingController";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // render header
  renderHeader = () => {
    return (
    <Header   navigation={this.props.navigation}
    id={"2"}/>
    )
  }

  // render footer
  renderFooter = () => {
    return (
      <footer className="group-footer">
        <div className="view-rectangle10" />
        <div className="view-vector-parent">
          <img
            className="view-vector-icon1"
            alt="viewVector1"
            src={viewVector1}
          />
          <div className="frame-wrapper12">
            <div className="frame-parent17">
              <div className="frame-parent18">
                <div className="textlabel-title-parent2">
                  <b className="textlabel-title8">OUR COMPANY</b>
                  <div className="textlabel-title-parent3">
                    <div className="textlabel-title9">About Us</div>
                    <div className="textlabel-title-parent4">
                      <div className="textlabel-title10">{`Features & Benefits`}</div>
                      <div className="textlabel-title-parent5">
                        <div className="textlabel-title11">Testimonials</div>
                        <div className="textlabel-title12">FAQs</div>
                        <div className="textlabel-title13">Contact Us</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textlabel-title-parent6">
                  <b className="textlabel-title14">OUR SERVICES</b>
                  <div className="textlabel-title-parent7">
                    <div className="textlabel-title15">For Schools</div>
                    <div className="textlabel-title-parent8">
                      <div className="textlabel-title16">For Students</div>
                      <div className="textlabel-title17">For Government</div>
                      <div className="textlabel-title18">Pricing</div>
                    </div>
                  </div>
                </div>
                <div className="textlabel-title-parent9">
                  <b className="textlabel-title19">LEGAL</b>
                  <div className="textlabel-title-parent10">
                    <div className="textlabel-title20">Security</div>
                    <div className="textlabel-title-parent11">
                      <div className="textlabel-title21">Privacy Policy</div>
                      <div className="textlabel-title22">
                        Terms and Conditions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-parent19">
                <div className="skip-newlogo-parent">
                  <img
                    className="skip-newlogo-icon"
                    alt="skipNewLogo"
                    src={skipNewLogo}
                  />
                  <div className="skip-wordmark-wrapper">
                    <img
                      className="skip-wordmark-icon"
                      alt="skipWordmark"
                      src={skipWordmark}
                    />
                  </div>
                </div>
                <div className="textlabel-title23">
                  <span className="meded">MedEd</span>
                  <span>{` `}</span>
                  <span className="cloud">CLOUD</span>
                  <span>
                    {" "}
                    is your reliable partner for navigating the complexities
                    of medical education, offering a seamless and interactive
                    learning experience.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-vector-group">
          <img
            className="view-vector-icon2"
            alt="viewVector2"
            src={viewVector2}
          />
          <div className="frame-wrapper13">
            <div className="frame-parent20">
              <div className="textlabel-title-wrapper3">
                <div className="textlabel-title24">
                  2024 MedEd Cloud. All rights reserved.
                </div>
              </div>
              <div className="frame-parent21">
                <div className="view-elipse-parent">
                  <div className="view-elipse" />
                  <img
                    className="skip-social-icon"
                    alt="facebookIcon"
                    src={skipSocial}
                  />
                </div>
                <div className="view-elipse-group">
                  <div className="view-elipse1" />
                  <img
                    className="skip-social-icon1"
                    alt="instagramIcon"
                    src={skipSocial1}
                  />
                </div>
                <div className="view-elipse-container">
                  <div className="view-elipse2" />
                  <img
                    className="image-x-icon"
                    alt="xIcon"
                    src={imageX2x}
                  />
                </div>
                <div className="view-elipse-parent1">
                  <div className="view-elipse3" />
                  <img
                    className="skip-social-icon2"
                    alt="linkedinIcon"
                    src={skipSocial2_2x}
                  />
                </div>
                <div className="view-elipse-parent2">
                  <div className="view-elipse4" />
                  <img
                    className="skip-social-icon3"
                    alt="youtubeIcon"
                    src={skipSocial3_2x}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  // render calendar
  renderCalendarCard = () => {
    return (
      <div className="view-rectangle calendar-simple">
        <div className="group-header">
          <div className="calendar-simple-header">
            <b>February, 2024</b>
            <div className="calnder-switcher">
              <img
                width="24px"
                height="24px"
                src={imageChevronLeftWhite}
              />
              <img
                width="24px"
                height="24px"
                src={imageChevronRightWhite}
              />
            </div>
          </div>
        </div>
        <div className="group-calendar-days">
          <div className="group-weekday-parent">
            <div className="group-weekday">
              <div className="textlabel-">Mo</div>
            </div>
            <div className="group-weekday1">
              <div className="textlabel-1">Tu</div>
            </div>
            <div className="group-weekday2">
              <div className="textlabel-2">We</div>
            </div>
            <div className="group-weekday3">
              <div className="textlabel-3">Th</div>
            </div>
            <div className="group-weekday4">
              <div className="textlabel-4">Fr</div>
            </div>
            <div className="group-weekday5">
              <div className="textlabel-5">Sa</div>
            </div>
            <div className="group-weekday6">
              <div className="textlabel-6">Su</div>
            </div>
          </div>
          <div className="just-day">
            <div className="view-136" />
            <div className="day">4</div>
          </div>
          <div className="group-calendar-days-inner">
            <div className="group-calendarelementsday-parent">
              <div className="group-calendarelementsday">
                <div className="bg" />
                <div className="textlabel-day">29</div>
              </div>
              <div className="group-calendarelementsday1">
                <div className="bg1" />
                <div className="textlabel-day1">30</div>
              </div>
              <div className="group-calendarelementsday2">
                <div className="textlabel-day2">31</div>
              </div>
              <div className="group-calendarelementsday3">
                <div className="textlabel-day3">1</div>
              </div>
              <div className="group-calendarelementsday4">
                <div className="textlabel-day4">2</div>
              </div>
              <div className="group-calendarelementsday5">
                <div className="textlabel-day5">3</div>
              </div>
              <div className="group-calendarelementsday6">
                <div className="textlabel-day6">4</div>
              </div>
              <div className="group-calendarelementsday7">
                <div className="textlabel-day7">5</div>
              </div>
              <div className="group-calendarelementsday8">
                <div className="textlabel-day8">6</div>
              </div>
              <div className="group-calendarelementsday9">
                <div className="textlabel-day9">7</div>
              </div>
              <div className="group-today">
                <div className="textlabel-day10">8</div>
              </div>
              <div className="group-calendarelementsday10">
                <div className="textlabel-day11">9</div>
                <img className="indicator-icon" alt="" />
                <img className="indicator-icon1" alt="" />
              </div>
              <div className="group-calendarelementsday11">
                <div className="textlabel-day12">10</div>
                <img className="indicator-icon2" alt="" />
                <img className="indicator-icon3" alt="" />
              </div>
              <div className="group-calendarelementsday12">
                <div className="textlabel-day13">11</div>
                <img className="indicator-icon4" alt="" />
                <img className="indicator-icon5" alt="" />
                <img className="indicator-icon6" alt="" />
              </div>
              <div className="group-calendarelementsday13">
                <div className="textlabel-day14">12</div>
                <img className="indicator-icon7" alt="" />
                <img className="indicator-icon8" alt="" />
              </div>
              <div className="group-calendarelementsday14">
                <div className="textlabel-day15">13</div>
                <img className="indicator-icon9" alt="" />
              </div>
              <div className="group-calendarelementsday15">
                <div className="textlabel-day16">14</div>
                <img className="indicator-icon10" alt="" />
                <img className="indicator-icon11" alt="" />
                <img className="indicator-icon12" alt="" />
              </div>
              <div className="group-calendarelementsday16">
                <div className="textlabel-day17">15</div>
                <img className="indicator-icon13" alt="" />
                <img className="indicator-icon14" alt="" />
              </div>
              <div className="group-calendarelementsday17">
                <div className="textlabel-day18">16</div>
                <img className="indicator-icon15" alt="" />
              </div>
              <div className="group-calendarelementsday18">
                <div className="textlabel-day19">17</div>
                <img className="indicator-icon16" alt="" />
                <img className="indicator-icon17" alt="" />
              </div>
              <div className="group-calendarelementsday19">
                <div className="textlabel-day20">18</div>
              </div>
              <div className="group-calendarelementsday20">
                <div className="textlabel-day21">19</div>
              </div>
              <div className="group-calendarelementsday21">
                <div className="textlabel-day22">20</div>
                <img className="indicator-icon18" alt="" />
                <img className="indicator-icon19" alt="" />
                <img className="indicator-icon20" alt="" />
              </div>
              <div className="group-calendarelementsday22">
                <div className="textlabel-textlabel-day">
                  21
                </div>
              </div>
              <div className="group-calendarelementsday23">
                <div className="textlabel-day23">22</div>
                <img className="indicator-icon21" alt="" />
                <img className="indicator-icon22" alt="" />
              </div>
              <div className="group-calendarelementsday24">
                <div className="textlabel-day24">23</div>
              </div>
              <div className="group-calendarelementsday25">
                <div className="textlabel-day25">24</div>
              </div>
              <div className="group-calendarelementsday26">
                <div className="textlabel-day26">25</div>
                <img className="indicator-icon23" alt="" />
              </div>
              <div className="group-calendarelementsday27">
                <div className="textlabel-day27">26</div>
              </div>
              <div className="group-calendarelementsday28">
                <div className="textlabel-day28">27</div>
                <img className="indicator-icon24" alt="" />
                <img className="indicator-icon25" alt="" />
              </div>
              <div className="group-calendarelementsday29">
                <div className="textlabel-day29">28</div>
              </div>
              <div className="group-calendarelementsday30">
                <div className="textlabel-day30">29</div>
                <img className="indicator-icon26" alt="" />
              </div>
              <div className="group-calendarelementsday31">
                <div className="textlabel-day31">1</div>
              </div>
              <div className="group-calendarelementsdayem">
                <div className="bg2" />
                <div className="textlabel-day32">2</div>
              </div>
              <div className="group-calendarelementsdayem1">
                <div className="bg3" />
                <div className="textlabel-day33">3</div>
              </div>
            </div>
          </div>
          <div className="view-144" />
        </div>
      </div>
    )
  }

  // render upcoming meetings card
  renderUpcomingMeetingsCard = () => {
    const upcomingMeetingsData = this.state.upcomingMeetingsData
    const next = true
    const dataPerPage = 3
    const currentPage = 1
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex - dataPerPage
  
    // slice data in chunks of 3
    const meetingsList = upcomingMeetingsData && upcomingMeetingsData.slice(firstIndex, lastIndex)
  
    return (
      <div className="view-rectangle group-upcomingmeetings">
        <div className="textlabel-title-group">
          <div className="group-header">
            <div className="upcomingmeetings-header">
              <b>Upcoming Meetings</b>
              <div className="upcomingmeetings-switcher-btns">
                <img
                  width="24px"
                  height="24px"
                  src={imageChevronLeft}
                />
                { next ?
                  <img
                    width="24px"
                    height="24px"
                    src={imageChevronRight}
                  />
                :
                  <img
                    width="24px"
                    height="24px"
                    src={imageChevronRightWhite}
                  />
                }
              </div>
            </div>
          </div>
          <div className="upcomingmeetings-content">
            <div className="upcomingmeetings-list">
              { meetingsList && meetingsList.length > 0 ?
                meetingsList.map((meetingObj: any, index: number) => {
                  const meeting = meetingObj && meetingObj["attributes"]
                  const title = meeting && meeting["title"]
                  const link = meeting && meeting["link"]
                  const location = meeting && meeting["location"]
                  const timeFrame = meeting && meeting["time_frame"].split("-")

                  return (
                    <div key={index} className="event-card meeting-color">
                      <div className="v-line" />
                      <div className="event-icons">
                        <img
                          width="16px"
                          height="16px"
                          src={skipMeeting}
                        />
                        { location ?
                          <img
                            width="16px"
                            height="16px"
                            src={skipLocationOn}
                          />
                        :
                          <img
                            width="16px"
                            height="16px"
                            src={skipLocationLink}
                          />
                        }
                      </div>
                      <div className="event-content">
                        <span className="event-title">{title}</span>
                        {link && (
                            <a className="event-location googlemeet" href={link} target="_blank" rel="noopener noreferrer">Google Meet</a>
                        )}
                         {location && (
                            <span className="event-location">{location}</span>
                        )}
                      </div>
                      <div className="event-timeframe">
                        <span>Today</span>
                        <span>{timeFrame[0]}</span>
                        <span className="gap">-</span>
                        <span>{timeFrame[1]}</span>
                      </div>
                    </div>
                  )
                })
                :
                <div className="no-events">
                  <div className="textlabel-text-group">
                    <div className="textlabel-text8">
                      <span className="textlabel-text-txt">
                        <p className="all-clear">
                          <b>All Clear!</b>
                        </p>
                        <p className="schedules-open-time">
                          Schedule's open. Time for planning!
                        </p>
                      </span>
                    </div>
                    <div className="frame-wrapper4">
                      <div className="wrapper-skip-union-parent">
                        <div className="wrapper-skip-union">
                          <img
                            className="skip-union-icon"
                            alt="skipUnion"
                            src={skipUnion}
                          />
                        </div>
                        <img
                          className="skip-note-stack-icon"
                          alt="skipNoteStack"
                          src={skipNoteStack}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="skip-bmec1">
                    <div className="textlabel-7">New Meeting</div>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  // generate activity counts
  generateWeeklyActivityCounts = (weeklyActivityCounts: any) => {
    const activityCounts = weeklyActivityCounts["activities_count"]
    const currentDate = new Date()
    const currentDayNumber = currentDate.getDay()

    const counts = activityCounts && activityCounts.filter((day:any) =>{
      return day["day_number"] === currentDayNumber
    })
    return counts
  }

  // render activity Card
  renderActivityCard = () => {
    const activityCounts = this.generateWeeklyActivityCounts(this.state.weeklyActivityCounts)

    const dayNumber = activityCounts && activityCounts[0] ? activityCounts[0]["day_number"] : 0
    const meetingsCount = activityCounts && activityCounts[0] ? activityCounts[0]["meetings"] : 0
    const lecturesCount = activityCounts && activityCounts[0] ? activityCounts[0]["lectures"] : 0
    const tasksCount = activityCounts && activityCounts[0] ? activityCounts[0]["tasks"] : 0

    return(
      <div className="view-rectangle group-activity">
        <div className="textlabel-title-container">
          <b className="textlabel-title3">Activity</b>
          <div className="frame-wrapper5">
            <div className="view-stroke-parent">
              <div className="view-stroke2" />
              <div className="textlabel-text9">This week</div>
            </div>
          </div>
        </div>
        <div className="activity-group-switcher">
          <img className="activity-chevron-left"
            width="24px"
            height="24px"
            src={imageChevronLeft}
          />
          <div className="textlabel-text-wrapper1">
            <strong className="textlabel-text10">{dayNumber}</strong>
          </div>
          <div className="textlabel-text10-active-day">
            <b>Day</b>
            <b>Active</b>
          </div>
          <img className="activity-chevron-right"
            width="24px"
            height="24px"
            src={imageChevronRightWhite}
          />
        </div>
        <div className="textlabel-text11">
          You've logged in for one day, keep it up!
        </div>
        <div className="view-rectangle-parent">
          <div className="view-rectangle2" />
          <div className="frame-parent4">
            <div className="skip-meeting-parent">
              <img
                className="skip-meeting"
                alt="skipMeeting"
                src={skipMeeting}
              />
              <b className="textlabel-text12">{meetingsCount}</b>
            </div>
            <div className="textlabel-text13">Meetings</div>
          </div>
          <div className="frame-parent5">
            <div className="skip-lecture-parent">
              <img
                className="skip-lecture-icon"
                alt="skipLecture"
                src={skipLecture}
              />
              <b className="textlabel-text14">{lecturesCount}</b>
            </div>
            <div className="textlabel-text15">Lectures</div>
          </div>
          <div className="frame-parent6">
            <div className="skip-task-parent">
              <img
                className="skip-task-icon"
                alt="skipTask"
                src={skipTask}
              />
              <b className="textlabel-text16">{tasksCount}</b>
            </div>
            <div className="textlabel-text17">Tasks</div>
          </div>
        </div>
      </div>
    )
  }

  renderEventIcon = (type: string, location: string) => {
    if (type == "task") {
      return (
        <img
          width="16px"
          height="16px"
          src={skipCalendar}
        />
      )
    }
    else if (type == "lecture") {
     if (location) {
      return (
        <img
          width="16px"
          height="16px"
          src={lectLocation}
        />
      )
     } else{
      return (
        <img
          width="16px"
          
          src={lectMeet}
          height="16px"
        />
      )
     }
     
      
    }
    else if (type == "meeting") {
      if (location) {
       return (
         <img
           width="16px"
           height="16px"
           src={skipLocationOn}
         />
       )
      } else{
       return (
         <img
           width="16px"
           height="16px"
           src={skipLocationLink}
         />
       )
      }
      
       
     }
    else {
      return (
        <img
          height="16px"
          src={skipCalendar}
          width="16px"
         
        />
      )
    }
  }
  renderTomorrowIcon = (type: string) => {
    if (type == "task") {
      return (
        <img
          width="16px"
          height="16px"
          src={skipTask}
        />
      )
    }
    else if (type == "lecture") {
      return (
        <img
          width="16px"
          height="16px"
          src={skipLecture}
        />
      )
    }
    else if (type == "meeting") {
      return (
        <img
        src={skipMeeting}
          width="16px"
          height="16px"
          
        />
      )
    }
    else {
      return (
        <img
          width="16px"
          src={skipTask}
          height="16px"
        />
      )
    }
  }

  // render tomorrow card
  renderTomorrowCard = () => {
    const tomorrowData = this.state.tomorrowEventsData
    const currentPage = 1
    const dataPerPage = 4
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex-dataPerPage
    const eventsList = tomorrowData?.length>0 && tomorrowData?.slice(firstIndex, lastIndex)
    return (
      <div className="view-rectangle3 group-tomorrow">
        <div className="tomorrow-group-parent">
          <div className="frame-parent8">
            <div className="textlabel-title-frame">
              <b className="textlabel-title4">Tomorrow</b>
            </div>
            <div className="frame-wrapper6">
              <div className="view-stroke-group">
                <div className="view-stroke3" />
                <div className="textlabel-text18">0</div>
              </div>
            </div>
            <img
              className="group-switcher-icon"
              alt="groupSwitcher"
              src={groupSwitcher}
            />
          </div>
          <div className="upcomingmeetings-content">
            <div className="upcomingmeetings-list">
              { tomorrowData?.length > 0 ?
                  eventsList?.map((tomorrowObj: any, index: number) => {
                    const tomorrowEvent = tomorrowObj["data"]["attributes"]
                    const type = tomorrowEvent && tomorrowEvent["task_category_title"]
                    //const iconType = type && (type[0].toUpperCase() + type.slice(1))
                    const deadline = tomorrowEvent["deadline"] && tomorrowEvent["deadline"].split(' ')
                    const taskStartTime = deadline && deadline[3] + " " + deadline[4]
                    const eventTimeFrame = tomorrowEvent["time_frame"] && tomorrowEvent["time_frame"].split('-')
                    const location = tomorrowEvent && tomorrowEvent["location"] || ''
                    const link = tomorrowEvent && tomorrowEvent["link"] 
                    return (
                      <div key={index} className={`event-card ${type}-color`}>
                        <div className="v-line" />
                        <div className="event-icons">
                          { this.renderTomorrowIcon(type) }
                          { this.renderEventIcon(type, location) }
                        </div>
                        <div className="event-content">
                          <span className="event-title"> {tomorrowEvent["title"]} </span>
                          { type == "task" ?
                              <div className="event-task-time">
                                <span className="event-location" style = {{marginRight: "5px"}}> {taskStartTime} </span>
                                <span className={this.getClassName(tomorrowEvent["priority"])}></span>
                              </div>
                            :
                            <div>
                              {link && (
                                  <a className="event-location googlemeet" href={link} target="_blank" rel="noopener noreferrer">Google Meet</a>
                              )}
                              {location && (
                                  <span className="event-location">{location}</span>
                              )}
                            </div>
                             
                          }
                        </div>
                        { type == "task" ?
                            <div className=" event-timeframe circle">
                              <img
                                width="16px"
                                height="16px"
                                src={skipCircle}
                              />
                            </div>
                          :
                            <div className="event-timeframe">
                              <span>{eventTimeFrame[0]}</span>
                              <span>-</span>
                              <span>{eventTimeFrame[1]}</span>
                            </div>
                        }
                      </div>
                    )
                  })
                :
                  <div className="frame-parent9">
                    <div className="frame-wrapper7">
                      <div className="textlabel-text-parent1">
                        <div className="textlabel-text19">
                          <span className="textlabel-text-txt1">
                            <p className="nothing-on-for-tomorrow-yet">
                              <b>Nothing on for tomorrow yet!</b>
                            </p>
                            <p className="schedule-lectures-meetings">
                              Schedule lectures, meetings, and other
                              learning opportunities.
                            </p>
                          </span>
                        </div>
                        <div className="frame-wrapper8">
                          <div className="skip-union-parent">
                            <img
                              className="skip-union-icon1"
                              alt="skipUnion1"
                              src={skipUnion1}
                            />
                            <img
                              className="skip-calendar-month-icon"
                              alt="skipCalendarMonth"
                              src={skipCalendarMonth}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="skip-bmec3">
                      <div className="image-wrapper">
                        <img
                          className="image-icon"
                          alt="image2"
                          src={image2}
                        />
                      </div>
                      <div className="textlabel-9">New Activity</div>
                      <div className="image-container">
                        <img
                          className="image-icon1"
                          alt="image3"
                          src={image3}
                        />
                      </div>
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  // render categories card
  renderCategoriesCard = () => {
    return (
      <div className="group-categories">
        <div className="view-rectangle4" />
        <div className="frame-parent10">
          <div className="textlabel-title-wrapper1">
            <b className="textlabel-title5">Categories</b>
          </div>
          <div className="group-add">
            <div className="view-stroke4" />
            <div className="textlabel-text20">Add</div>
          </div>
        </div>
        <div className="group-category-parent">
          <div className="group-category">
            <div className="view-rectangle-group">
              <div className="view-rectangle5" />
              <img
                className="skip-mask-icon"
                alt="skipMask2x"
                src={skipMask_2x}
              />
            </div>
            <div className="checkbox-mec-wrapper">
              <img
                className="checkbox-mec-icon"
                alt="checkboxMec"
                src={checkboxMec}
              />
            </div>
            <div className="textlabel-text-wrapper2">
              <div className="textlabel-text21">Meetings</div>
            </div>
            <div className="skip-more-vert-wrapper">
              <img
                className="skip-more-vert-icon"
                alt="skipMoreVert"
                src={skipMoreVert}
              />
            </div>
          </div>
          <div className="group-category1">
            <div className="view-rectangle-container">
              <div className="view-rectangle6" />
              <img
                className="skip-mask-icon1"
                alt="skipMask1_2x"
                src={skipMask1_2x}
              />
            </div>
            <div className="checkbox-mec-container">
              <img
                className="checkbox-mec-icon1"
                alt="checkboxMec1"
                src={checkboxMec1}
              />
            </div>
            <div className="textlabel-text-wrapper3">
              <div className="textlabel-text22">Lectures</div>
            </div>
            <div className="skip-more-vert-container">
              <img
                className="skip-more-vert-icon1"
                alt="skipMoreVert"
                src={skipMoreVert}
              />
            </div>
          </div>
        </div>
        <div className="group-category2">
          <div className="view-rectangle-parent1">
            <div className="view-rectangle7" />
            <img
              className="skip-mask-icon2"
              alt="skipMask2_2x"
              src={skipMask2_2x}
            />
          </div>
          <div className="checkbox-mec-frame">
            <img
              className="checkbox-mec-icon1"
              alt="checkboxMec2"
              src={checkboxMec2}
            />
          </div>
          <div className="textlabel-text-wrapper4">
            <div className="textlabel-text23">Tasks</div>
          </div>
          <div className="skip-more-vert-frame">
            <img
              className="skip-more-vert-icon2"
              alt="skipMoreVert"
              src={skipMoreVert}
            />
          </div>
        </div>
      </div>
    )
  }

  compareTimeWithDeadline = (deadline: string | number | Date, hour: number) => {
    const taskTime = new Date(deadline).getHours(); 
    return taskTime === hour; 
  };
  
  compareTimeWithTimeFrame = (timeFrame: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: (time: any) => any): [any, any]; new(): any; }; }; }, hour: number) => {
    const [startTime, endTime] = timeFrame.split('-').map(time => {
      const [timeStr, period] = time.trim().split(' ');
      let [h, m] = timeStr.split(':').map(Number);
      if (period === 'PM' && h !== 12) h += 12;
      if (period === 'AM' && h === 12) h = 0;
      return h;
    });
    return hour >= startTime && hour < endTime;
  };
  

  renderTaskOrEvent = (hour: number) => {
    const { dayTasks } = this.state;
    const tasks = Array.isArray(dayTasks) ? dayTasks : [];
  
    const groupedTasks: { [key: number]: Array<any> } = {};
  
    tasks.forEach(taskObj => {
      const { type, attributes } = taskObj.data;
      const { deadline, time_frame } = attributes;
  
      let timePeriod: number | undefined;
  
      if (type === 'task' && this.compareTimeWithDeadline(deadline, hour)) {
        timePeriod = new Date(deadline).getHours();
      } else if (type === 'repeatingtask' && this.compareTimeWithTimeFrame(time_frame, hour)) {
        timePeriod = this.getTimePeriodFromTimeFrame(time_frame);
      }
  
      if (timePeriod !== undefined) {
        if (!groupedTasks[timePeriod]) {
          groupedTasks[timePeriod] = [];
        }
        groupedTasks[timePeriod].push(taskObj);
      }
    });
  
    return Object.keys(groupedTasks).map((timePeriodStr) => {
      const timePeriod = parseInt(timePeriodStr); 
      const tasksForTimePeriod = groupedTasks[timePeriod];
      
      return tasksForTimePeriod.map((taskObj, index) => {
        const { type, attributes } = taskObj.data;
        const { title, participents, deadline, time_frame, task_category_title, link, location, priority } = attributes;
  
        const taskBoxStyle = {
          ...webStyle2.taskbox,
          width: tasksForTimePeriod.length > 1 ? '48%' : '100%',
          position: 'absolute' as const,
          left: tasksForTimePeriod.length === 1 ? '9%' :
                index === 0 && tasksForTimePeriod.length > 1 ? '9%' :
                index === 1 ? '55%' :
                index === 2 ? '100%' : 
                index === 3 ? '150%' : 
                index === 4 ? '200%' : "100%",
          padding: "1%",
        };
  
        if (type === 'task' && this.compareTimeWithDeadline(deadline, hour)) {
          return (
            <div style={taskBoxStyle as React.CSSProperties} key={taskObj.id}>
              {this.taskInDayBox(title, participents, hour, deadline, priority)}
            </div>
          );
        }
  
        if (type === 'repeatingtask') {
          if (task_category_title === 'meeting' && this.compareTimeWithTimeFrame(time_frame, hour)) {
            return (
              <div style={taskBoxStyle as React.CSSProperties} key={taskObj.id}>
                {this.meetingDayBox(title, participents, link, location, priority)}
              </div>
            );
          }
          if (task_category_title === 'lecture' && this.compareTimeWithTimeFrame(time_frame, hour)) {
            return (
              <div style={taskBoxStyle as React.CSSProperties} key={taskObj.id}>
                {this.lectureInDayBox(title, participents, link, location, priority)}
              </div>
            );
          }
        }
  
        return null;
      });
    });
  };
  
  getTimePeriodFromTimeFrame = (time_frame: string) => {
    const [startTime, endTime] = time_frame.split('-');
    const startHour = new Date(`1970-01-01T${startTime}:00`).getHours();
    return startHour; 
  };
    
  
  getPriorityColor = (priority: any) => {
    switch (priority) {
      case 'low':
        return 'red';
      case 'medium':
        return 'orange';
      case 'high':
        return '#18dfb0e5';
    }

  }

  taskInDayBox = (title: string, participents: any, hours:any,deadline:any,priority:string) => {
    const dotColor = this.getPriorityColor(priority);
    return (
      <>
      <div>
        <div style={{height:'2px', background:'#4E0EAD', width: '90%', position: 'absolute', top:"8%", left:"11% !important",}}/>
        <Grid container style={{ ...webStyle.taskbox,background:'#36117b',left:"11% !important",}as React.CSSProperties}>
          <Grid item xs={4} sm={5} >
            <div style={{...webStyle.imgText,alignItems:"center",}}>
              <img src={tasktitle} style={{width: "31px",
  height: "5%"}}/>
              <div style={{ color:'#fff', fontWeight:500 ,fontSize:".7rem" ,overflow:"Hidden",}}>{title}</div>
            </div>
          </Grid>
          <Grid item xs={4} sm={3}>
          {participents && (
            <div style={webStyle.imgText}>
              <img src={taskMemo}/>
              <div style={{ color:'#fff', marginTop:'4px' , overflow:"hidden" }}>{participents}</div>
            </div>
          )}
          </Grid>
          <Grid item xs={4} sm={4} style={{position:'relative'}}>
          <div style={{ width: '10px', height: '10px', backgroundColor: dotColor,boxShadow: priority?'0 0 5px rgba(0, 0, 0, 0.5)':'none',position: 'absolute',right:"103%",top: "29%", borderRadius: '50%', marginRight: '8px', display: 'inline-block' }} />
            <div style={{...webStyle.imgText,marginTop:"4%"}}>
              <img src={taskTime} />
              <div style={{ color:'#fff', marginTop:'2px',fontSize:".7rem" }}>{this.formatDayDate(this.state.currentDate)} {hours} : 00</div>
            </div>
          </Grid>
        </Grid>
        </div>
      </>
    )
  }
  
  meetingDayBox = (title:string, participents:any,meetingLink:string,location:string,priority:string) => {
    const dotColor = this.getPriorityColor(priority);
    return (
      <>
        <div style={{height:'2px', background:'#3AA180',width: '90%', position: 'absolute', top:"8%", left:"1%" }}/>
        <Grid container style={{background:'#216556',  ...webStyle.taskbox,left:"1%"} as React.CSSProperties}>
          <Grid item xs={4} sm={4}>
            <div style={webStyle.imgText}>
              <img src={meetingtitle} style={{width:22}}/>
              <div style={{ color:'#fff', fontWeight:500 }}>{title}</div>
            </div>
          </Grid>
          <Grid item xs={4} sm={5}>
            <div style={webStyle.imgText}>
              <img src={meetingMemo} style={{width:22}}/>
              <div style={{ color:'#fff', marginTop:'4px' }}>{participents}</div>
            </div>
          </Grid>
          <Grid item xs={4} sm={3} style={{position:'relative'}}>
            <div style={webStyle.imgText}>
            <div style={{ width: '10px', height: '10px', backgroundColor: dotColor,boxShadow:priority? '0 0 5px rgba(0, 0, 0, 0.5)':'none', position: 'absolute',right:"103%",top: "29%",borderRadius: '50%', marginRight: '8px', display: 'inline-block' }} />
              {meetingLink &&(
                <div style={webStyle.imgText}>
              <LinkOutlined style={{color:'#3AA180'}}/>
              <div style={{marginTop:"2px"}}>
              <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{...webStyle.meetingLink,fontSize:".7rem"}}>
                Google Meet
              </a>
              </div>
              </div>
            )}
            {location && (
              <div style={{display:'flex'}}>
               <LocationOnOutlined style={{color:'#3AA180'}}/>
               <p style={{ color:'#fff', marginTop:'4px' }}>{location}</p>
              </div>
            )}
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
  
  lectureInDayBox = (title:string, participents:any,meetingLink:string,location:string,priority: string) => {
    const dotColor = this.getPriorityColor(priority);
    return (
      <>
        <div style={{height:'2px', background:'#3DBEE4', width: '90%', position: 'absolute', top:"8%", left:"10%"}}/>
        <Grid container style={{ background:'#195F79', ...webStyle.taskbox, left:"10%"} as React.CSSProperties}>
          <Grid item xs={4} sm={4}>
            <div style={webStyle.imgText}>
              <img src={lecttitle} style={{width:22}}/>
              <div style={{ color:'#fff', fontWeight:500 }}>{title}</div>
            </div>
          </Grid>
          <Grid item xs={4} sm={5}>
            <div style={webStyle.imgText}>
              <img src={lectMemo} style={{width:22}}/> 
              <div style={{ color:'#fff', marginTop:'4px' }}>{participents}</div>
            </div>
          </Grid>
          <Grid item xs={4} sm={3} style={{position:'relative'}}>
            <div style={webStyle.imgText}>
            <div style={{ width: '10px', height: '10px', backgroundColor: dotColor, boxShadow:priority? '0 0 5px rgba(0, 0, 0, 0.5)':'none',borderRadius: '50%', position: 'absolute',right:"103%",top: "29%",marginRight: '8px', display: 'inline-block' }} />
              {location && (
              <div style={{display:'flex',gap:7}}>
              <LocationOnOutlined style={{color:'#3DBEE4'}}/>
              <div style={{ color:'#fff', marginTop:'4px' }}>{location}</div>
              </div>
              )}
              {meetingLink &&(
                <>
                 <div style={{display:'flex',gap:7}}>
              <LinkOutlined style={{color:'#3DBEE4'}}/>
              <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{...webStyle.meetingLink,fontSize:".7rem",}}>
                Google Meet
              </a>
              </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
   generateWeekDates = (startDate: any) => {
    const dates = [];
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    
    for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const day = dayNames[date.getDay()];
        dates.push(`${day} ${date.getDate()}`);
    }
    return dates;
};
 hasDataForDate = (date:any) => {
  return this.state.weeklyData.some((task: { data: { attributes: any; }; }) => {
      const { attributes } = task.data;

      if (attributes.deadline) {
          const taskDate = new Date(attributes.deadline);
          if (taskDate.getDate() === date.getDate() && taskDate.getMonth() === date.getMonth() && taskDate.getFullYear() === date.getFullYear()) {
              return true;
          }
      }
      if (attributes.start_time) {
          const startDateTime = new Date(attributes.start_time);
          if (startDateTime.getDate() === date.getDate() && startDateTime.getMonth() === date.getMonth() && startDateTime.getFullYear() === date.getFullYear()) {
              return true;
          }
      }

      return false;
  });
};

 getBackgroundColor = (attributes:any) => {
  if (attributes?.data?.type == 'task') {
      return '#6A00F4';
  } if (attributes?.data?.type == 'repeatingtask') {
      if (attributes?.data?.attributes.task_category_title === 'meeting') {
          return '#216556'; 
      }  if (attributes?.data?.attributes.task_category_title== 'lecture') {
          return '#195F79'; 
      }
  }
  return 'transparent';
};
getBorderColor = (attributes:any) => {
  if (attributes?.data?.type == 'task') {
      return '#4E0EAD';
  }  if (attributes?.data?.type == 'repeatingtask') {
      if (attributes?.data?.attributes.task_category_title === 'meeting') {
          return '#3AA180'; 
      }  if (attributes?.data?.attributes.task_category_title== 'lecture') {
          return '#3DBEE4'; 
      }
  }
  return 'transparent';
};
convertTo24Hour = (time12:any) => {
  const [time, modifier] = time12.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
      hours = (parseInt(hours, 10) + 12).toString(); 
  }
  if (modifier === 'AM' && hours === '12') {
      hours = '00'; 
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};
WeeklySchedule = ( startDate:any ) => {
  const weekDates = this.generateWeekDates(startDate);

  const renderTasks = (date: Date, timeSlot: string | number) => {
    const currentDate = new Date();
    const currentDateStr = currentDate.toDateString();
    const currentHour = currentDate.getHours();
    const timeSlotHour = typeof timeSlot === 'string' ? parseInt(timeSlot.split(':')[0]) : timeSlot;
  
    if (date.toDateString() === currentDateStr && timeSlotHour === currentHour) {
      return (
        <img
          src={groupCurrentTime} 
          alt="Current Event"
          style={{ width: '100%', height: '100%', borderRadius: '8px' }}
        />
      );
    }
  
    const tasksForTimeSlot = this.state.weeklyData.filter((task: { data: { attributes: any; }; }) => {
      const { attributes } = task.data;
      const taskDate = new Date(attributes.deadline || attributes.start_time);
      const taskTime = taskDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const taskTime24 = this.convertTo24Hour(taskTime);
  
      return (
        taskDate.getDate() === date.getDate() &&
        ((attributes.deadline && taskTime24 === timeSlot) ||
        (attributes.start_time &&
          timeSlot >= taskTime24 &&
          timeSlot < new Date(attributes.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })))
      );
    });
    const isScrollable = tasksForTimeSlot.length > 2;
  
    return (
      <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: isScrollable ? 'nowrap' : 'wrap',
        gap: '4px',
        overflowX: isScrollable ? 'auto' : 'hidden',
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none',
      }}
    >
      <style>
        {`
          div::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
        {tasksForTimeSlot.map((task: { data: { attributes: any; }; }, index: number) => {
          const { attributes } = task.data;
          const dotColor = this.getPriorityColor(attributes.priority);
          const taskWidth = tasksForTimeSlot.length == 1 ? '100%' : '45%'
          return (
            <div 
              key={attributes.id} 
              className="meeting-cell" 
              style={{ 
                backgroundColor: this.getBackgroundColor(task),
                borderTop: this.getBorderColor(task),
                ...webStyle.weekTaskBox,
                flex: '1 1 30%', 
                maxWidth:taskWidth,
                boxSizing: 'border-box',
                position:'relative'
              }}
            >
              <div 
                style={{ 
                  width: '8px', 
                  height: '8px', 
                  top: 7, 
                  right: -3, 
                  backgroundColor: dotColor,
                  boxShadow: attributes.priority ? '0 0 5px rgba(0, 0, 0, 0.5)' : 'none',
                  position: 'absolute',
                  borderRadius: '50%', 
                  marginRight: '8px', 
                  display: 'inline-block' 
                }} 
              />
              <div style={{ height: '1px', backgroundColor: this.getBorderColor(task), marginBottom: '2px' }} />
              <span className="calenderweekdata" style={webStyle.weekTaskTitle as React.CSSProperties}>{attributes.title}</span>
            </div>
          );
        })}
      </div>
    );
  };
  

  return (
      <div className="grid-container">
          <div className="grid-header">
              <div className="grid-header-cell"></div>
              {weekDates.map((date, index) => {
                const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
                const hasData = this.hasDataForDate(currentDate);

                return (
                    <div
                        key={index}
                        className="grid-header-cell"
                        style={{ color: hasData ? '#94A3B8' : '#64748B' }}
                    >
                        {date}
                    </div>
                );
            })}
          </div>
          {this.state.timeSlot.map((time, index) => (
              <div key={index} className={`grid-row ${index === 0 ? 'first-row' : ''}`}>
                  <div className="grid-time-cell">{time}</div>
                  {weekDates.map((date, dateIndex) => (
                      <div key={dateIndex} className={`grid-cell ${dateIndex === weekDates.length - 1 ? 'last-cell' : ''}`}>
                          {renderTasks(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + dateIndex), time)}
                      </div>
                  ))}
                  <div className="horizontal-line"></div>
              </div>
          ))}
            <div className="horizontal-line2"></div>
      </div>
  );
};
isSameDay = (date1:any, date2:any) => {
  return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
};
montlySchedule=()=>{
  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });
  
  const localizer = momentLocalizer(moment) as DateLocalizer;

  const mappedEvents =  this.state.monthlyData.map((item: { data: { attributes?: any; type?: any; }; }) => {
  const {type}=item.data
    const { id, title, deadline, task_category_title,start_time,priority } = item.data.attributes;

    const dateToUse = deadline || start_time;
    
    const dateFormat = deadline ? 'DD MMM YYYY h:mm A' : 'MM/DD/YYYY h:mm A';
    
    const startDate = moment(dateToUse, dateFormat).toDate();
    const endDate = moment(dateToUse, dateFormat).add(1, 'hours').toDate();
  
    return {
      id,
      title,
      start: startDate,
      end: endDate,
      type,
      category: task_category_title,
      priority
    };
  });

  const eventStyleGetter = (event: any) => {
    let backgroundColor = '';
    let borderLeftColor = '';

    if (event.type === 'task') {
      backgroundColor = '#36117b'; 
      borderLeftColor = '#4E0EAD';
    } else if (event.type === 'repeatingtask') {
      if (event.category === 'meeting') {
        backgroundColor = '#216556'; 
        borderLeftColor = '#3AA180';
      } else if (event.category === 'lecture') {
        backgroundColor = '#195F79'; 
        borderLeftColor = '#3DBEE4';
      }
    }
    return {
      style: {
        backgroundColor,
        color: 'white',
        paddingLeft: '8px',
        borderLeft: `2px solid ${borderLeftColor}`,
      },
    };
  };

  const EventWithDot = ({ event }: any) => {
    let dotColor = '';

    switch (event.priority) {
      case 'low':
        dotColor = 'red';
        break;
      case 'medium':
        dotColor = 'orange';
        break;
      case 'high':
        dotColor = 'green';
        break;
      default:
        dotColor = 'unset';
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <span>{event.title}</span>
        <span
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: dotColor,
            position:'absolute',
            right:'3px'
          }}
        ></span>
      </div>
    );
  };
  
  return(
    <>
     <MonthBox> 
     <Calendar
        localizer={localizer}
        events={mappedEvents}
        data-test-id="monthcalendar" 
        startAccessor="start"
        date={this.state.currentDate}
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        components={{
          event: EventWithDot,
        }}
        views={['month']}
        messages={{
          showMore: (total: any) => `+${total} more`,
        }}
      />
  </MonthBox>
    </>
  )
}
  // render calendar day view card
  renderCalendarDayViewCard = () => {
    const startOfWeek = new Date(this.state.currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1);
    const currentHour = new Date().getHours();
    const isCurrentHour = this.isSameDay(this.state.currentDate, this.state.checkCurrentDate);
    return (
      <div className="calendar-day" style={{overflow:"scroll"}}>
        <div className="frame-parent11">
          <div className="frame-wrapper9">
            <div className="textlabel-title-parent1">
              <b className="textlabel-title6">
              {this.currentTimeHeading()}
              </b>
              <div className="skip-world-parent">
                <img
                  className="skip-world-icon"
                  alt="skipWorld"
                  src={skipWorld}
                />
                <div className="textlabel-text-wrapper5">
                  <div className="textlabel-text24">
                    {this.state.selectedTaskTime!='Month' && <>
                    Time zone: EST
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-parent12">
            <div className="group-switcher-wrapper">
              <ArrowBackIos onClick={this.handlePreviousDay} data-test-id="backarrow" className="group-switcher-icon1" style={{width:'15px'}}/>
              <ArrowForwardIos onClick={this.handleNextDay} data-test-id="forwardarrow" className="group-switcher-icon1" style={{width:'15px'}}/>
            </div>
            <div className="tabbar-segmented-group-mec">
              <div className={this.state.selectedTaskTime=='Day'?"skip-day":'button-month'}>
                <div className="textlabel-label-" data-test-id="DayClick" onClick={()=>this.setState({selectedTaskTime:'Day'})}>Day</div>
              </div>
              <div className={this.state.selectedTaskTime=='Week'?"skip-day":'button-week'}>
                <div className="textlabel-label-1" data-test-id="WeekClick" onClick={()=>{this.setState({selectedTaskTime:'Week'});
                this.updateWeekDates(this.state.currentDate); this.getWeeklyTask()}}>Week</div>
              </div>
              <div className={this.state.selectedTaskTime=='Month'?"skip-day":'button-month'}>
                <div className="textlabel-label-2" data-test-id="MonthClick" onClick={()=>{this.setState({selectedTaskTime:'Month'});
                this.getMonthlyTask()}}>Month</div>
              </div>
              <div className={this.state.selectedTaskTime=='Year'?"skip-day":'button-year'}>
                <div className="textlabel-label-3" data-test-id="yearclick" onClick={()=>this.setState({selectedTaskTime:'Year'})}>Year</div>
              </div>
            </div>
          </div>
        </div>
        {this.state.selectedTaskTime =='Day' &&
        <div className="group-">

          <div className="group-1">
            <div className="textlabel-10">07:00</div>
            <div className="line-wrapper">
              <div className="line-" />
            </div>
            {currentHour == 7 && isCurrentHour&&(
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(7)}
          </div>
          <div className="group-calendar-hour">
            <div className="textlabel-11">08:00</div>
            <div className="line-container">
              <div className="line-1" />
            </div>
            {currentHour == 8 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(8)}
          </div>
          <div className="group-calendar-hour1">
            <div className="textlabel-12">09:00</div>
            <div className="line-frame">
              <div className="line-2" />
            </div>
            {currentHour == 9 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(9)}
          </div>
          <div className="group-calendar-hour2">
            <div className="textlabel-13">10:00</div>
            <div className="line-wrapper1">
              <div className="line-3" />
            </div>
            {currentHour == 10 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(10)}
          </div>
          <div className="group-calendar-hour3">
            <div className="textlabel-14">11:00</div>
            <div className="line-wrapper2">
              <div className="line-4" />
            </div>
            {currentHour == 11 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(11)}
          </div>
          <div className="group-calendar-hour4">
            <div className="textlabel-15">12:00</div>
            <div className="line-wrapper3">
              <div className="line-5" />
            </div>
            {currentHour == 12 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(12)}
          </div>
          <div className="group-calendar-hour5">
            <div className="textlabel-16">13:00</div>
            <div className="line-wrapper4">
              <div className="line-6" />
            </div>
            {currentHour == 13 &&isCurrentHour&&(
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(13)}
          </div>
          <div className="group-calendar-hour6">
            <div className="textlabel-17">14:00</div>
            <div className="line-wrapper5">
              <div className="line-7" />
            </div>
            {currentHour == 14 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(14)}
          </div>
          <div className="group-calendar-hour7">
            <div className="textlabel-18">15:00</div>
            <div className="line-wrapper6">
              <div className="line-8" />
            </div>
            {currentHour == 15 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(15)}
          </div>
          <div className="group-calendar-hour8">
            <div className="textlabel-19">16:00</div>
            <div className="line-wrapper7">
              <div className="line-9" />
            </div>
            {currentHour == 16 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(16)}
          </div>
          <div className="group-calendar-hour9">
            <div className="textlabel-20">17:00</div>
            <div className="line-wrapper8">
              <div className="line-10" />
            </div>
            {currentHour == 17 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(17)}
          </div>
          <div className="group-calendar-hour10">
            <div className="textlabel-21">18:00</div>
            <div className="line-wrapper9">
              <div className="line-11" />
            </div>
            {currentHour == 18 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(18)}
          </div>
          <div className="group-calendar-hour11">
            <div className="textlabel-22">19:00</div>
            <div className="line-wrapper10">
              <div className="line-12" />
            </div>
            {currentHour == 19 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(19)}
          </div>
          <div className="group-calendar-hour12">
            <div className="textlabel-23">20:00</div>
            <div className="line-wrapper11">
              <div className="line-13" />
            </div>
            {currentHour == 20 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(20)}
          </div>
          <div className="group-calendar-hour12">
            <div className="textlabel-23">21:00</div>
            <div className="line-wrapper11">
              <div className="line-13" />
            </div>
            {currentHour == 21 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(21)}
          </div>
          <div className="group-calendar-hour12">
            <div className="textlabel-23">22:00</div>
            <div className="line-wrapper11">
              <div className="line-13" />
            </div>
            {currentHour == 22 &&isCurrentHour&& (
              <img className="group-current-time-icon" alt="groupCurrentTime" src={groupCurrentTime} />
            )}
            {this.renderTaskOrEvent(22)}
          </div>
        </div>
        }
        {this.state.selectedTaskTime=='Week' && <>{this.WeeklySchedule(startOfWeek)}</>}
        {this.state.selectedTaskTime=='Month' &&<>{this.montlySchedule()}</>}
      </div>
    )
  }

  // render No Tasks card
  renderNoTasks = () => {
    return (
      <div className="group-mytasks-inner">
        <div className="textlabel-text-parent2">
          <div className="textlabel-text28">
            <span className="textlabel-text-txt2">
              <p className="ready-to-tackle-your-first-tas">
                <b>Ready to tackle your first task?</b>
              </p>
              <p className="your-tasks-will">
                Your tasks will start to fill this space! Begin by
                setting your first goal or to-do, and watch your
                productivity grow.
              </p>
            </span>
          </div>
          <div className="frame-wrapper11">
            <div className="skip-union-container">
              <img
                className="skip-union-icon3"
                alt="skipUnion3"
                src={skipUnion3}
              />
              <img
                className="skip-bar-chart-4-bars-icon"
                alt="skipBarChart4Bars"
                src={skipBarChart4Bars}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getPriorityImage = (priority: string) => {
    return priority === "high" ? groupHighTasksPriority
           : priority === "medium" ? groupMidTasksPriority
           : groupLowTasksPriority;
  }

  // render task element content
  renderTaskElementContent = (data: any) => {
    
    return (
      <div className="tasks-list-content">
        { data && data.map((taskObj: any, index: number) =>
          {
            // console.log("Task Element", taskObj && taskObj["attributes"])
            const element = taskObj && taskObj["attributes"]
            const description = element && element["description"]
            const deadline = element && element["deadline"]
            const priority = element && element["priority"]
            const priorityimg = priority && this.getPriorityImage(priority);

            return(
              <div key={index} className="task-card-container">
                <div className="task-card">
                  <div className="task-element">
                    <div className="task-elements-group-left">
                      <div className="task-element-icon">
                        <img
                          width="16"
                          height="16"
                          alt="skipTaskSummarize"
                          src={skipTaskSummarize}
                        />
                      </div>
                      <div className="task-element-title">
                        <span>{description}</span>
                      </div>
                    </div>
                    <div className="task-elements-group-right">
                      <div className="task-element-deadline">
                        <span>{deadline.split(' ')[3]}</span>
                      </div>
                      <div className="task-element-priority">
                        <img
                          width="22"
                          height="22"
                          alt={`group${priority}TasksPriority`}
                          src={priorityimg}
                        />
                      </div>
                      <div className="task-element-status">
                        <img
                          width="16"
                          height="16"
                          alt="skipCircle"
                          src={skipCircle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    )
  }

  populateTasksList = (myTasksData: any) => {
    return (
      Object.entries(myTasksData).map((taskObj: any, index: number) => {
        const key = taskObj[0].split("_")[0]
        const tasks = taskObj[1]["data"]
        const data = tasks && tasks.slice(0, 5)
        console.log("Today Tasks", taskObj, key, data)
        return (
          key != "month" && (data && data.length > 0) ?
            <div key={index} className={`${key}-tasks-list-container`}>
              <div className="tasks-list-header">
                <div className="tasks-list-title">
                  <span>
                    {key!="today" ? `This ${key}` : "Today"}
                  </span>
                </div>
                <div className="tasks-list-actions">
                  <div>
                    <span>
                      {key=="today" ? "DEADLINE" : null}
                    </span>
                  </div>
                  <div>
                    <span>
                      {key=="today" ? "PRIORITY" : null}
                    </span>
                  </div>
                  <div className="upcomingmeetings-switcher-btns">
                    <img
                      width="24px"
                      height="24px"
                      src={imageChevronLeft}
                    />
                    <img
                      width="24px"
                      height="24px"
                      src={imageChevronRight}
                    />
                  </div>
                </div>
              </div>
              { this.renderTaskElementContent(data) }
            </div>
          :
          null
        )
      })
    )
  }

  // render tasks view dynamically
  renderTasksView = (myTasksData: any, firstIndex: number, lastIndex: number) => {
    return(
      <div className="tasks-list-container">
        { myTasksData ?
          this.populateTasksList(myTasksData)
        : null }
      </div>
    )
  }

  // render tasks card
  renderTasksCard = () => {
    const myTasksData = this.state.myTasksData
    const currentPage = 1
    const dataPerPage = 12
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex - dataPerPage
    const noTasks = myTasksData.hasOwnProperty("error") ? true : false;

    return (
      <div className="group-mytasks">
        <div className="frame-parent13">
          <div className="frame-parent14">
            <div className="frame-parent15">
              <div className="textlabel-title-wrapper2">
                <b className="textlabel-title7">My Tasks</b>
              </div>
              <div className="mytasks-group-options">
                <img
                  className="group-options-icon"
                  alt="groupOptions2x"
                  src={groupOptions2x}
                />
              </div>
            </div>
            <div className="frame-parent16">
              <div className="frame-wrapper10">
                <div className="skip-union-group">
                  <img
                    className="skip-union-icon2"
                    alt="skipUnion2"
                    src={skipUnion2}
                  />
                  <div className="textlabel-text25">
                    <p className="make-it-happen">
                      <b>Make it happen!</b>
                    </p>
                    <p className="add-a-task">Add a task right away</p>
                  </div>
                </div>
              </div>
              <div className="textinput-fields-parent">
                <div className="textinput-fields">
                  <div className="view-rectangle9" />
                  <input
                    className="textlabel-text26 textlabel-text-pro"
                    placeholder="Task title"
                    type="text"
                  />
                </div>
                <textarea
                  className="textinput-fields1"
                  placeholder="Task description (optional)"
                  rows={5}
                  cols={15}
                />
                <div className="textinput-fields">
                  <div className="view-rectangle9" />
                  <input
                    className="textlabel-text26 textlabel-text-pro"
                    placeholder="Deadline"
                    type="text"
                  />
                  <img
                    className="skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </div>
                <fieldset className="textinput-fields mytasks-select-timezone">
                  <div className="view-rectangle9" />
                  <legend className="mytasks-select-legend"><b>Time Zone</b></legend>
                  <input
                    className="textlabel-text26 textlabel-text-pro"
                    placeholder="EST (Default)"
                    type="text"
                  />
                  <img
                    className="skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </fieldset>
                <fieldset className="textinput-fields mytasks-select-timezone">
                  <div className="view-rectangle9" />
                  <legend className="mytasks-select-legend"><b>Priority</b></legend>
                  <img
                    className="group-priority"
                    alt="groupHighTasksPriority"
                    src={groupHighTasksPriority}
                  />
                  <input
                    className="textlabel-text26 textlabel-text-pre"
                    placeholder="High (Default)"
                    type="text"
                  />
                  <img
                    className="mytasks-skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </fieldset>
              </div>
            </div>
            <button className="skip-bmec4">
              <div className="textlabel-24">Create New Task</div>
            </button>
          </div>
        </div>
        <div className="mytasks-view-vector">
          <img
              className="skip-vector-icon1"
              alt=""
              src={skipVector}
            />
        </div>
        { noTasks ? this.renderNoTasks() : null }
        { myTasksData ? this.renderTasksView(myTasksData, firstIndex, lastIndex) : null}
      </div>
    );
  }

  // Customizable Area End

  render() {
    return (
      <LocalizationProvider>
        <div className="my-schedule-first-view-of-new">
          <img className="skip-substract-icon" alt="skipSubstract" src={skipSubstract} />
          <main className="frame-parent">
            {this.renderHeader()}
            <section className="frame-container">
              <div className="frame-group">
                <div className="frame-div">
                  <div className="mytools-breadcrumb frame-parent1">
                    <div className="frame-wrapper1">
                      <div className="frame-parent2">
                        <div className="frame-wrapper2">
                          <div className="textlabel-title-parent">
                            <div className="textlabel-title">My Tools</div>
                          </div>
                        </div>
                        <div>
                          <img
                            className="skip-arrow-right-icon"
                            alt="skipArrowRight"
                            src={skipArrowRight}
                          />
                        </div>
                        <div className="frame-wrapper2">
                          <div className="textlabel-title-wrapper">
                            <div className="textlabel-title1">My Schedule</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button className="skip-bmec4">
                        <div className="image-wrapper">
                          <img
                            className="image-icon"
                            alt="image2"
                            src={image2}
                          />
                        </div>
                        <div className="textlabel-24">New Activity</div>
                        <div className="image-container">
                          <img
                            className="image-icon1"
                            alt="image3"
                            src={image3}
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                  <img
                    className="view-vector-icon"
                    alt="viewVector"
                    src={viewVector}
                  />
                  <div className="my-schedule-highlights frame-parent3">
                    <div className="frame-wrapper3">
                      <div className="calendar-simple-parent">
                        {this.renderCalendarCard()}
                        {this.renderUpcomingMeetingsCard()}
                        {this.renderActivityCard()}
                      </div>
                    </div>
                    <button className="skip-bmec2">
                      <b className="textlabel-8">?</b>
                    </button>
                  </div>
                  <div className="frame-parent7">
                    <div className="group-tomorrow-parent">
                      {this.renderTomorrowCard()}
                      {this.renderCategoriesCard()}
                    </div>
                    {this.renderCalendarDayViewCard()}
                  </div>
                </div>
                {this.renderTasksCard()}
              </div>
            </section>
          </main>
          {this.renderFooter()}
        </div>
        <div className="footer-wrapper">
        </div>
      </LocalizationProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle={
  imgText:{ display:'flex', gap:2 },
  taskbox:{ width: '90%', position: 'absolute', top: "34%", left:"11%", borderBottomLeftRadius:10, borderBottomRightRadius:10, height:"35px",
    display:'flex', textAlign:'center', paddingLeft: "1%", paddingRight: "1%", },
  meetingLink:{color:'#fff',marginTop:'4px',textDecoration:'underline',cursor:'pointer'},
  weekTaskTitle:{
    fontFamily: 'Raleway',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '12px',
    textAlign: 'center' as 'center',             
    textOverflow: 'ellipsis', 
    maxWidth: '67px',

  },
  
  weekTaskBox:{
    padding:9,borderBottomLeftRadius:'8px', 
    borderBottomRightRadius:'8px',height:'40px',marginBottom: '1px', textAlign: 'center' as 'center',display:'flex',justifyContent:'center',alignItems:'center' as 'center'
}
}
const webStyle2={
  imgText:{ display:'flex', gap:7 },
  taskbox:{ width: '100%', position: 'absolute', top: "34%", left:"9%", borderBottomLeftRadius:10, borderBottomRightRadius:10,
    display:'flex', textAlign:'center', padding:"0.5%",},
  meetingLink:{color:'#fff',marginTop:'4px',textDecoration:'underline',cursor:'pointer'},
  weekTaskTitle:{
    fontFamily: 'Raleway',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '12px',
    textAlign: 'center' as 'center',             
    textOverflow: 'ellipsis', 
    maxWidth: '67px',

  },
  
  weekTaskBox:{
    padding:9,borderBottomLeftRadius:'8px', 
    borderBottomRightRadius:'8px',height:'40px',marginBottom: '1px', textAlign: 'center' as 'center',display:'flex',justifyContent:'center',alignItems:'center' as 'center'
}
}
// const MonthBox=styled(Box)({
//   height:'589px',
//   width:'100%',
//   '& .rbc-off-range-bg':{
//     background:'none'
//   },
//   '& .rbc-header + .rbc-header':{
//     borderLeft:'none'
//   },
//   '& .rbc-toolbar':{
//     display:'none'
//   },
//   '& .rbc-today':{
//     background:'none'
//   },
//   '& .rbc-month-view':{
//     border:'none',
//     borderBottom:'1px solid #647488'
//   },
//   '& .rbc-header':{
//     fontSize:'14px',
//     color:'#647488',
//     borderBottom:'1px solid #647488'
//   },
//   '& .rbc-now': {
//     fontWeight: 'bold',
//   },
//   '& .rbc-month-row + .rbc-month-row':{
//     borderTop:'1px solid #647488',
//   },
//   '& .rbc-day-bg + .rbc-day-bg':{
//     borderLeft:'1px solid #647488',
//     position:'relative'
//   },
//   '& .rbc-month-row':{
//     display:'flex',
//     justifyContent:'flex-end'
//   },
//  '& .rbc-date-cell': {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'baseline',
   
//   },
//  '& .rbc-button-link':{

//  },
//  '& .root-11':{
//   position:'absolute',
//  },
//  '& .rbc-event, .rbc-day-slot .rbc-background-event':{
//   height:'20px',
//   borderTopLeftRadius: 'unset',
//   borderBottomLeftRadius:'unset',
//   position:'relative',
//   order: 0,
//  },
//  '& .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label':{
//   fontSize:'13px'
//  },
//  '& .rbc-month-row:child(6)': {
//   display: 'none', 
// },
// '& .rbc-show-more':{
//   bottom:'-32px',
//   borderRadius:'50%',
//   padding:'3px',
//   color:'#fff',
//   background:'#647488',
//   width:'21px',
// },
// '& .rbc-row-segment':{
//   padding:'0 3px 2px 3px'
// }
// })


const MonthBox = styled(Box)({
  height: '589px',
  width: '100%',
  '& .rbc-off-range-bg': {
    background: 'none'
  },
  '& .rbc-header + .rbc-header': {
    borderLeft: 'none'
  },
  '& .rbc-toolbar': {
    display: 'none'
  },
  '& .rbc-today': {
    background: 'none'
  },
  '& .rbc-month-view': {
    border: 'none',
    borderBottom: '1px solid #647488'
  },
  '& .rbc-header': {
    fontSize: '14px',
    color: '#647488',
    borderBottom: '1px solid #647488'
  },
  '& .rbc-now': {
    fontWeight: 'bold',
  },
  '& .rbc-month-row + .rbc-month-row': {
    borderTop: '1px solid #647488',
  },
  '& .rbc-day-bg + .rbc-day-bg': {
    borderLeft: '1px solid #647488',
    position: 'relative'
  },
  '& .rbc-month-row': {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  '& .rbc-date-cell': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
  '& .rbc-button-link': {
    position: 'absolute',
    bottom: '4px'
  },
  '& .rbc-event, .rbc-day-slot .rbc-background-event': {
    height: '20px',
    borderRadius: '4px',
    backgroundColor: '#4a90e2', // Customize event color
    color: '#ffffff', // Event text color
    padding: '0 4px',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '3px', // Adds space between events
  },
  '& .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label': {
    fontSize: '13px'
  },
  '& .rbc-show-more': {
    bottom: '-32px',
    borderRadius: '50%',
    padding: '3px',
    color: '#fff',
    background: '#647488',
    width: '21px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  '& .rbc-row-segment': {
    padding: '0 3px 2px 3px'
  },
  '& .rbc-row-content': {
    position: 'relative',
    zIndex: 4,
    height: '98%'
  }
});



// Customizable Area End
