import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;

  next: boolean;
  timeZone: string;
  timeFrame: string;
  selectedMonth: string;
  selectedYear: string;
  weeklyActivityCounts: any;
  upcomingMeetingsData: any;
  tomorrowEventsData: any;
  tasksCategoriesData: any;
  myTasksData: any;
  currentDate: Date;
  deadlineTime:string;
  timeBlocks:{time:string,hour:number}[];
  dayTasks:any;
  selectedTaskTime:string;
  weekStartDate: any,
  weekEndDate: any,
  timeSlot :string[];
  weekCount:number;
  weeklyData:any;
  checkCurrentDate:Date;
  monthlyData:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;

  upcomingMeetingsDataFetchApiCallId:any;
  weeklyActivityCountsFetchApiCallId:any;
  tomorrowEventsDataFetchApiCallId:any;
  tasksCategoriesDataFetchApiCallId:any;
  myTasksDataFetchApiCallId:any;
  daySchedulingId:any;
  weekSchedulingId:string='';
  monthSchedulingId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,

      next: true,
      timeFrame: "tomorrow",
      timeZone: "Asia/Kolkata",
      selectedMonth: "July",
      selectedYear: "2024",
      weeklyActivityCounts: [],
      upcomingMeetingsData: [],
      tomorrowEventsData: [],
      tasksCategoriesData: [],
      myTasksData: [],
      currentDate: new Date(),
      deadlineTime:'',
      timeBlocks: [
        { time: '07:00', hour: 7 },
        { time: '08:00', hour: 8 },
        { time: '09:00', hour: 9 },
        { time: '10:00', hour: 10 },
        { time: '11:00', hour: 11 },
        { time: '12:00', hour: 12 },
        { time: '13:00', hour: 13 },
        { time: '14:00', hour: 14 },
        { time: '15:00', hour: 15 },
        { time: '16:00', hour: 16 },
        { time: '17:00', hour: 17 },
        { time: '18:00', hour: 18 },
        { time: '19:00', hour: 19 },
        { time: '20:00', hour: 20 }
      ],
      dayTasks:'',
      selectedTaskTime:'Day',
      weekStartDate: null,
      weekEndDate: null,
      timeSlot : [
        '7:00', '8:00', '9:00', '10:00', '11:00', 
        '12:00', '13:00', '14:00', '15:00', '16:00', 
        '17:00', '18:00', '19:00', '20:00','21:00','22:00','23:00','24:00'
    ],
    weekCount:0,
    weeklyData:'',
    checkCurrentDate:new Date(),
    monthlyData:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson && responseJson.errors) {

        this.setState({serviceProviderSchedule: []});

        if ( typeof responseJson.errors === 'string' ) {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else if (apiRequestCallId != null) {
        this.setApiResponse(apiRequestCallId, responseJson)
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.onUpcomingMeetingsFetch()
    await this.onWeeklyActivityCountsFetch()
    await this.onTomorrowEventsFetch()
    await this.onTasksCategoriesFetch()
    await this.onMyTasksFetch()
    this.getSchedulingDay()
    this.getWeeklyTask()
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === true) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setApiResponse = (apiRequestCallId: any, responseJson: any) => {

      if (apiRequestCallId === this.serviceProviderScheduleApiId) {
        if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          console.log("Service Provider Schedule API Response", responseJson, responseJson.data)
          this.setState({
              serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
      }
      if (apiRequestCallId === this.serviceProviderDetailApiId) {
        if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          console.log("Service Provider Detail API Response", responseJson, responseJson.data)
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
              getName(MessageEnum.CalendarProviderDetailsResponseMessage),
              responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
      }
      if (apiRequestCallId === this.upcomingMeetingsDataFetchApiCallId) {
          console.log("Upcoming Meetings API Response", responseJson, responseJson.data)
          if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          this.setState({upcomingMeetingsData: responseJson.data})
      }
      if (apiRequestCallId === this.weeklyActivityCountsFetchApiCallId) {
          console.log("Activity API Response", responseJson, responseJson.data)
          if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          this.setState({weeklyActivityCounts: responseJson})
      }
      if (apiRequestCallId === this.tomorrowEventsDataFetchApiCallId) {
          console.log("Tomorrow Events API Response", responseJson, responseJson.data)
          if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          this.setState({tomorrowEventsData: responseJson})
      }
      if (apiRequestCallId === this.tasksCategoriesDataFetchApiCallId) {
          console.log("Tasks Categories API Response", responseJson, responseJson.data)
          if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          this.setState({tasksCategoriesData: responseJson.data})
      }
      if (apiRequestCallId === this.myTasksDataFetchApiCallId) {
          console.log("My Tasks API Response", responseJson, responseJson.data)
          if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
          this.setState({myTasksData: responseJson})
      }
      if(apiRequestCallId===this.daySchedulingId){
        if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
        this.setState({dayTasks:responseJson})
        console.log('checkinggg',responseJson)
      }
      if(apiRequestCallId==this.weekSchedulingId){
        if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
        this.setState({weeklyData:responseJson})
      }
      if(apiRequestCallId==this.monthSchedulingId){
        if (this.handleToken(responseJson)) {this.props.navigation.navigate("Signuplogin")}
        this.setState({monthlyData:responseJson})
      }
  };
  handleToken = (responseJson:any) => {
    if (responseJson?.errors?.[0]?.token == 'Token has Expired') {
     return true;}
    return false;
};
  onSelectDate = (selectedDateStr: string) => {
    const {details} = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  onUpcomingMeetingsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.upcomingMeetingsDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchUpcomingMeetingsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onWeeklyActivityCountsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.weeklyActivityCountsFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchWeeklyActivityCountsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onTomorrowEventsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.tomorrowEventsDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchTomorrowEventsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onTasksCategoriesFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.tasksCategoriesDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchTasksCategoriesEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  getClassName(priority: string) {
    if (priority === 'low') {
      return 'greenCircle';
    } else if (priority === 'medium') {
      return 'yellowCircle';
    } else if (priority === 'high') {
      return 'redCircle';
    }
  }
  
  onMyTasksFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.myTasksDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchMyTasksEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

formatDateAsMMDDYYYY = (date:Date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

  getSchedulingDay=async ()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData('signupToken')
    };
    const formattedDate = this.formatDateAsMMDDYYYY(this.state.currentDate);

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.daySchedulingId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_repeatingtasks/filter_by_timeframe?time_frame=date&date=${formattedDate}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  }
   formatDayDate=(dateInput: Date)=> {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayName = daysOfWeek[dateInput.getDay()];
    const dayOfMonth = dateInput.getDate();
        return `${dayName} ${dayOfMonth}`;
  }

handlePreviousDay = () => {
  this.setState((prevState) => {
    let newDate = new Date(prevState.currentDate);
    let newWeekCount = prevState.weekCount;

    if (this.state.selectedTaskTime === 'Day') {
      newDate.setDate(newDate.getDate() - 1);
      this.getSchedulingDay();
    } else if (this.state.selectedTaskTime === 'Week') {
      newDate.setDate(newDate.getDate() - 7);
      newWeekCount -= 1; 
      this.getWeeklyTask(); 
    } else if (this.state.selectedTaskTime === 'Month') {
      newDate = moment(prevState.currentDate).subtract(1, 'month').toDate();
    }

    return { currentDate: newDate, weekCount: newWeekCount };
  }, () => {
    if (this.state.selectedTaskTime === 'Month') {
      this.getMonthlyTask();
    }
    console.log('Updated currentDate:', this.state.currentDate);
  });
};

handleNextDay = () => {
  this.setState((prevState) => {
    let newDate = new Date(prevState.currentDate);
    let newWeekCount = prevState.weekCount;

    if (this.state.selectedTaskTime === 'Day') {
      newDate.setDate(newDate.getDate() + 1);
      this.getSchedulingDay();
    } else if (this.state.selectedTaskTime === 'Week') {
      newDate.setDate(newDate.getDate() + 7);
      newWeekCount += 1;
      this.getWeeklyTask(); 
    } else if (this.state.selectedTaskTime === 'Month') {
      newDate = moment(prevState.currentDate).add(1, 'month').toDate();
    }

    return { currentDate: newDate, weekCount: newWeekCount };
  }, () => {
    if (this.state.selectedTaskTime === 'Month') {
      this.getMonthlyTask(); 
    }
    console.log('Updated currentDate:', this.state.currentDate);
  });
};


currentTimeHeading = () => {
  const { selectedTaskTime, currentDate, weekCount } = this.state;

  if (selectedTaskTime === 'Day') {
    return this.formatDate(currentDate);
  }

  if (selectedTaskTime === 'Week') {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return `${this.formatDateWeek(startOfWeek, 'MMM D')} - ${this.formatDateWeek(endOfWeek, 'MMM D')}`;
  }
  if(selectedTaskTime=='Month'){
    return this.formatDateMonth(currentDate, 'MMMM');
  }
}
formatDateMonth = (date:any, format:any) => {
  return moment(date).format(format);
};

getStartOfWeek = (date: Date) => {
  const startOfWeek = new Date(date);
  const day = startOfWeek.getDay(); 
  
  const diff = (day === 0 ? -6 : 1) - day; 
  startOfWeek.setDate(startOfWeek.getDate() + diff); 
  return startOfWeek; 
}

getEndOfWeek = (date: Date) => {
  const endOfWeek = new Date(date); 
  const day = endOfWeek.getDay();
  
  const diff = (day === 0 ? 0 : 7) - day; 
  endOfWeek.setDate(endOfWeek.getDate() + diff); 
  return endOfWeek; 
}


formatDateWeek = (date: Date, format: string) => {
  const options: Intl.DateTimeFormatOptions = {
      month: 'long',  
      day: 'numeric' 
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

updateWeekDates = (date: Date) => {
  const startOfWeek = this.getStartOfWeek(date);
  const endOfWeek = this.getEndOfWeek(date);
  this.setState({
      weekStartDate: startOfWeek,
      weekEndDate: endOfWeek,
  });
};
getWeeklyTask=async ()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token": await getStorageData('signupToken')
  };

const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
);

this.weekSchedulingId = requestMessage.messageId;

requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_repeatingtasks/filter_by_timeframe?time_frame=week&timezone=Asia/Kolkata&offset=${this.state.weekCount}`
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);

runEngine.sendMessage(requestMessage.id, requestMessage);
}
getMonthlyTask=async ()=>{
  const currentDate = new Date(this.state.currentDate);
  const month = currentDate.toLocaleString('default', { month: 'long' }).toLowerCase();
  const year = currentDate.getFullYear(); 

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token": await getStorageData('signupToken')
  };

const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
);

this.monthSchedulingId = requestMessage.messageId;

requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_repeatingtasks/filter_by_timeframe?time_frame=month&month=${month}&year=${year}`
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);

runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
}