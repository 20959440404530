import React from "react";
// Customizable Area Start
import { activestudents, averagegenerate, completionrate, frequency ,exportsValue} from "./assets";
import { Box } from "@mui/material";
import "./Header.css";
import AnalyticsController,{Props} from "../../analytics/src/AnalyticsController.web";

import { getStorageData } from "../../../framework/src/Utilities";

export default class StudentReports extends AnalyticsController {
    constructor(props: Props) {
        super(props);

    }
    taskSelection = () => {
        return (
            <>
                <div className="taskselection" style={{
                    backgroundColor: '#050D15',
                    padding: '3% 100px 3%',
                    borderRadius: '0 0 50px 50px',
                    position: 'relative',
                    width: '100%'
                }}>
                    <h2
                        style={{
                            color: 'white', fontFamily: 'Raleway', fontSize: '24px',
                            fontWeight: 900, margin: 0, marginLeft: '30px'
                        }}>
                        Reports
                    </h2>
                </div>
                <div className="taskselection" style={{
                    padding: '3% 100px 0%',
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h2 style={{
                        color: 'white',
                        fontFamily: 'Raleway',
                        fontSize: '20px',
                        fontWeight: 700,
                        margin: 0,
                        marginLeft: '30px'
                    }}>
                        Metrics / Dashboard
                    </h2>
                    <div style={{zIndex:2}}>
                    <span className="btn-login-wrappers">
                  <div 
                    onClick={()=>this.handleExport()}
                    style={{
                        position: 'relative',
                        display: 'flex',
                        height: '46px',
                        cursor:'pointer',
                        alignItems: "center",
                        justifyContent: 'center',
                        padding: '4px 12px',
                        color: 'white',
                        fontFamily: 'Raleway',
                        fontSize: '16px',
                        fontWeight: 800,
                        gap:'15px',
                        width:'172px',
                        marginRight:'26px',
                        borderRadius: '8px',
                        zIndex: 1
                    }}> 

                        Export
                        <img src={exportsValue}alt="export"/>
                       
                       
                        {this.state.exportsValue && (
                           <div
                           style={{
                             position: "absolute",
                             top: "120%",
                             left: "50%",
                             transform: "translateX(-50%)",
                             width: "172px",
                            background:' rgba(28, 34, 53,0.7)',
                             backdropFilter: "blur(8px)",
                             border: "1px solid rgba(255, 255, 255, 0.05)",
                             borderRadius: "20px",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             justifyContent: "center",
                             color: "white",
                             fontFamily: "Raleway",
                             fontSize: "16px",
                             fontWeight: 600,
                             cursor: "pointer",
                             zIndex: 2,
                             overflow: "hidden",
                           }}
                         >
                           <div
                           data-test-id="csv-btn"
                             onClick={async ()=>this.handleCsvFile(await getStorageData("signupToken"))}
                             style={{
                            
                               width: "100%",
                               padding: "10px 10px 10px 35px", 
                               fontFamily: "Poppins",
                               fontSize: "14px",
                               fontWeight: 300,
                               transition: "background 0.3s ease",
                             }}
                             onMouseEnter={(e) =>
                               (e.currentTarget.style.background =
                                 "linear-gradient(90deg, rgba(45, 0, 247, 0.2) 44%, rgba(255, 255, 255, 0.2) 100%)")
                             }
                             onMouseLeave={(e) => (e.currentTarget.style.background = "transparent")}
                           >
                             CSV
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        border: "0.1px solid rgba(255, 255, 255, 0.05)",
                                    }}
                                />                         
                                <div
                                    style={{
                                        padding: "10px 10px 10px 35px",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        fontWeight: 300,
                                        transition: "background 0.3s ease",
                                    }}
                                    onClick={async ()=>this.handleExcelFile(await getStorageData("signupToken"))}
                                    onMouseEnter={(e) =>
                                    (e.currentTarget.style.background =
                                        "linear-gradient(90deg, rgba(45, 0, 247, 0.2) 44%, rgba(255, 255, 255, 0.2) 100%)")
                                    }
                                    onMouseLeave={(e) => (e.currentTarget.style.background = "transparent")}
                                     data-test-id="excel-btn"
                                >
                                    Excel
                                </div>                           
                            </div>
                        )}
                    </div> 
                    </span>
                    </div>
                </div>
                <div className="taskselection" style={{
                    padding: '1% 100px 3%',
                    position: 'relative',
                    width: '100%'
                }}>
                    {this.handleReport()}
                </div>
               
            </>
        );
    };
    handleReport = () => {
        return (
            <div style={{ ...webStyle.header }}>
                <Box className="headerBox" style={{ ...webStyle.headerBox, position: 'relative' }}>
                    <Box style={{
                        ...webStyle.rowDirection, flexDirection: "column", position: 'absolute',
                        width: 'calc(100% - 50px)'
                    }} >
                        <div style={{ ...webStyle.divHeading }}>
                            <h4 style={{
                                ...webStyle.headingValue, textTransform: 'uppercase'
                            }}>
                                ACTIVITY
                            </h4>
                            <img src={activestudents} style={{ ...webStyle.imageStyle }} />
                        </div>
                        <div style={{ ...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics, }}>
                           {`${this.state.metricReport?.active_students}%`}
                        </h4>
                            <h4 style={{ ...webStyle.Student, textTransform: "uppercase" }}>
                                ACTIVE  <div style={{ marginTop: '5px' }}><span>STUDENTS</span></div>
                            </h4></div>

                    </Box>
                </Box>
                <Box className="headerBox" style={{ ...webStyle.headerBox, position: 'relative' }}>
                    <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
                        <div style={{ ...webStyle.divHeading }}>
                            <h4 style={{ ...webStyle.headingValue, textTransform: 'uppercase' }}>
                                LOGIN FREQUENCY
                            </h4>
                            <img src={frequency} style={{ ...webStyle.imageStyle }} /></div>
                        <div className="datahandle" style={{ ...webStyle.data }}> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
                        {this.state.metricReport?.average_logins}
                        </h4>
                            <h4 style={{ ...webStyle.Student, textTransform: "uppercase" }}>
                            <span>AVERAGE</span><br />
                            <div style={{ marginTop: '5px' }}><span>PER WEEK</span></div>
                            </h4></div>

                    </Box>
                </Box>
                <Box className="headerBox" style={{ ...webStyle.headerBox, position: 'relative' }}>
                    <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
                        <div style={{ ...webStyle.divHeading }}>
                            <h4 style={{ ...webStyle.headingValue, textTransform: 'uppercase' }}>
                                COMPLETION RATE
                            </h4>
                            <img src={completionrate} style={{ ...webStyle.imageStyle }} />
                        </div>
                        <div style={{ ...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
                        {`${this.state.metricReport?.completion_rate}%`}
                        </h4>
                            <h4 style={{ ...webStyle.Student, textTransform: "uppercase" }}>
                                COMPLETED  <div style={{ marginTop: '5px' }}><span>COURSES</span></div>
                            </h4></div>

                    </Box>
                </Box>
                <Box className="headerBox" style={{ ...webStyle.headerBox, position: 'relative' }}>
                    <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
                        <div style={{ ...webStyle.divHeading }}>
                            <h4 style={{ ...webStyle.headingValue, textTransform: 'uppercase' }}>
                                AVERAGE completed courses
                            </h4>
                            <img src={averagegenerate} style={{ ...webStyle.imageStyle }} />
                        </div>
                        <div style={{ ...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
                        {this.state.metricReport?.average_completed_courses}
                        </h4>
                            <h4 style={{ ...webStyle.Student, textTransform: "uppercase" }}>
                                PER  <div style={{ marginTop: '5px' }}><span>STUDENT</span></div>
                            </h4></div>

                    </Box>
                </Box>
            </div>
        );
    };
    render() {
        return (
            <Box style={webStyle.backgroundCLr}>
                {this.taskSelection()}
            </Box>
        );
    }
}

const webStyle = {
    Student: {
        fontFamily: "Poppins", fontSize: "12px", opacity: '70%', color: 'white'
    },
    data: {
        display: 'flex',alignItems: 'center', marginTop: '10px', fontFamily: 'Poppins'
    },
    imageStyle: {
        width: '21.5px',
        height: "20px"
    },
    headingValue: {
        opacity: '70%',
        fontFamily: "Raleway",
        color: 'white',
        fontWeight: 700,
        fontSize: "12px",
        margin: 0
    },
    divHeading: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'
    },
    header: {
        display: 'flex', justifyContent: 'space-between',
    },
    rowDirection: {
        display: "flex",
        justifyContent: "space-between",
    },
    backgroundCLr: {
        backgroundColor: "rgb(28, 34, 53)",
    },
    dataAnalytics: {
        fontFamily: "Poppins", fontSize: "40px", color: 'white', marginRight: '10px'
    },
    week: {
        width: '51px',
        height: '24px',
        cursor: "pointer",
        fontWeight: 500,
        fontSize: '12px', color: '#FFFFFF', display: 'flex',
        justifyContent: 'center', alignItems: 'center',
        fontFamily: 'Poppins',
    },
    courses: {
        display: 'flex',
        cursor: "pointer",
        width: '109px', height: '35px', borderRadius: '50px', background: '#FFFFFF1A', fontWeight: 300, fontSize: '14px', opacity: '80%', color: '#FFFFFF',
        justifyContent: 'center', alignItems: 'center',
    },
    headerBox: {
        width: '273px',
        height: '144px',
        margin: '20px',
        border:'1px solid #121c28',
        borderRadius: '20px',
        padding: '25px',
        backgroundColor: '#121c28',
        paddingBottom: '40px'
    }

};
// Customizable Area End


