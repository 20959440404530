export const skipExam = require("../assets/skip_exam.svg");
export const skipExam2 = require("../assets/skip_exam2.svg");
export const filterPNG = require("../assets/filter_alt.svg");
export const checkPNG = require("../assets/check_circle.svg");
export const skipCancelPNG = require("../assets/cancel.svg");
export const skipEditPNG = require("../assets/expand_less.svg");
export const skipVector = require("../assets/skip_vector.png");
export const courserate=require("../assets/courserate.svg")
export const Filter = require("../assets/filter.svg")
export const search = require("../assets/search.svg")
export const swapVector = require("../assets/swap_vert.svg")
export const downArrow = require("../assets/down_arrow.svg")
export const deselect = require("../assets/skip_radiob_MEC.svg")
export const select = require("../assets/skip_radiob_MEC (1).svg")
export const filter= require("../assets/skip_filter_alt (1).svg")
export const searchImgIcon = require("../assets/search_off.svg")