import React from "react";
// Customizable Area Start
import { Univeristy, Platform, Pending, Completed ,Filter} from "./assets";
import { Box, Divider } from "@mui/material";
import "./Header.css";
import DashboardController, {
  Props,
} from "./DashboardController.web";

export default class TopNav extends DashboardController {
  constructor(props: Props) {
    super(props);

  }
  taskSelection = () => {
    return (
      <div className="taskselection" style={{ backgroundColor:'#050D15',
        padding: '3% 100px 3%' ,
        borderRadius: '0 0 50px 50px',
        position :'relative',
        width:'100%'}}>
          <h2
            style={{  color: 'white',fontFamily:'Raleway',fontSize:'24px',
              fontWeight: 500,
              margin:0,marginLeft:'15px' }}
          >
            Welcome to
            <span  style={{ color:'white',fontWeight:'bold',fontFamily:'Raleway',fontSize:'24px', }}>  {this.state.dashboardData?.institute}</span>
          </h2> 
        <Divider style={{marginBottom:'40px',border: '1px solid transparent', color: 'white',fontWeight: 'bold',margin:'20px',borderImageSource: 'linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.5) 100%)',borderImageSlice: 1,}}/>
        <div>
          <h2
           style={{color: 'white',fontWeight: 'bold',margin:'20px', fontFamily:'Raleway',}}>
            Summary
          </h2>
        </div>
        {this.handleSummary()}
      </div>
    );
  };
  handleSummary = () => {
   const details=this.state.dashboardData;
console.log(details,"details")
    return (
      <div style={{ ...webStyle.header}}>
        <Box className="headerBox"style={{...webStyle.headerBox,position:'relative'}}>
          <Box style={{ ...webStyle.rowDirection, flexDirection: "column" ,position: 'absolute',
                width: 'calc(100% - 50px)'}} >
            <div style={{ ...webStyle.divHeading}}>
              <h4 style={{
               ...webStyle.headingValue,textTransform:'uppercase'
              }}>
                UNIVERSITY
              </h4>
              <img src={Univeristy} style={{ ...webStyle.imageStyle }} />
            </div>
            <div style={{...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics ,}}>
             {details?.university_total_students?.toLocaleString()}
            </h4>
              <h4 style={{ ...webStyle.Student,textTransform: "uppercase"}}>
                TOTAL STUDENTS
              </h4></div>

          </Box>
        </Box>
        <Box  className="headerBox" style={{...webStyle.headerBox,position:'relative'}}>
          <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
            <div style={{ ...webStyle.divHeading}}>
              <h4 style={{ ...webStyle.headingValue,textTransform:'uppercase' }}>
                PLATFORM ENGAGED
              </h4>
              <img src={Platform}  style={{ ...webStyle.imageStyle }}/></div>
            <div className="datahandle" style={{...webStyle.data }}> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
            {details?.platform_engagement?.toLocaleString()}
            </h4>
              <h4 style={{ ...webStyle.Student,textTransform: "uppercase"}}>
                ACTIVE STUDENTS
              </h4></div>

          </Box>
        </Box>
        <Box  className="headerBox" style={{...webStyle.headerBox,position:'relative'}}>
          <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
            <div style={{ ...webStyle.divHeading }}>
              <h4 style={{ ...webStyle.headingValue,textTransform:'uppercase' }}>
                PENDING COURSES
              </h4>
              <img src={Pending} style={{ ...webStyle.imageStyle }} />
            </div>
            <div style={{...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
            {details?.pending_courses?.toLocaleString()}

            </h4>
              <h4 style={{ ...webStyle.Student ,textTransform: "uppercase"}}>
                TOTAL COURSES
              </h4></div>

          </Box>
        </Box>
        <Box  className="headerBox" style={{...webStyle.headerBox,position:'relative'}}>
          <Box style={{ ...webStyle.rowDirection, flexDirection: "column" }} className="statsheading">
          <div style={{ ...webStyle.divHeading }}>
              <h4 style={{ ...webStyle.headingValue,textTransform:'uppercase' }}>
                completed courses
              </h4>
              <img src={Completed}  style={{ ...webStyle.imageStyle }} />
            </div>
            <div style={{ ...webStyle.data }} className="datahandle"> <h4 className="datahandle1" style={{ ...webStyle.dataAnalytics }}>
            {`${details?.completed_courses}%`}
            </h4>
              <h4 style={{ ...webStyle.Student ,textTransform: "uppercase"}}>
                TOTAL PERCENTAGE
              </h4></div>

          </Box>
        </Box>
      </div>
    );
  };
  render() {
    return (
      <Box style={webStyle.backgroundCLr}>
        {this.taskSelection()}
      </Box>
    );
  }
}

const webStyle = {
  Student:{
    fontFamily: "Poppins", fontSize: "12px", opacity: '70%', color: 'white' 
  },
  data:{
 display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px',fontFamily:'Poppins'
  },
  imageStyle:{
  width: '25.8px', height: "24px"},
  headingValue:{
    opacity: '70%',
    fontFamily: "Raleway",
    color: 'white',
    fontWeight: 700,
    fontSize: "12px",
    margin: 0
  },
   divHeading:{
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' 
   },
   header:{
    display: 'flex', justifyContent: 'space-between', 
   },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  dataAnalytics:{
    fontFamily: "Poppins", fontSize: "40px",  color: 'white',marginRight:'10px'
  },
  week: {
    width: '51px',
    height: '24px',
    cursor: "pointer",
    fontWeight:500,
    fontSize:'12px',color:'#FFFFFF',display:'flex',
    justifyContent:'center',alignItems:'center',
    fontFamily:'Poppins',
  },
  courses:{
    display:'flex',
    cursor: "pointer",
     width: '109px', height: '35px', borderRadius: '50px', background: '#FFFFFF1A',fontWeight:300,fontSize:'14px',opacity:'80%',color:'#FFFFFF',
     justifyContent:'center',alignItems:'center',  
  },
  headerBox:{
    width: '273px',
    height: '144px',
    margin:'20px',  
    borderRadius: '20px',
    padding: '25px',
    backgroundColor:'#121c28',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    paddingBottom: '40px'
  
  }

};
// Customizable Area End
