import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { CSSProperties } from "react";
export const configJSON = require("./config");
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  loading:boolean;
  profileData:any,
  token: any;
  drawerItems: any;
  anchorElHome: boolean | null;
  valueBorder:number,
  isDropdownOpen:boolean;
  userName:string;
  selectedMenu: number;
  anchorElTools: boolean | null;
  anchorElServices: boolean | null;
  anchorElhelp: boolean | null;
  isLogoutModal:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getProfile: any;
  menuMapping: Record<string, number> = {
    "/Dashboard":1,
    "/Students":2,
    "/Reports":3
  };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      profileData:[],
      anchorElHome: null,
      loading:false,
      userName:'',
      isDropdownOpen:false,
      anchorElTools: null,
      valueBorder:0,
      anchorElServices: null,
      anchorElhelp: null,
      selectedMenu:  this.menuMapping[window.location.pathname] ?? 2,
      isLogoutModal: false
  
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if(responseJson){
      if (apiRequestCallId === this.getProfile) {
        this.setState({profileData:responseJson?.data[0]?.attributes})
        this.setState({ loading: false });
       } 
    }
     else {
        this.parseApiErrorResponse(responseJson);
      }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };
  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
     const token = await getStorageData("signupToken");
    this.getToken();
    // Customizable Area Start
    this.getProfileData(token);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        // this.getToken();
      });
    }
    // Customizable Area End
  }
// Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onClickDashboard = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Dashboard"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
 
  onClickStudentList = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Students"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onClickReports = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Reports"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  getFontWeight = (isActive: any) => (isActive ? 400 : 300);

  getMenuStyle = (isSelected: boolean,checkborder:boolean, anchorEl: any): CSSProperties => {
      return {
        cursor: "pointer",
        color: isSelected ? "white" : "rgba(255, 255, 255, 1)",
        textAlign: "center",
        fontWeight: isSelected ||checkborder? 400 : this.getFontWeight(anchorEl),
        backgroundColor: checkborder ? "#FFFFFF0D" : "transparent",
        borderRadius: checkborder ? "20px" : "0",
        display: "inline-flex", 
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px",
        border: checkborder ? "1px solid #FFFFFF0D" : "1px solid transparent",
        padding: "8px 20px",
        lineHeight: "1.5",
        minWidth: "100px",
        width: "fit-content",
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px !important',
        opacity:  isSelected || checkborder ? "1" : "0.7",
      };
    };
  toggleDropdown = () => {
      this.setState((prevState):any => ({
        isDropdownOpen: !prevState.isDropdownOpen,
      }));
    };
    navigateProfile = () =>{
      this.props.navigation.navigate("ProfileSettings")
    }
  openLogoutModal = () =>{
    this.setState({isLogoutModal:!this.state.isLogoutModal})
  }
  handleLogout = () => {
      removeStorageData("signupToken");
     this.props.navigation.navigate("Signuplogin")
    
    };
    getProfileData = (token:any) => {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const dataValue = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getProfile = dataValue.messageId;
  
      dataValue.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.profile}`
      );
  
      dataValue.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      dataValue.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(dataValue.id, dataValue);
     
    };
  // Customizable Area End
}
