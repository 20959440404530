import { Box } from "@material-ui/core";
import ApiIntegrationController  ,{Props} from "../../apiintegration/src/ApiIntegrationController";
import TopNav from "./TopNav.web";
import React from 'react'
import StudentTable from "./StudentTable.web";
export default class Student extends ApiIntegrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }



    render() {
        return (<>
            <TopNav searchDetails={this.state} handleInputChange={this.handleInputChange} handleFilter={this.handleFilter} handleCourseClick={this.handleCourseClick} handleStatus={this.handleStatus}/>
            <Box style={{
                marginBottom:'30px',
                padding: '20px',
                borderRadius: '20px',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                marginTop: '30px',
                marginLeft: '85px',
                marginRight: '85px',
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
            }}>
                <StudentTable detailsData={this.state} navigateStudentDetail={this.navigateStudentDetail} getStudentTableDetails ={this.getStudentTableDetails} setQueryData={this.setQueryData}/>
            </Box>
        </>)
    }
}

