// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { logoutSVG, medEdsmall } from "./assets";

import NavigationMenuController, { Props } from "./NavigationMenuController";
import DialogBox from "../../../components/src/DialogBox.web";
import Dashboard from '../../dashboard/src/Dashboard.web';
import Highlight from "../../dashboard/src/Highlight.web";
import Graph from "../../dashboard/src/Graph.web";

export default class InstitutionalHeader extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    }

  displayMenu = () => {
    const {
      anchorElHome,
      anchorElTools,
      anchorElhelp,
      selectedMenu,
       valueBorder,
      
    } = this.state;
    return (
      <div style={{ display: "flex",marginLeft:'20px' }}>
        <div
          style={{ position: "relative" }}
          data-test-id="onClickHome"
          onClick={() => this.onClickDashboard()}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              data-test-id="handleClickHome"
              className="displayMenuText instituteText"
              style={this.getMenuStyle(selectedMenu == 1, this.state.valueBorder==1,anchorElHome)}
            >
              Dashboard
            </Typography>
            {selectedMenu == 1 ? (
              <p
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor:
                    anchorElHome || selectedMenu == 1
                      ? "white"                       
                      : "rgba(255, 255, 255, 0.4)",
                  color: "white",
                  marginRight: "22px",
                  marginTop:'-6px'                                   
                }}
              />
            ) : null}
          </Box>
        </div>
        <div style={{marginLeft:'-1.8rem'}}>
          <Typography
            onClick={(event) => this.onClickStudentList()}
            data-test-id="handleStudentList"
            className="displayMenuText "
            style={{
              ...this.getMenuStyle(selectedMenu == 2,valueBorder==2, anchorElHome),
              marginLeft: "auto",
            }}
          >
            Student list
          </Typography>
          {selectedMenu == 2 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElTools || selectedMenu == 2 
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px",    
                marginTop:'-6px'                                   

              }}
            />
          ) : null}
        </div>

        <div style={{marginLeft:'-1.8rem'}}>
          <Typography
            onClick={(event) => this.onClickReports()}
            data-test-id="handleReports"
            className="displayMenuText"
            style={this.getMenuStyle(selectedMenu == 3, valueBorder==3,anchorElHome)}
          >
            Reports
          </Typography>
          {selectedMenu == 3 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: anchorElhelp || selectedMenu == 3 
                  ? "white"
                  : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "45px",
                marginTop:'-6px'                                   

              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderNav = () => {
    if (this.state.loading) {
      return (
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              width="100%"
          >
              <CircularProgress style={{ color: "white" }} />
          </Box>
      );
  }
    return (
      <Grid container xl={12} className="headerAll" data-testid="nav">
        <Box className="headerMain">
          <Box className="logoContainer">
            <img className="logoTop" src={medEdsmall} alt="medEd_logo" />
          </Box>
          <Grid item xl={12} sm={6} className="displayMenu">
            {this.displayMenu()}
          </Grid>

          <Grid
            item
            xl={12}
            sm={6}
            className="searchContainer"
            data-testid="auth-wrapper"
          >
            
            &nbsp;&nbsp;
            <Box className="header">
            <Box className="showProfile" onClick={this.navigateProfile} style={{cursor:'pointer'}}>
                <Box className="profileLetter">
                  {this.state.profileData?.image == null ? (
                    this.state.profileData?.first_name?.charAt(0)
                  ) : (
                    <img
                      src={this.state.profileData?.image?.url}
                      alt="Profile"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                    />
                  )}
                </Box>
              <div className="textContainer">
                <span className="usrNameTxt" data-testid="signupBtn" style={{fontFamily:'poppins',fontSize:'14px',fontWeight:300}}>
                {this.state.profileData?.first_name+" "+this.state.profileData?.last_name}
                </span>
                <span style={{fontFamily:'poppins',fontSize:'10px',fontWeight:300}} className="subTextProfile">{this.state.profileData?.email}</span>
              </div>
              <KeyboardArrowRightIcon
                onClick={this.toggleDropdown}
                className="dropdownIcon"
                data-test-id="dropdownArrow"
                style={{ cursor: 'pointer' }}
              />
              </Box>
           
              </Box>
              <div style={{position:'relative',top:'6px'}}>
              <div className="logoutBtnHeader" onClick={this.openLogoutModal} id="handle-logout" style={{cursor:'pointer'}}>
              <img src={logoutSVG} height={'15px'} width={'15px'}/>
          </div>
            </div>
          </Grid>
        </Box>
        <DialogBox isModalOpen ={this.state.isLogoutModal}
        handleCancel={this.openLogoutModal} handleOnAction ={this.handleLogout}/>
      </Grid>
    );
  };

    render() {
      
    return (
            // Required for all blocks
      <Container maxWidth="xl" className="container-header mainheader">
        {this.renderNav()}
        {this.state.selectedMenu===1 && 
        <>
        <Dashboard navigation={undefined}/>
        <Graph id={""} navigation={undefined}/>
        <Highlight navigation={undefined} id={""}/>
        </>
      }
      </Container>
          );
  }
}

// Customizable Area End
